import { api } from 'api';
import { useMemo } from 'react';
import { viewportSize } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { JobSeniorityLevelMapping, JobTypeMapping } from 'constants/mappings';

const jobLabelList: string[] = ['location', 'jobType', 'seniorityLevel', 'salaryRange'];
export const usePositionDetails = () => {
  const {state} = useLocation();
  const {data = []} = api.useJobListQuery();
  const {width} = viewportSize()

  const positionDetails: Record<string, any> = useMemo(() => data.find((_detail: any) => _detail.id === state.id) || {}, [data, state.id]);

  const jobLabels: string[] = useMemo(() => {
    return jobLabelList.reduce((acc: string[], cur: string) => {
      if (positionDetails[cur]) {
        if (JobTypeMapping[positionDetails[cur]]) {
          return [
            ...acc,
            JobTypeMapping[positionDetails[cur]]
          ]
        } else if (JobSeniorityLevelMapping[positionDetails[cur]]) {
          return [
            ...acc,
            JobSeniorityLevelMapping[positionDetails[cur]]
          ]
        }
        return [
          ...acc,
          positionDetails[cur]
        ]
      } else {
        return acc;
      }
    }, [])
  }, [positionDetails]);

  return {
    positionDetails,
    jobLabels,
    width
  }
}