export const ROUTES = {
  home: '/',
  team: '/team',
  credo: '/credo',
  careers: '/careers',
  contact: '/contacts',
  services: '/services',
  projectForm: '/project-form',
  positionDetails: '/position-details',
  notAuthorized: '/not-authorized',
  formSubmitted: '/project-form-submitted',
}

export const ADMIN_ROUTES = {
  admin:'/admin',
  adminSignIn:'/admin/sign-in',
  adminProfile:'/admin/profile',
  adminForgotPassword: '/admin/forgot-password',
  adminResetPassword:'/admin/reset-password',
  dashboard:'/admin/dashboard',
  countries:'/admin/dashboard/countries',
  languages:'/admin/dashboard/languages',
  customFields:'/admin/job-management/custom-fields',
  jobAnnouncements:'/admin/job-management/job-announcements',
  createJobAnnouncements:'/admin/job-management/job-announcements/create',
  notAuthorized:'/not-authorized',
}