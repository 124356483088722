import { OptionType } from '../scribexUI';

export type ErrorMappingValueType = {
  type: 'error' | 'success' | 'warning' | 'info',
  message: string
}

export type OptionListType = {
  label: string
  info: string
  id: string
}

export type ServiceType = {
  id: string
  title: string
  description: string
  optionList?: OptionListType[]
}

export interface IService {
  title: string
  description: string
  optionList?: OptionListType[]
}

export type OpenPositionsType = {
  jobTitle: string
  jobType: string
  jobLocation: string
  salaryRange: string
  jobCountry: string
  jobPosition: string
  description: string
  jobResponsibilities: string[]
  requiredQualifications?: string[]
  ourOfferIncludes?: string[]
  link: string
  id: string
}

import type { MenuProps } from 'antd';
import { ValidationInfoType } from 'scribexUI/typings';

export type IUserType = 'USER' | 'BUSINESS';
export type SignupFormTabsTypeTitle = 'USER_STANDARD' | 'USER_PREV' | 'BUSINESS_PREV' | 'BUSINESS_STANDARD';
export type FormItemName =
  'businessName'
  | 'email'
  | 'password'
  | 'username'
  | 'confirmPassword'
  | 'oldPassword'
  | 'userName';


export type AdditionalProp = {
  info?: {
    message: string | JSX.Element,
    icon?: string
  },
  error?: {
    message: string | JSX.Element,
    icon?: string
  }
}


export type FormType = {
  placeholder: string,
  label?: string,
  name: FormItemName,
  id: number,
  isPassword?: boolean,
  validation?: 'warning' | 'error',
  additionalProp?: AdditionalProp,
  validationInfo?: ValidationInfoType

}
export type ThirdPartySignupType = {
  icon: string,
  btnText: string,
  id: number
}
export type SignupFormTabsType = {
  label: string,
  key: SignupFormTabsTypeTitle
}

export interface IUserTypeData {
  title: string,
  typeImg: string,
  id: number,
  type: IUserType
}

export type SignupFormType = {
  title: string,
  tabs: SignupFormTabsType[],
  form: {
    formRegular: Record<string, FormType>,
    formBusiness: Record<string, FormType>,
  }
  thirdPartySignup: ThirdPartySignupType[],
  checkboxText: string,
  pPolicy: string,
  tAndC: string,
  submitBtn: string,
  signInBtn: string,
  haveAccountQuestion: string
}
export type SigninFormType = {
  title: string,
  form: FormType[],
  thirdPartySignup?: ThirdPartySignupType[],
  submitBtn: string,
  signupBtn?: string,
  haveAccountQuestion?: string,
  forgotPass: string
}

export type ResetPasswordFormType = {
  title: string,
  description: string,
  form: Record<string, FormType>,
  submitBtn: string
}

export type EmailVerificationType = {
  title: string,
  descriptionOne: string,
  descriptionTwo?: string,
  icon: string,
  submitBtn?: string,
  resendEmail?: string
}

export type SignupErrorMappingValueType = {
  type: 'error' | 'success' | 'warning' | 'info',
  message: string
}

export type ProfileMenuItem = Required<MenuProps>['items'][number];

export type CustomFieldsType = 'TEXT' | 'TEXT_AREA' | 'NUMBER' | 'FILE' | 'EMAIL' | 'PHONE_NUMBER';

export type FileSelectTYpe = string | number | string[];

export const CUSTOM_FIELD_TYPES: Record<CustomFieldsType, string> = {
  TEXT: 'Text Input',
  TEXT_AREA: 'Text Area',
  NUMBER: 'Number',
  FILE: 'File',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone Number'
};
export const JOB_TYPES: Record<string, string> = {
  FULL_TIME: 'Full time',
  PART_TIME: 'Part time',
};
export const APPLICATION_METHOD: Record<string, string> = {
  EMAIL: 'Email',
  FORM: 'Form',
};

export type CustomFieldsListType = {
  label: string,
  value?: string
  key: string
  options?: OptionType[]
}

export type JobCreateFieldsNameType = 'title' | 'description' | 'location' | 'jobType' | 'salaryRange' | 'applicationMethod' | 'department' | 'seniorityLevel' | 'currency' | 'min' | 'max' | 'emailApp' | 'formApp';
export type JobCreateFieldsType = {
  [key in JobCreateFieldsNameType]?:string
}
export type JobDetailsType = {
  [key in JobCreateFieldsNameType]?:any
}
