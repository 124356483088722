import React, { JSX } from 'react';
import { Col, Divider, Row, Space } from 'antd';
import { ADMIN_ROUTES } from 'constants/routes';
import {
  BreadcrumbItemType,
  SXBreadcrumb, SXButton,
  SXInput, SXModal, SXPagination,
  SXTable, SXTag, SXTooltip,
  SXTypography
} from 'scribexUI';
import {
  AddIcon, DeleteIcon,
  EmptyScreen,
  FilterIcon,
  HomeIcon,
  NoResultFilterScreen,
  NoResultScreen,
  OutlineArrowDownIcon,
  SearchIcon,
  Spin,
} from 'componnets';
import { EditIcon } from 'scribexUI/icons';
import deleteIcon from 'assets/icons/delete.svg';
import { JobTypeMapping } from 'constants/mappings';
import EmptyScreenIcon from 'assets/icons/empty-country.png';
import { JobFilter } from 'componnets/common/modal/filter/job';
import { useJobAnnouncements } from './useJobAnnouncements';
import styles from './styles.module.scss';

const {Title, Text} = SXTypography;

type TableColumnValue<T> = T extends { [key: string]: infer V } ? V : never;

interface TableColumn<T, K extends keyof T = keyof T> {
  title: string;
  dataIndex: K;
  render?: (value: TableColumnValue<T>, record: T, index: number) => JSX.Element;
  editable?: boolean;
  fixed?: 'left' | 'right'
  width?: number | string
}

type StatusType = {
  published: boolean
  remove: boolean
}

export interface DataType {
  key: string
  title: string
  description: string
  location: string
  jobType: string
  department: string[]
  salaryRange: string
  deadLine: string
  applicationMethod: string
  status: StatusType
}


const items: BreadcrumbItemType[] = [
  {
    label: 'Job Management ',
    id: '1',
    path: ADMIN_ROUTES.jobAnnouncements,
    icon: <HomeIcon/>,
  },
  {
    label: 'Job Announcements',
    path: ADMIN_ROUTES.jobAnnouncements,
    id: '2',
  },
];
export const JobAnnouncements = React.memo(() => {
  const {
    onCreateJobAnnouncement,
    fieldsList,
    searchIsLoading,
    isSearched,
    onRemoveClick,
    isOpen,
    fieldName,
    onClose,
    onRemoveConfirm,
    contextHolder,
    onJobEdit,
    onSearch,
    isFilterOpen,
    onFilterOpen,
    isFiltered,
    onFilterApply,
    filterRef,
    onFilterClose,
    onFilterReset,
    totalItems,
    onPageChange
  } = useJobAnnouncements();
  const columns: TableColumn<DataType>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '200px',
      render: (value: DataType['title']) => {
        if (value) {
          return <span>{value}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '200px',
      render: (value: DataType['description']) => {
        if (value) {
          return <span>{value}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '200px',
      render: (value: DataType['location']) => {
        if (value) {
          return <span>{value}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Job type',
      dataIndex: 'jobType',
      width: '200px',
      render: (value: DataType['jobType']) => {
        if (value) {
          return <span>{JobTypeMapping[value]}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: '200px',
      render: (value: DataType['department']) => {
        if (value) {
          return <span>{[...value].join(', ')}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Salary range',
      dataIndex: 'salaryRange',
      width: '200px',
      render: (value: DataType['salaryRange']) => {
        if (value === '-') {
          return <span>N/A</span>
        }
        return <span>{value}</span>
      }
    },
    {
      title: 'Deadline',
      dataIndex: 'deadLine',
      width: '200px',
      render: (value: DataType['deadLine']) => {
        const options: Record<string, string> = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        };
        if (value) {
          const date = new Date(value).toLocaleDateString('en-GB', options);
          return <span>{date}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Application method',
      dataIndex: 'applicationMethod',
      width: '200px',
      render: (value: DataType['applicationMethod']) => {
        if (value) {
          return <span>{value}</span>
        }
        return <span>N/A</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '200px',
      render: (value: DataType['status']) => {
        const {published, remove} = value;
        return <SXTag type={'status'} state={'base'} status={remove ? 'removed' : published ? 'published' : 'draft'}
                      withDot>{remove ? 'Archive' : published ? 'Published' : 'Draft'}</SXTag>;
      },
    },
    {
      title: '',
      dataIndex: 'key',
      width: '72px',
      fixed: 'right',
      render: (value: DataType['key']) => {
        const field: any = fieldsList.find(_field => _field.id === value);
        return (
          <Space size={8} align={'center'}>
            <SXTooltip
              disabled={!field?.remove}
              title={'This custom field is currently IS REMOVED.'}>
              <button
                disabled={!!field?.remove}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0',
                  cursor: `${!field?.usedCount ? 'pointer' : 'not-allowed'}`
                }}
                onClick={() => onRemoveClick(value)}
              ><DeleteIcon/></button>
            </SXTooltip>
            <button style={{backgroundColor: 'transparent', border: 'none', padding: '0', cursor: 'pointer'}}
              onClick={() => onJobEdit(value)}
            >
              <EditIcon/>
            </button>
          </Space>
        )
      },
    }
  ];
  return (
    <>
      {contextHolder}
      <Row gutter={[0, 10]} align={'top'} className={styles.job_announcements}>
        <Row gutter={[0, 10]} className={styles.top_banner}>
          <Col span={24} style={{padding: '12px 32px'}}>
            <SXBreadcrumb items={items}/>
          </Col>
          <Col span={24} style={{padding: '10px 32px 0'}}>
            <Row justify={'space-between'} align={'middle'}>
              <Title level={4}>Job Announcements</Title>
              <Col>
                <Space>
                  <Col>
                    <SXButton
                      size={'xs'}
                      sxType={'secondary'}
                      $left_icon={<FilterIcon/>}
                      $right_icon={<OutlineArrowDownIcon/>}
                      onClick={onFilterOpen}
                    >Filter</SXButton>
                  </Col>
                  <Col>
                    <SXInput
                      inpSize={'sm'}
                      placeholder={'Search'}
                      $right_icon={<SearchIcon/>}
                      onChange={(e) => onSearch(e)}
                    />
                  </Col>
                  <Col>
                    <SXButton
                      sxType={'primary'}
                      size={'xs'}
                      $right_icon={<AddIcon/>}
                      onClick={onCreateJobAnnouncement}
                    >Create New</SXButton>
                  </Col>
                </Space>
              </Col>
            </Row>
          </Col>
          <Divider className={styles.divider}/>
        </Row>
        <Col span={24} style={{padding: '32px'}}>
          {fieldsList?.length ? <Spin spinning={searchIsLoading}>
            <SXTable columns={columns} dataSource={fieldsList} tableId={'uniqueTableIdJobAnnouncements'}/>
          </Spin> : isSearched ? <NoResultScreen/> :
            isFiltered ? <NoResultFilterScreen/> :
            <EmptyScreen
              icon={EmptyScreenIcon}
              description={`There are no Job Announcements yet!`}/>}
          <SXPagination
            total={totalItems}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
      <SXModal
        isOpen={isOpen}
        onClose={onClose}
        okText={'Delete'}
        title={'Delete Application'}
        image={deleteIcon}
        onSave={onRemoveConfirm}>
        <Row justify={'center'}>
          <Text className={'center'}>Are you sure you want to delete <Text underline>{fieldName ? fieldName : ''}</Text> Job application?</Text>
        </Row>
      </SXModal>
      <SXModal
        type={'standard'}
        size={'md'}
        isOpen={isFilterOpen}
        onClose={onFilterReset}
        cancelText={isFiltered ? 'Cancel' : 'Close'}
        title={'Filter'}
        onSave={onFilterApply}
      >
        <JobFilter ref={filterRef} onClose={onFilterClose}/>
      </SXModal>
    </>
  )
})