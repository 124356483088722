import React from 'react';
import { Col, Space } from 'componnets';
import { HideIcon } from 'scribexUI/icons';
import { SXTypography } from 'scribexUI';
import { CalendarIcon, LightIcon, NotificationIcon, Row, SettingsHeaderIcon, WalletIcon } from 'componnets';
import styles from './styles.module.scss';

const {Text} = SXTypography;
export const AdminHeader = React.memo(() => {
  return (
    <Row justify={'end'} align={'middle'} className={styles.admin_header}>
      {/*
      <Col span={8}>*/}
      {/*  <Space size={24} wrap={false} align={'center'} className={'w-100'}>*/}
      {/*    <HomeIcon size={24}/>*/}
      {/*    <SXInput*/}
      {/*      inpSize={'sm'}*/}
      {/*      placeholder={'Search'}*/}
      {/*      $right_icon={<SearchIcon/>}*/}
      {/*    />*/}
      {/*  </Space>*/}
      {/*
      </Col>*/}
      <Col span={10}>
        <Row justify={'end'}>
          <Space size={16}>
            <Space size={6}>
              <Col className={styles.wallet}>
                <Space size={4} align={'center'}>
                  <WalletIcon/>
                  <Text size={'md'} color={'#222222'}>5 280.48 AMD</Text>
                  <HideIcon/>
                </Space>
              </Col>
              <Col className={styles.wallet}>
                <Space size={4} align={'center'}>
                  <CalendarIcon/>
                  <Text size={'md'} color={'#222222'}>23.03.2024</Text>
                </Space>
              </Col>
            </Space>
            <Space size={10} wrap={false}>
              <SettingsHeaderIcon/>
              <LightIcon/>
              <NotificationIcon/>
            </Space>
          </Space>
        </Row>
      </Col>
    </Row>
  )
})