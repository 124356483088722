export const RemoveCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.94645 15.1667C3.99979 15.1667 0.779785 11.9534 0.779785 8.00004C0.779785 4.04671 3.99979 0.833374 7.94645 0.833374C11.8931 0.833374 15.1131 4.04671 15.1131 8.00004C15.1131 11.9534 11.8998 15.1667 7.94645 15.1667ZM7.94645 1.83337C4.54645 1.83337 1.77979 4.60004 1.77979 8.00004C1.77979 11.4 4.54645 14.1667 7.94645 14.1667C11.3465 14.1667 14.1131 11.4 14.1131 8.00004C14.1131 4.60004 11.3465 1.83337 7.94645 1.83337Z"
        fill="#FF4D4F"/>
      <path
        d="M10.6131 8.5H5.27979C5.00645 8.5 4.77979 8.27333 4.77979 8C4.77979 7.72667 5.00645 7.5 5.27979 7.5H10.6131C10.8865 7.5 11.1131 7.72667 11.1131 8C11.1131 8.27333 10.8931 8.5 10.6131 8.5Z"
        fill="#FF4D4F"/>
    </svg>

  )
}