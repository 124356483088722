export const VerticalDotsIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3333 7.00033C16.3333 8.28782 15.2872 9.33366 14 9.33366C12.7125 9.33366 11.6667 8.28755 11.6667 7.00033C11.6667 5.71283 12.7128 4.66699 14 4.66699C15.2875 4.66699 16.3333 5.71283 16.3333 7.00033Z"
        fill="#A9A9AA"/>
      <path
        d="M16.3333 14.0003C16.3333 15.2892 15.2888 16.3337 14 16.3337C12.7112 16.3337 11.6667 15.2891 11.6667 14.0003C11.6667 12.7115 12.7112 11.667 14 11.667C15.2888 11.667 16.3333 12.7115 16.3333 14.0003Z"
        fill="#A9A9AA"/>
      <path
        d="M16.3333 21.0003C16.3333 22.2878 15.2872 23.3337 14 23.3337C12.7125 23.3337 11.6667 22.2875 11.6667 21.0003C11.6667 19.7128 12.7128 18.667 14 18.667C15.2875 18.667 16.3333 19.7128 16.3333 21.0003Z"
        fill="#A9A9AA"/>
    </svg>
  )
}