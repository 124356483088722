import { useAuth } from 'hooks';
import React, { FC } from 'react';
import { ROUTES } from 'constants/routes';
import { Navigate } from 'react-router-dom';

export interface IPrivateRoute {
  redirectPath?: string
  element: React.ReactElement
}

export const PrivateRoutes: FC<IPrivateRoute> = (props) => {
  const {element} = props;
  const {
    isAuthenticated,
    // isLoading
  } = useAuth();
  // if (!isLoading) {
  if (isAuthenticated) {
    return element
  }
  return <Navigate to={ROUTES.notAuthorized}/>
  // }
  // return null
};