import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { SXTag } from 'scribexUI';
import { DangerIcon, InfoIcon } from '../../../icons';
import { StyledMessage, StyledMessageWrapper, StyledValidationIcon } from '../../errorMessage';
import { ValidationInfoType } from '../../../typings';
import { TagList } from './TagList';

interface InputTYpe {
  placeholder?: string
  defaultValue?: string
  onChange?: (tags: string[]) => void
  label?: string
  disabled?: boolean
  inpSize?: 'sm' | 'lg' | 'fill'
  defaultTags?: string[]
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
  options: string[]
  initialValue?: string[]
}

type StyledInputWrapperType = {
  $inpSize: 'sm' | 'lg' | 'fill'
  disabled: boolean
}

const sizes: Record<any, any> = {
  sm: {
    padding: '6px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    height: '34px'
  },
  lg: {
    padding: '10px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    height: '46px'

  },
  fill: {
    padding: '13px 0',
    fontSize: '14px',
    lineHeight: '20px',
    height: '46px'
  },
};


const StyledInputContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  gap: 4px;
  position: relative;
`;

const StyledInputLabel = styled.label<{ disabled: boolean }>`
  padding: 0;
  margin: 0;
  font-family: DMSans-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  ${(props) =>
          props.disabled && css`
            color: #68686B;
          `
  }
`;
const StyledInputWrapper = styled.div<StyledInputWrapperType>`
  border-radius: 6px;
  background-color: #FFFFFF;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  height: 46px;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  overflow-x: auto;
  scrollbar-width: thin;
  border: 1px solid #D5D5D5;
  ${(props) => sizes[props.$inpSize as string]};

  ${(props) =>
          props.disabled && css`
            border-color: rgba(20, 13, 11, .5);
            color: rgba(20, 13, 11, .5);
            cursor: not-allowed;
          `
  }
  &:focus-within {
    border-color: #331DAD;
  }
`;


const inputStyles = css<{ disabled: boolean }>`
  border: none;
  font-family: DMSans-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  min-width: 100px;
  color: #140D0B;

  &:focus {
    border: none;
    color: #331DAD;
    outline: none;

    &::placeholder {
      color: #331DAD;
    }
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: #140D0B;
  }

  ${(props) =>
          props.disabled && css`
            background-color: #ffffff;
            cursor: not-allowed;
            color: rgba(20, 13, 11, .5);

            &::placeholder {
              color: rgba(20, 13, 11, .5);
            }
          `
  }
`;

const StyleTagMessage = styled.span<{ color?: string }>`
  font-size: 12px;
  font-family: DMSans-Regular, sans-serif;
  color: ${(props) => props.color ? props.color : '#68686B'};
`;


const StyledInput = styled.input<{ disabled: boolean }>`
  ${inputStyles}
`;

const StyledTagInputBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 999;
`;

const StyledOptions = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 0 0 6px 6px;
  background-color: #FBFBFB;
  max-height: 300px;
  overflow-x: auto;
  z-index: 9999;
  width: 100%;
`;
export const SXTagInput = React.forwardRef<HTMLInputElement, InputTYpe>((props, ref) => {
  const {
    label,
    disabled = false,
    defaultValue = "",
    onChange,
    placeholder,
    validationInfo = {},
    isValid,
    inpSize = 'lg',
    initialValue = [],
    options = []
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [tagOptions, setTagOptions] = useState(options);
  const [tags, setTags] = useState(initialValue || []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean>(false);
  const uniqueId = Math.random().toString(16).slice(2) + Date.now();

  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }
  const isValidationMessageVisible = !disabled && message;

  const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

    setIsExist(false);
    setValue(event.target.value);
    const searchedOptions = options.filter(_option => {
      const optionValue = _option.toLowerCase();
      const eventValue = event.target.value.toLowerCase();
      return !tags.some(_tag => _tag.toLowerCase() === optionValue) && (optionValue.startsWith(eventValue) || optionValue.includes(eventValue))
    })
    setTagOptions(searchedOptions);
    setIsOpen(true)
  }, [tags, options]);

  useEffect(() => {
    if (isExist) {
      setTimeout(() => {
        setIsExist(false)
      }, 3000)
    }
  }, [isExist])

  const onKeyHandler = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const trimValue = value.trim();
    if (event.key === 'Enter' && trimValue) {
      const IsTagExist = tags.some(_tag => _tag === trimValue);
      setValue('');
      if (IsTagExist) {
        setIsExist(true);
        return;
      }
      onChange && onChange([...tags, trimValue]);
      setTags(prevState => [...prevState, trimValue]);
    }
  }, [onChange, value, tags]);

  const onTagRemove = useCallback((tag: string) => {
    setTags(prevState => prevState.filter(_tag => _tag !== tag));
  }, []);

  const onOptionsClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOptionSelect = useCallback((event: React.MouseEvent, value: string) => {
    onChange && onChange([...tags, value]);
    setTags(prevState => [...prevState, value]);
    setValue('');
    setTagOptions(prevState => prevState.filter(_tag => _tag !== value));
  }, [onChange, tags]);
  return (
    <>
      {isOpen && <StyledTagInputBackdrop onClick={onOptionsClose}/>}
      <StyledInputContainer>
        {label && <StyledInputLabel htmlFor={uniqueId} disabled={disabled}>
          {label}
        </StyledInputLabel>}
        <StyledInputWrapper
          disabled={disabled}
          $inpSize={inpSize}
        >
          {tags.map((tag) => {
            const key = Math.random().toString(16).slice(2) + tag;
            return <SXTag onRemoveClick={() => onTagRemove(tag)} type={'remove'} key={key}>{tag}</SXTag>
          })}
          <StyledInput
            id={uniqueId}
            ref={ref}
            disabled={disabled}
            type={'text'}
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChangeHandler(event)}
            onKeyDown={e => onKeyHandler(e)}
          />
        </StyledInputWrapper>
        {!!value && <StyleTagMessage>Press Enter to save</StyleTagMessage>}
        {isExist && <StyleTagMessage color={'#CF1322'}>Tag already exist!</StyleTagMessage>}
        {isValidationMessageVisible && <StyledMessageWrapper>
          <StyledValidationIcon>
            {!disabled && icon}
          </StyledValidationIcon>
          <StyledMessage $isValid={isValid}>
            {!disabled && message}
          </StyledMessage>
        </StyledMessageWrapper>}
        {isOpen && !!tagOptions.length && <StyledOptions>
          <TagList options={tagOptions} onOptionSelect={onOptionSelect} bordered/>
        </StyledOptions>}
      </StyledInputContainer>

    </>
  )
})