import React from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Layout, Row } from 'componnets';
import styles from './styles.module.scss';

export const AppLayout = React.memo(() => {
  return (
    <Layout className={styles.layout}>
      <Row className={styles.web_layout}>
        <Col span={24}>
          <Outlet/>
        </Col>
      </Row>
    </Layout>
  )
})