import { api } from 'api';
import { notification } from 'antd';
import { useAppDispatch } from 'store/hooks';
import { useTypedSelector } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { getLatestSource } from 'utils/helpers';
import { ADMIN_ROUTES } from 'constants/routes';
import { ErrorMappingValueType } from 'typings';
import { CustomFieldsFilterRef } from 'componnets';
import { CustomFieldValidation } from 'constants/validations';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { resetJobFilterParams } from 'componnets/common/modal/filter/job/jobFilterSlice';

export interface JobFieldType {
  id: string
  title: string;
  description: string;
  location: string;
  jobType: string;
  department: string
  salaryRange: string
  deadLine: string
  applicationMethod: string
  published: boolean
  remove: boolean
}

export const useJobAnnouncements = () => {
  const [currentField, setCurrentField] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string | null>(null);
  const [currentData, setCurrentData] = useState<Record<string, any>>({});

  const {filteredParams} = useTypedSelector(state => state.jobFilter);
  const [notificationApi, contextHolder] = notification.useNotification();
  const filterRef = useRef<CustomFieldsFilterRef>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateJob] = api.useUpdateJobMutation();
  const [search, {
    data: searchData,
    isLoading: searchIsLoading,
    isUninitialized: isUninitializedSearch,
    fulfilledTimeStamp: searchTimeStamp,
    reset:searchReset
  }] = api.useJobSearchMutation();
  const [filter, {
    data: filterData,
    isUninitialized: isFilterUninitialized,
    isSuccess: filterIsSuccess,
    fulfilledTimeStamp: filterTimeStamp,
    reset
  }] = api.useJobFilterMutation({fixedCacheKey: 'jobFilterCache'});
  const {
    data = [],
    fulfilledTimeStamp: listTimeStamp
  } = api.useJobAnnouncementListQuery({size: perPageCount, page: currentPage}, {
    skip: !isFilterUninitialized || !isUninitializedSearch
  });

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onCreateJobAnnouncement = useCallback(() => {
    navigate(ADMIN_ROUTES.createJobAnnouncements);
  }, [navigate]);

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      searchReset();
    } else {
      setSearchedValue(event.target.value);
      search({search: event.target.value});
    }
  }, [search,searchReset]);

  const onFilterOpen = useCallback(() => {
    setIsFilterOpen(true);
  }, []);

  const onPageChange = useCallback((page: number, perPageCount: number) => {
    const source = getLatestSource(Number(listTimeStamp), Number(filterTimeStamp), Number(searchTimeStamp));
    switch (source) {
      case 'list':
        setCurrentPage(page);
        setPerPageCount(perPageCount);
        break;
      case 'filter':
        filter({size: perPageCount, page: page, ...filteredParams});
        break;
      case 'search':
        search({size: perPageCount, page: page, search: searchedValue});
        break;
      default:
        return
    }
  }, [listTimeStamp, filterTimeStamp, searchTimeStamp, filteredParams, filter, search, searchedValue]);

  const fieldsList: JobFieldType[] = useMemo(() => {
    let mapData;
    const source = getLatestSource(Number(listTimeStamp), Number(filterTimeStamp), Number(searchTimeStamp));
    switch (source) {
      case 'list':
        mapData = data;
        break;
      case 'filter':
        mapData = filterData;
        break;
      case 'search':
        mapData = searchData;
        break;
      default:
        mapData = {};
    }
    if (mapData.totalElements !== currentData.totalElements) {
      setCurrentData(mapData);
    }
    return mapData?.content?.map((_field: JobFieldType) => {
      return {
        ..._field,
        key: _field.id,
        status: {published: _field.published, remove: _field.remove}
      }
    })
  }, [data, searchData, filterData, currentData, searchTimeStamp, listTimeStamp, filterTimeStamp]);

  const onJobEdit = useCallback((id: string) => {
    const jobData = fieldsList.find(_field => _field.id === id);
    navigate(`${ADMIN_ROUTES.jobAnnouncements}/${id}`, {
      state: {
        jobData,
      }
    });
  }, [navigate, fieldsList]);

  const onRemoveClick = useCallback((id: string) => {
    setIsOpen(prevState => !prevState);
    const field = fieldsList.find(_field => _field.id === id);
    setCurrentField(field);
    setFieldName(field?.title || '');
  }, [fieldsList]);

  const onRemoveConfirm = useCallback(() => {
    const payload = {
      ...currentField,
      remove: true,
      published: false
    }
    updateJob(payload)
      .then(({data, error}: any) => {
        setIsOpen(false);
        if (data?.status && data?.status === "ok") {
          openNotificationWithIcon({type: 'success', message: "The Job application was successfully deleted."});
        } else {
          if (data) {
            Object.keys(data).forEach((_key: string) => {
              if (_key === 'status') {
                openNotificationWithIcon(CustomFieldValidation[data[_key]]);
              } else {
                _key && openNotificationWithIcon({type: 'error', message: data[_key]});
              }
            })
          } else if (error?.data) {
            error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
          }
        }
      })
      .catch(err => {
        console.log(err, 'error')
      })
  }, [currentField, updateJob, openNotificationWithIcon])

  const onFilterReset = useCallback(() => {
    if (!isFilterUninitialized) {
      dispatch(resetJobFilterParams());
      reset();
      return;
    }
    setIsFilterOpen(false);
  }, [isFilterUninitialized, reset, dispatch]);

  const onFilterClose = useCallback(() => {
    setIsFilterOpen(false);
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, []);

  const onFilterApply = useCallback(() => {
    filterRef.current?.onApply();
  }, []);

  return {
    onCreateJobAnnouncement,
    fieldsList,
    searchIsLoading,
    isSearched: !!searchedValue,
    onRemoveClick,
    isOpen,
    fieldName,
    onRemoveConfirm,
    contextHolder,
    onClose,
    onJobEdit,
    onSearch,
    isFilterOpen,
    onFilterOpen,
    onFilterApply,
    onFilterClose,
    filterRef,
    onFilterReset,
    isFiltered: !isFilterUninitialized && filterIsSuccess,
    totalItems: currentData?.totalElements,
    onPageChange
  }
}