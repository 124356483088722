import React from 'react';
import { ADMIN_ROUTES } from 'constants/routes';
import { SXList, SXTypography } from 'scribexUI';
import { Outlet, useNavigate } from 'react-router-dom';
import ScribexLogo from 'assets/icons/Scribex-logo.svg';
import { ListType } from 'scribexUI/components/list/List';
import { ActionList, AdminHeader, Col, Divider, Image, Row, Space } from 'componnets';
import { DashboardIcon, InsightsIcon, ReportIcon, VerticalDotsIcon, LocalizationIcon } from 'componnets/common/icons';
import { useAdmin } from './dashboard/useAdmin';
import styles from './styles.module.scss';


type SidebarType = ListType;
export const AdminDashboardSidebarData: Record<string, SidebarType[]> = {
  groupName: [
    {
      icon: <DashboardIcon/>,
      label: 'Job Management ',
      id: '1',
      children: [
        {
          label: 'Job Announcements',
          id: '1/1',
          path: ADMIN_ROUTES.jobAnnouncements
        },
        {
          label: 'Applications',
          id: '1/2'
        },
        {
          label: 'Custom Fields ',
          id: '1/3',
          path: ADMIN_ROUTES.customFields
        }
      ]
    },
    {
      icon: <InsightsIcon/>,
      label: 'Insights',
      id: '2',
    },
    {
      icon: <ReportIcon/>,
      label: 'Report',
      id: '3',
    }
  ],
  adminTools: [
    {
      label: 'Localization',
      id: '1',
      icon: <LocalizationIcon/>,
      children: [
        {
          // path: ADMIN_ROUTES.customFields,
          label: 'Countries',
          id: '1/1'
        },
        {
          // path: ROUTES.languages,
          label: 'Languages',
          id: '1/2'
        },
        {
          label: 'Currencies',
          id: '1/3'
        },
        {
          label: 'Translation',
          id: '1/4'
        }
      ]
    }
  ]
};
const options = [
  {
    label: "Action",
    value: 'action'
  },
  {
    label: "Log Out",
    value: 'logout'
  }]


const {Text} = SXTypography;
export const Admin = React.memo(() => {
  const navigate = useNavigate()
  const handleEsim = (path: string) => {
    navigate(path)
  }

  const {onActionMenuClick, isActionMenuOpen, onActionOptionSelect} = useAdmin();
  return (
    <Row justify={'center'} wrap={false} className={styles.dashboard}>
      <Col flex={292}>
        <Col className={styles.side_bar}>
          <Row wrap={true} justify={'center'} gutter={[0, 40]}>
            <Col span={24}>
              <Row gutter={[0, 12]} wrap={true}>
                <Col className={styles.logo} span={24}>
                  <Image src={ScribexLogo} alt={'Logo'} preview={false} width={90}
                         onClick={() => navigate(ADMIN_ROUTES.admin)}
                         style={{cursor: 'pointer'}}/>
                </Col>
                <Col span={24}>
                  <Space>
                    <Text size={'sm'}>System Time Zone - UTC+0</Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Row gutter={[0, 12]}>
                    <Text color={'#A9A9AA'}>Group Name</Text>
                    <SXList
                      mode={'transparent'}
                      list={AdminDashboardSidebarData.groupName}
                      onClick={(path) => handleEsim(path)}
                    />
                  </Row>
                </Col>
                <Divider style={{margin: '0'}}/>
                <Col span={24}>
                  <Row gutter={[0, 12]}>
                    <Text color={'#A9A9AA'}>Administrative Tools</Text>
                    <SXList
                      mode={'transparent'}
                      list={AdminDashboardSidebarData.adminTools}
                      onClick={(path) => handleEsim(path)}/>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} className={styles.profile_info}>
              <Row className={'w-100'} gutter={[0, 16]}>
                <Col span={24}>
                  <Divider style={{margin: '0'}}/>
                </Col>
                <Col span={24}>
                  <Row justify={'space-between'} align={'middle'} style={{position: 'relative'}}>
                    <Space size={8}>
                      <Col flex={'40px'} className={styles.avatar}/>
                      <Col span={24}>
                        <Space direction={'vertical'} size={0}>
                          <Text size={'md'} weight={'medium'}>Name Surname</Text>
                          <Text size={'sm'} color={'#68686B'}>Role Name</Text>
                        </Space>
                      </Col>
                    </Space>
                    <Col span={2}>
                      <Col onClick={onActionMenuClick} style={{cursor: 'pointer'}}>
                        <VerticalDotsIcon/>
                      </Col>
                      {isActionMenuOpen &&
                        <Row className={styles.action_list_wrapper}>
                          <ActionList
                            options={options}
                            onClose={onActionMenuClick}
                            onSelect={(event: any, item: any) => onActionOptionSelect(item)}/>
                        </Row>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col span={20} className={styles.content_bar}>
        <AdminHeader/>
        <Outlet/>
      </Col>
    </Row>
  )
})