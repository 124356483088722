import React, { FC } from 'react';
import { Spin } from 'componnets';
import styled, { css } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonProps, ButtonType } from 'scribexUI/typings';

const sizes: Record<ButtonType["size"], any> = {
  md: {
    padding: '10px 26px',
    fontSize: '16px',
    lineHeight: '22px',
    height:'42px'
  },
  sm: {
    padding: '8px 24px',
    fontSize: '16px',
    lineHeight: '24px',
    height:'40px'
  },
  xs: {
    padding: '8px 20px',
    fontSize: '14px',
    height:'35px'

  },
  lg: {
    padding: '14px 40px',
    fontSize: '20px',
    lineHeight: '26px',
    height: '54px'
  },
};

const roundness: Record<ButtonType["roundness"], any> = {
  pill: {
    borderRadius: "360px"
  },
  rounded: {
    borderRadius: "6px"
  },
  sharp: {
    borderRadius: "none"
  },
};

const buttonStyles = css<ButtonProps>`
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: DMSans-Regular, sans-serif;
  white-space: nowrap;

  ${(props) => sizes[props.size as ButtonType["size"]]};
  ${(props) => props.$block && css<ButtonProps>`
    width: 100%;
  `};
  ${(props) => roundness[props.$roundness as ButtonType["roundness"]]};
  ${(props) =>
          props.$sxType === 'primary' && css<ButtonProps>`
            background-color: ${props.theme.colors.primary};
            color: white;
            border: none;
          `
  };
  ${(props) =>
          props.$sxType === 'secondary' && css<ButtonProps>`
            background-color: #E6E6E6;
            color: #331DAD;
            border: none;
          `
  };
  ${(props) =>
          props.$sxType === 'tertiary' && css<ButtonProps>`
            background-color: transparent;
            color: ${props.$borderColor ? props.$borderColor : props.theme.colors.primary};
            border: 2px solid ${props.$borderColor ? props.$borderColor : props.theme.colors.primary};
    
          `
  };
  ${({$bgColor}) => $bgColor && css`
    background-color: ${$bgColor};
  `};
  ${(props) =>
          props.$isLoading && css<ButtonProps>`
            cursor: not-allowed;
          `
  };
  ${(props) =>
          props.disabled && !props.$isLoading && css<ButtonProps>`
            background-color: ${(props) => props.$sxType === "tertiary" ? 'white' : '#68686B'};
            border: ${(props) => props.$sxType === "tertiary" ? `2px solid #68686B` : 'none'};
            color: ${() => '#ffffff'};
            cursor: not-allowed;
          `
  };
  &:hover:not([disabled]) {
    ${(props) =>
            props.$sxType === 'primary' && css<ButtonProps>`
                //background-color: ${props.theme.colors.primaryHover};
              // todo need to write it in theme generic way
              background-color: #331DAD;
            `
    };
    ${(props) =>
            props.$sxType === 'tertiary' && css<ButtonProps>`
              color: #331DAD;
              border-color: #331DAD;
            `
    };
`;
const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles}
`;

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpinWrapper = styled.span`
  svg {
    fill: #FFFFFF;
  }
`;

export const SXButton: FC<ButtonProps> = React.memo((props) => {
  const {
    sxType = 'secondary',
    size = 'md',
    roundness = 'rounded',
    children,
    onClick,
    $right_icon,
    $left_icon,
    disabled,
    block,
    bgColor,
    borderColor,
    type = 'button',
    className,
    isLoading
  } = props;
  return (
    <StyledButton
      className={className}
      type={type}
      $sxType={sxType}
      size={size}
      $roundness={roundness}
      onClick={onClick}
      $left_icon={$left_icon}
      $right_icon={$right_icon}
      disabled={disabled}
      $block={block}
      $bgColor={bgColor}
      $borderColor={borderColor}
      $isLoading={isLoading}
    >
      {$left_icon && <StyledIconWrapper>{$left_icon}</StyledIconWrapper>}
      {isLoading ? <StyledSpinWrapper>
        <Spin spinning={isLoading} size="large"
              indicator={<LoadingOutlined spin/>}/>
      </StyledSpinWrapper> : children}
      {$right_icon && <StyledIconWrapper>{$right_icon}</StyledIconWrapper>}
    </StyledButton>
  )
})