import styled from 'styled-components';
import React, { FC, JSX } from 'react';
import { SXRadioButton } from './index';
import { DangerIcon, InfoIcon } from '../../icons';
import { ValidationInfoType } from '../../typings';
import { StyledMessage, StyledMessageWrapper, StyledValidationIcon } from '../errorMessage';

export interface IOption {
  label: string;
  value: string;
  name?: string;
  disabled?: boolean;
}

export interface IOptionGroup {
  label: string;
  options: IOption[];
  disabled?: boolean,
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
  initialValue?:string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface RadioButtonGroupProps extends IOptionGroup {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RenderOptionsProps {
  options: IOption[];
  initialValue?:string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Wrapper = styled.div`
  width: 100%;

  & > :not(:last-child) {
    border-bottom: none;
    border-radius: 0;
  }

  & > :first-child {
    border-radius: 6px 6px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const StyledFieldset = styled.div`
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const StyledLabel = styled.label`
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  font-family: DMSans-SemiBold, sans-serif;
`;
const OptionContainer = styled.div`
  padding: 11px 16px;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  width: 100%;
`;

const RenderOptions = ({options, onChange, initialValue}: RenderOptionsProps) => {
  return options.map(({label, name, disabled, value}, index): JSX.Element => {
    const optionId = `radio-option-${label.replace(/\s+/g, "")}`;
    const checked= initialValue ? value === initialValue : undefined;
    return <OptionContainer key={optionId}>
      <SXRadioButton
        value={value}
        label={label}
        id={optionId}
        name={name}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        initialValue={initialValue}
      />
    </OptionContainer>
  });
};
export const SXRadioButtonGroup: FC<RadioButtonGroupProps> = React.memo((props) => {
  const {
    label,
    options,
    onChange,
    disabled,
    validationInfo= {},
    isValid,
    initialValue
  } = props;

  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }

  const isValidationMessageVisible = !disabled && message;

  return (
    <StyledFieldset>
      <StyledLabel>{label}</StyledLabel>
      <Wrapper>
        {RenderOptions({options, onChange, initialValue})}
      </Wrapper>
      {isValidationMessageVisible && <StyledMessageWrapper>
        <StyledValidationIcon>
          {!disabled && icon}
        </StyledValidationIcon>
        <StyledMessage $isValid={isValid}>
          {!disabled && message}
        </StyledMessage>
      </StyledMessageWrapper>}
    </StyledFieldset>
  )
});