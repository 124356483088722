import { api } from 'api';
import  { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'constants/routes';

export type FormFieldType = {
  id: string,
  fileType?: string[] | null[]
  label: string
  placeholder?: string
  type: string
  usedCount: number
  max?: number
  min?: number
  required?:boolean
}
export const useApplicationForm = (formFields:FormFieldType[]) => {
  const [fieldsToDisplay, setFieldsToDisplay] = useState<FormFieldType[]>([]);
  const [customFields, setCustomFields] = useState<FormFieldType[]>([]);
  const {data = {}} = api.useCustomFieldListQuery({size: 30, page: 0});

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.content) {
      setCustomFields(data.content);
    }
  }, [data]);

  useEffect(() => {
    if(formFields?.length) {
      setFieldsToDisplay(formFields);
      setCustomFields(prevState => prevState.filter(_field => formFields.some(_form => _form.id !== _field.id)))
    }

  },[formFields]);

  const onCustomFieldsClick = useCallback(() => {
    navigate(ADMIN_ROUTES.customFields);
  }, [navigate]);
  const onToggle = useCallback((value:boolean, id:string) => {
    setFieldsToDisplay(prevState => {
      return prevState.map(_field => {
        if (_field.id === id ) {
          return {
            ..._field,
            required:value
          }
        }
        return _field
      })
    })
  }, []);

  const onFieldAdd = useCallback((field: FormFieldType) => {
    setFieldsToDisplay(prevState => [...prevState, field]);
    setCustomFields(prevState => prevState.filter(_field => _field.id !== field.id));
  }, []);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setFieldsToDisplay((prevItems) => {
      const newItems = [...prevItems];
      const [draggedItem] = newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, draggedItem);
      return newItems;
    });
  }, []);

  const onColumnRemoveClick = useCallback((id: string) => {
    const field = fieldsToDisplay.find(_field => _field.id === id);
    setFieldsToDisplay(prevState => prevState.filter(_field => _field.id !== id));
    if (field) {
      setCustomFields(prevState => [...prevState, field]);
    }
  }, [fieldsToDisplay]);

  return {
    data,
    fieldsToDisplay,
    onFieldAdd,
    moveItem,
    onColumnRemoveClick,
    customFields,
    onCustomFieldsClick,
    onToggle
  }
}