import styled from 'styled-components';
import React, { FC, useCallback, useState } from 'react';
import { SXTypography, SXTag, SXTextButton, SXCheckbox, SXRadioButton } from 'scribexUI';
import { OutlineArrowDownIcon, OutlineArrowUpIcon } from 'scribexUI/icons';
import { Image } from 'antd';

const {Text} = SXTypography;

interface IListItem {
  label: string;
  icon?: React.ReactElement;
  button?: string | null;
  tag?: string;
  id?: string;
  selectable?: boolean;
  buttonType?: 'radio' | 'checkbox';
  bordered?: boolean;
  children?: any;
  hasChildren?: boolean;
  onClick?: () => void;
  onSelect?: (item: any, value?: string) => void;
  onButtonClick?: (id?: string, label?:string) => void;
  isChildren?: boolean;
  img?: string;
  countryKey?: string;
  selectedOption?: string;
  onRemoveDefault?: (item?: any) => void;
}

interface ListItemProps {
  selected?: boolean;
}

type LiContainerType = {
  $isChildren: boolean
  $bordered: boolean
}

const StyledListItem = styled.li<ListItemProps>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  list-style-type: none;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > button {
    margin-left: auto;
  }
`;

const StyledContainer = styled.div<LiContainerType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const StyledHoverContainer = styled.div`
  width: 100%;

  &:hover {
    background-color: #F8F8F8;
  }
`;

const StyledLiContainer = styled.div<LiContainerType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  ${(props) => props.$isChildren && 'padding-left:24px'};
  ${(props) => props.$bordered && "border-top: 0.5px solid #E6E6E6"}
`;

const StyledTRightContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
`;

const StyledButtonContainer = styled.div`
  max-width: 90px;
  width: 100%;
  margin-left: auto;
`;

const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledChildrenContainer = styled.div`
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListItemElement: FC<IListItem> = React.memo((props) => {
  const {
    label,
    tag,
    icon,
    button,
    selectable,
    buttonType,
    children,
    bordered = false,
    hasChildren = false,
    onClick,
    isChildren = false,
    img,
    onSelect,
    countryKey,
    onButtonClick,
    onRemoveDefault,
    id
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showOnHover, setShowOnHover] = useState(false);

  const onMouseEnter = useCallback(() => {
    setShowOnHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowOnHover(false);
  }, []);

  return (
    <StyledListItem
      selected={true}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <StyledHoverContainer>
        <StyledLiContainer
          $bordered={bordered}
          $isChildren={isChildren}
          onClick={(event) => {
            event.stopPropagation();
            onClick && onClick();
          }}>
          <StyledLeftContainer>
            {(icon || img) && <StyledIconWrapper>
              {img && <Image src={img} alt={'Image'} preview={false} width={'100%'} height={'100%'}
                             style={{borderRadius: '50%'}}/>}
              {icon && icon}
            </StyledIconWrapper>}
            <Text size={'md'} color={'#68686B'}>{label}</Text>
            {tag && <SXTag type={'remove'} onRemoveClick={onRemoveDefault}>{tag}</SXTag>}
            {showOnHover && button && !tag &&
              <StyledButtonContainer>
                <SXTextButton
                  size={'xs'}
                  underline
                  onClick={() => onButtonClick && onButtonClick(id, label)}>
                  {button}
                </SXTextButton>
              </StyledButtonContainer>
            }
          </StyledLeftContainer>
          <StyledTRightContainer>
            {hasChildren && <StyledChildrenContainer onClick={() => {
              setIsOpen(prevState => !prevState);
              onClick && onClick();
            }}>
              {isOpen ? <OutlineArrowUpIcon/> : <OutlineArrowDownIcon/>}
            </StyledChildrenContainer>}
            {selectable && (buttonType === 'checkbox' ?
              <SXCheckbox onChange={() => onSelect && onSelect({name: label, key: countryKey, id})}/>
              : <SXRadioButton
                value={id}
                onChange={(value) => {
                  onSelect && onSelect({name: label, key: countryKey, id: value});
                }}/>)
            }
          </StyledTRightContainer>
        </StyledLiContainer>
      </StyledHoverContainer>
      {children?.length && <StyledContainer $bordered={bordered} $isChildren={isChildren}>
        {children}
      </StyledContainer>}
    </StyledListItem>
  )
})