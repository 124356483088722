import React, { forwardRef } from 'react';
import { CustomFieldsType } from 'typings';
import { CUSTOM_FIELD_TYPES } from 'typings';
import { Col, Form as AntdForm, Row, Space } from 'antd';
import { SXCheckbox, SXTag, SXTypography } from 'scribexUI';
import { useCustomFieldsFilter } from './useCustomFieldsFilter';

const {Title, Text} = SXTypography;

interface CustomFieldsFilter {
  onClose: (event?: React.MouseEvent) => void
}

export type CustomFieldsFilterRef = {
  onApply: () => void;
};
export const CustomFieldsFilter = forwardRef<CustomFieldsFilterRef, CustomFieldsFilter>((props, ref) => {
  const {onClose} = props;
  const {
    form,
    onFinish,
    onPublishedTagClick,
    onInUseTagClick,
    onPublishedTagRemove,
    onInUseTagRemove,
    contextHolder,
    filteredParams,
    usage
  } = useCustomFieldsFilter(ref, onClose);
  return (
    <>
      {contextHolder}
      <Row>
        <AntdForm
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Title level={5}>Status</Title>
                <Col span={24}>
                  <Space>
                    <AntdForm.Item name={'usage'} initialValue={filteredParams['usage']}>
                      <Row>
                        <SXTag
                          onClick={onInUseTagClick}
                          onRemoveClick={onInUseTagRemove}
                          selectable
                          type={'status'}
                          state={'base'}
                          status={'success'}
                          initialSelectValue={usage.inUse}
                          withDot>In Use</SXTag>
                        <SXTag
                          onClick={onPublishedTagClick}
                          onRemoveClick={onPublishedTagRemove}
                          type={'status'}
                          state={'base'}
                          selectable
                          initialSelectValue={usage.published}
                          withDot>Published</SXTag>
                      </Row>
                    </AntdForm.Item>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Title level={5}>Type</Title>
                <Col span={24}>
                  {Object.keys(CUSTOM_FIELD_TYPES).map((_key) =>
                    <AntdForm.Item key={_key} valuePropName="checked" name={_key} initialValue={filteredParams[_key]}>
                      <Row justify={'space-between'} style={{padding: '8px'}}>
                        <Text>{CUSTOM_FIELD_TYPES[_key as CustomFieldsType]}</Text>
                        <SXCheckbox defaultChecked={false} value={!!filteredParams[_key]}/>
                      </Row>
                    </AntdForm.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </AntdForm>
      </Row>
    </>
  )
})