import { useEffect, useState } from 'react';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { DoneIcon } from 'scribexUI/icons/DoneIcon';
import variables from 'assets/styles/variables.scss';


type CheckboxInputType = {
  $circle: boolean
  $color: string
  $outline: boolean
  $selectedColor: string
  $hoverColor: string
}

type StyledLabelTextType = {
  $font: string
  $color: string
  $hoverColor: string

}

type StyledOutlineInputType = {
  $selectedColor: string
  disabled: boolean
}


interface ISXCheckbox {
  circle?: boolean
  outline?: boolean
  disabled?: boolean
  title?: string
  font?: string
  color?: string
  hoverColor?: string
  selectedColor?: string
  defaultChecked?:boolean
  value?:boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  z-index: 99;
`;

const StyledChecbox = styled.div`
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  z-index: 10;
`;
const StyledLabelText = styled.span<StyledLabelTextType>`
  ${(props) => css`
    font-family: ${props.$font};
    color: ${props.$color};
  `}
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  cursor: pointer;

  &:hover {
    ${(props) => css`
      color: ${props.$hoverColor};
    `}
  }
`;

const CheckboxInput = styled.input<CheckboxInputType>`
  appearance: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  z-index: 10;
  box-sizing: border-box;

  ${(props) => css`
    border: 1px solid ${props.$color};
  `}
  ${(props) => props.$circle && css`
    border-radius: 360px;
  `}
  &:checked {
    ${(props) => !props.$outline && css`
      background-color: ${props.$selectedColor};
      border-color: ${props.$selectedColor};
    `}
    ${(props) => props.$outline && css`
      border: 1px solid ${props.$selectedColor};
      border-radius: 2px;
    `}
  }

  &:disabled {
    cursor: not-allowed;
    ${(props) => props.checked ? css`
      ${!props.$outline && 'background-color: #D5D5D5'};
      border-color: #D5D5D5;
    ` : css`
      background-color: #F8F8F8;
      border-color: #D5D5D5;
    `}
  }

  &:hover {
    ${(props) => !props.checked && css`
      background-color: ${props.$hoverColor ? 'transparent' : '#F8F8F8'};
      border-color: ${props.$hoverColor ? props.$hoverColor : '#D5D5D5'};
    `}
  }
`;

const StyledIconWrapper = styled.span<Record<any, any>>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  ${(props) => props.disabled && css`
    cursor: not-allowed;
    background-color: #D5D5D5;
  `}
`;

const StyledOutlineInput = styled.span<StyledOutlineInputType>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.$selectedColor};
  ${(props) => props.disabled && css`
    cursor: not-allowed;
    background-color: #D5D5D5;
  `}
`;

export const SXCheckbox = React.forwardRef<HTMLInputElement, ISXCheckbox>((props, ref) => {
  const {
    circle = false,
    outline = false,
    disabled = false,
    title,
    font = variables.mainFontRegular,
    color = '#A9A9AA',
    selectedColor = '#5865F2',
    hoverColor = '',
    defaultChecked,
    value,
    onChange
  } = props;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(prevState => !prevState);
    onChange && onChange(event);
  }, [onChange]);

  useEffect(() => {
    setIsChecked(value);
  },[value]);


  return (
    <CheckboxContainer>
      <StyledChecbox>
        <CheckboxInput
          ref={ref}
          disabled={disabled}
          $outline={outline}
          $circle={circle}
          $color={color}
          type="checkbox"
          $selectedColor={selectedColor}
          $hoverColor={hoverColor}
          checked={isChecked}
          onChange={handleCheckboxChange}
          onClick={(event) => event.stopPropagation()}
        />
        {(isChecked && !outline) && <StyledIconWrapper onClick={(event:React.MouseEvent<HTMLSpanElement>) => {event.stopPropagation()}} disabled={disabled}><DoneIcon/></StyledIconWrapper>}
        {(outline && isChecked) && <StyledOutlineInput $selectedColor={selectedColor} disabled={disabled}/>}
      </StyledChecbox>
      {title && <StyledLabelText $font={font} $color={isChecked ? selectedColor : color} $hoverColor={hoverColor} >{title}</StyledLabelText>}
    </CheckboxContainer>
  );
});

