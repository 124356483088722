export const HomeIcon = ({size = 16}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8607 15.1666H4.14065C2.31398 15.1666 0.833984 13.6799 0.833984 11.8532V6.91323C0.833984 6.00657 1.39398 4.86657 2.11398 4.30657L5.70732 1.50657C6.78732 0.666568 8.51398 0.626568 9.63398 1.41323L13.754 4.2999C14.5473 4.85323 15.1673 6.0399 15.1673 7.00657V11.8599C15.1673 13.6799 13.6873 15.1666 11.8607 15.1666ZM6.32065 2.29323L2.72732 5.09324C2.25398 5.46657 1.83398 6.31323 1.83398 6.91323V11.8532C1.83398 13.1266 2.86732 14.1666 4.14065 14.1666H11.8607C13.134 14.1666 14.1673 13.1332 14.1673 11.8599V7.00657C14.1673 6.36657 13.7073 5.4799 13.1807 5.1199L9.06065 2.23323C8.30065 1.6999 7.04732 1.72657 6.32065 2.29323Z"
        fill="#222222"/>
      <path
        d="M8 12.5C7.72667 12.5 7.5 12.2733 7.5 12V10C7.5 9.72667 7.72667 9.5 8 9.5C8.27333 9.5 8.5 9.72667 8.5 10V12C8.5 12.2733 8.27333 12.5 8 12.5Z"
        fill="#222222"/>
    </svg>
  )
}