import React, { FC } from 'react';
import { CookieData } from 'mock';
import { SXButton } from 'scribexUI';
import { viewportSize } from 'utils/helpers';
import { Col, CookieIcon, Row, Typography } from 'componnets';
import styles from './styles.module.scss'

const {Text} = Typography;
const {description, submitBtn, link} = CookieData;

interface ICookie {
  onMoreClick: () => void
  onAllowAll: () => void
}

export const Cookie: FC<ICookie> = React.memo((props) => {
  const {onMoreClick, onAllowAll} = props;
  const {width} =viewportSize()

  return (
    <Row align={'middle'} wrap={width < 577} className={styles.cookie}>
      <Col xs={3} sm={{flex: 'auto'}}>
        <CookieIcon/>
      </Col>
      <Col className={styles.description} xs={19} sm={{flex:'auto'}}>
        <Text>
          {description}
        </Text>
        <Text underline className={styles.link} onClick={onMoreClick}>
          {link}
        </Text>
      </Col>
      <Col sm={{flex:'144'}} xs={24}>
        <SXButton
          block={width < 577}
          roundness={'sharp'}
          size={'md'}
          bgColor={'#140D0B'}
          sxType={'primary'}
          onClick={onAllowAll}
        >{submitBtn}</SXButton>
      </Col>
    </Row>
  )
})