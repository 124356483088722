import React, { forwardRef } from 'react';
import { APPLICATION_METHOD, JOB_TYPES } from 'typings';
import { Col, Form as AntdForm, Row, Space } from 'antd';
import { SXCheckbox, SXTag, SXTypography } from 'scribexUI';
import { useJobFilter } from './useJobFilter';

const {Title, Text} = SXTypography;

interface CustomFieldsFilter {
  onClose: (event?: React.MouseEvent) => void
}

export type CustomFieldsFilterRef = {
  onApply: () => void;
};
export const JobFilter = forwardRef<CustomFieldsFilterRef, CustomFieldsFilter>((props, ref) => {
  const {onClose} = props;
  const {
    form,
    onFinish,
    onPublishedTagClick,
    onPublishedTagRemove,
    contextHolder,
    onRemoveTagClick,
    onRemoveTagRemove,
    filteredParams,
    usage
  } = useJobFilter(ref, onClose);
  return (
    <>
      {contextHolder}
      <Row style={{minHeight: '330px'}}>
        <AntdForm
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Title level={5}>Status</Title>
                <Col span={24}>
                  <Space size={4}>
                      <AntdForm.Item name={'published'} initialValue={[usage.published]}>
                        <SXTag
                          onClick={onPublishedTagClick}
                          onRemoveClick={onPublishedTagRemove}
                          selectable
                          type={'status'}
                          status={'published'}
                          state={'base'}
                          initialSelectValue={usage.published}
                          withDot>Published</SXTag>
                      </AntdForm.Item>
                      <AntdForm.Item name={'remove'} initialValue={[usage.archive]}>
                        <SXTag
                          onClick={onRemoveTagClick}
                          onRemoveClick={onRemoveTagRemove}
                          type={'status'}
                          state={'base'}
                          status={'removed'}
                          selectable
                          initialSelectValue={usage.archive}
                          withDot>Archive</SXTag>
                      </AntdForm.Item>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Title level={5}>Job Type</Title>
                {Object.keys(JOB_TYPES).map((_key, index) =>
                  <Col span={24} key={_key}>
                  <AntdForm.Item  valuePropName="checked" name={_key} initialValue={filteredParams[_key]}>
                    <Row justify={'space-between'} style={{padding: '8px', borderBottom: `${!index && '0.5px solid #E6E6E6'}`}}>
                      <Text>{JOB_TYPES[_key]}</Text>
                      <SXCheckbox defaultChecked={false} value={!!filteredParams[_key]}/>
                    </Row>
                  </AntdForm.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Title level={5}>Application Method</Title>
                {Object.keys(APPLICATION_METHOD).map((_key, index) =>
                  <Col span={24} key={_key}>
                    <AntdForm.Item  valuePropName="checked" name={_key} initialValue={filteredParams[_key]}>
                      <Row justify={'space-between'} style={{padding: '8px', borderBottom: `${!index && '0.5px solid #E6E6E6'}`}}>
                        <Text>{APPLICATION_METHOD[_key]}</Text>
                        <SXCheckbox defaultChecked={false} value={!!filteredParams[_key]}/>
                      </Row>
                    </AntdForm.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </AntdForm>
      </Row>
    </>
  )
})