import { Row } from 'componnets';
import React, { FC } from 'react';
import { Col, Image } from 'antd';
import { SXTypography } from 'scribexUI';
import styles from './styles.module.scss';

const {Text, Title} = SXTypography;

interface IEmptyScreen {
  icon?: string
  title?: string
  description?: string
  children?:React.ReactElement
}

export const EmptyScreen: FC<IEmptyScreen> = React.memo((props) => {
  const {icon, title, description,children} = props;
  return (
    <Row wrap={true} justify={'center'} gutter={[0, 8]} className={'w-100'}>
      {icon && <Image src={icon} alt={'Icon'} preview={false} style={{textAlign:'center'}}/>}
      <Col span={24} className={styles.title}>
        <Title level={4}>{title}</Title>
      </Col>
      <Text size={'md'} color={'##68686B'}>{description}</Text>
      <Col span={24}>
        <Row justify={'center'}>
          {children}
        </Row>
      </Col>
    </Row>
  )
})