import styled from 'styled-components';
import { SXTypography, TableColumn, SXCheckbox } from 'scribexUI';
import { SettingsDraggableColumn } from './SettingsDraggableColumn';
import React, { JSX, useCallback, useEffect, useState } from 'react';

const {Text} = SXTypography;

const Settings = styled.div`
  width: 400px;
  height: 258px;
  padding: 8px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
`;
const SettingsTopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SettingsBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 210px;
`;
const SettingsColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin;
  width: 50%;
`;
const SettingsSelectedColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin;
  width: 50%;
`;
const SettingsColumn = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #F8F8F8;
  }
`;
const SettingsDraggableColumnContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const StyledIndex = styled.span`
  width: 17px;
  padding: 0 6px;
`;

interface TableRowData {
  [key: string]: any;
}
interface ITableSettings<T> {
  columns: TableColumn<T>[]
  tableId: string
  onSettingsChange:(columns:TableColumn<T>[]) => void
}

export const TableSettings = React.memo(<T extends TableRowData>(props: ITableSettings<T>) => {
  const {columns, tableId, onSettingsChange} = props;
  const columnsToUpdate = JSON.parse(localStorage.getItem(tableId) as string);
  const columnsToShowInitialValue = columnsToUpdate.map((key: string) => columns.find(_column => _column.dataIndex === key));
  const [columnsToShow, setColumnsToShow] = useState<TableColumn<T>[]>(columnsToShowInitialValue);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setColumnsToShow((prevItems) => {
      const newItems = [...prevItems];
      const [draggedItem] = newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, draggedItem);
      return newItems;
    });
  }, []);

  const onColumnSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>, column: TableColumn<T>) => {
    setColumnsToShow(prevState => {
      return event.target.checked ? [
        ...prevState,
        column
      ] : prevState.filter(_column => _column.dataIndex !== column.dataIndex)
    });
  }, []);

  const onColumnRemoveClick = useCallback((id: string) => {
    setColumnsToShow(prevState => prevState.filter(_column => _column.dataIndex !== id));
  }, []);

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(columnsToShow.map(_column => _column.dataIndex)));
    onSettingsChange(columnsToShow);
  }, [columnsToShow, tableId, onSettingsChange]);

  return (
    <Settings>
      <SettingsTopContainer>
        <Text weight={'strong'}>Display Columns</Text>
        <Text color={'#68686B'} size={'sm'}>Select item and drag and drop to change arrangement in the table</Text>
      </SettingsTopContainer>
      <SettingsBottomContainer>
        <SettingsColumnsContainer>
          {columns.filter(item => item.title).map((_column: TableColumn<T>) => <SettingsColumn
              key={_column.dataIndex.toString()}
            >
              <Text color={'#68686B'} size={'md'}>{_column.title}</Text>
              <SXCheckbox
                defaultChecked={false}
                value={columnsToShow.some(showColumn => showColumn.dataIndex === _column.dataIndex)}
                onChange={(event) => onColumnSelect(event, _column)}/>
            </SettingsColumn>
          )}
        </SettingsColumnsContainer>
        <SettingsSelectedColumnsContainer>
          {columnsToShow.map((colum, index) => {
            const columnIndex = index + 1;
            return <SettingsDraggableColumnContainer key={colum.dataIndex as string}>
              <StyledIndex>
                <Text size={'md'}>{columnIndex}</Text>
              </StyledIndex>
              <SettingsDraggableColumn key={colum.dataIndex as string}
                                       id={colum.dataIndex as string}
                                       text={colum.title}
                                       index={index}
                                       moveItem={moveItem}
                                       onRemoveClick={onColumnRemoveClick}
              />
            </SettingsDraggableColumnContainer>
          })}
        </SettingsSelectedColumnsContainer>
      </SettingsBottomContainer>
    </Settings>
  )
}) as unknown as <T>(
  props: ITableSettings<T>
) => JSX.Element;