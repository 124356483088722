import { ErrorMappingValueType } from 'typings';

type CustomFieldCreationValidation = {
  [key: 'string' | string]: ErrorMappingValueType
}

export const CustomFieldValidation: CustomFieldCreationValidation = {
  DUPLICATE_CUSTOM_FIELD: {
    type: 'error',
    message: 'Custom field with this name already exist!'
  },
  NOT_FOUND: {
    type: 'error',
    message: 'Custom field not found!'
  },
}
export const JobValidation: CustomFieldCreationValidation = {
  NOT_FOUND: {
    type: 'error',
    message: 'Job not found!'
  },
}

export const ValidationErrors = {
  required: (fieldName: string) => `${fieldName} is required`
};