import { api } from 'api';
import { notification } from 'antd';
import { CUSTOM_FIELD_TYPES } from 'typings';
import { useAppDispatch } from 'store/hooks';
import { useTypedSelector } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'constants/routes';
import { getLatestSource } from 'utils/helpers';
import { CustomFieldsFilterRef } from 'componnets';
import { CustomFieldValidation } from 'constants/validations';
import { CustomFieldsType, ErrorMappingValueType } from 'typings';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { resetCustomFieldsFilterParams } from 'componnets/common/modal/filter/customFields/customFieldsFilterSlice';

export interface DataType {
  key: string;
  type: string;
  label: string;
  placeholder: string;
  characterLimit: string;
  usedCount: string
}

export interface IFields {
  id: string
  type: CustomFieldsType
  label: string
  placeholder: string
  characterLimit: string
  key: string
  action: string
  usedCount: string
}

export const useCustomFields = () => {
  const [currentField, setCurrentField] = useState<any>();
  const filterRef = useRef<CustomFieldsFilterRef>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isCreateFieldOpen, setIsCreateFieldOpen] = useState(false);
  const [fieldName, setFieldName] = useState<string | null>(null);
  const [currentData, setCurrentData] = useState<Record<string, any>>({});

  const {filteredParams} = useTypedSelector(state => state.customFieldsFilter);
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateField] = api.useUpdateCustomFieldMutation();
  const [search, {
    data: searchData,
    isUninitialized: isUninitializedSearch,
    isLoading: searchIsLoading,
    fulfilledTimeStamp: searchTimeStamp = 0,
    reset: searchReset
  }] = api.useCustomFieldSearchMutation();
  const [filter, {
    data: filterData,
    isUninitialized: isFilterUninitialized,
    isSuccess: filterIsSuccess,
    fulfilledTimeStamp: filterTimeStamp = 0,
    reset,
  }] = api.useCustomFieldFilterMutation({fixedCacheKey: 'customFieldFilterCache'});
  const {
    data = [],
    fulfilledTimeStamp: listTimeStamp = 0,
  } = api.useCustomFieldListQuery({size: perPageCount, page: currentPage}, {
    skip: !isFilterUninitialized || !isUninitializedSearch
  });

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const fieldsList: IFields[] = useMemo(() => {
    let mapData;
    const source = getLatestSource(Number(listTimeStamp), Number(filterTimeStamp), Number(searchTimeStamp));
    switch (source) {
      case 'list':
        mapData = data;
        break;
      case 'filter':
        mapData = filterData;
        break;
      case 'search':
        mapData = searchData;
        break;
      default:
        mapData = {};
    }
    if (mapData.totalElements !== currentData.totalElements) {
      setCurrentData(mapData);
    }
    return mapData?.content?.map((_field: IFields) => (
      {
        ..._field,
        type: CUSTOM_FIELD_TYPES[_field.type],
        key: _field.id,
        action: _field.id,
        typeKey: _field.type,
      })
    ) || []
  }, [data, searchData, filterData, currentData, searchTimeStamp, listTimeStamp, filterTimeStamp]);

  const routeChange = useCallback((id: string) => {
    const fieldData = fieldsList.find(_field => _field.key === id);
    navigate(`${ADMIN_ROUTES.customFields}/${id}`, {
      state: {
        fieldData,
      }
    });
  }, [navigate, fieldsList]);

  const onPageChange = useCallback((page: number, perPageCount: number) => {
    const source = getLatestSource(Number(listTimeStamp), Number(filterTimeStamp), Number(searchTimeStamp));
    switch (source) {
      case 'list':
        setCurrentPage(page);
        setPerPageCount(perPageCount);
        break;
      case 'filter':
        filter({size: perPageCount, page: page, ...filteredParams});
        break;
      case 'search':
        search({size: perPageCount, page: page, search: searchedValue});
        break;
      default:
        return
    }
  }, [listTimeStamp, filterTimeStamp, searchTimeStamp, filteredParams, filter, search, searchedValue]);

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      searchReset();
    } else {
      setSearchedValue(event.target.value);
      search({search: event.target.value});
    }
  }, [search, searchReset]);

  const onRemoveClick = useCallback((id: string) => {
    setIsOpen(prevState => !prevState);
    const field = fieldsList.find(_field => _field.key === id);
    setCurrentField(field);
    if (field?.label) {
      setFieldName(field.label);
    }
  }, [fieldsList]);

  const onRemoveConfirm = useCallback(() => {
    const payload = {
      ...currentField,
      type: currentField?.typeKey,
      removed: true,
      action: null,
      key: null,
      usedCount: null,
      typeKey: null
    }
    updateField(payload)
      .then(({data, error}: any) => {
        setIsOpen(false);
        if (data?.status && data?.status === "ok") {
          openNotificationWithIcon({type: 'success', message: "The custom field was successfully removed."});
        } else {
          if (data) {
            Object.keys(data).forEach((_key: string) => {
              if (_key === 'status') {
                openNotificationWithIcon(CustomFieldValidation[data[_key]]);
              } else {
                _key && openNotificationWithIcon({type: 'error', message: data[_key]});
              }
            })
          } else if (error?.data) {
            error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
          }
        }
      })
      .catch(err => {
        console.log(err, 'error')
      })
  }, [currentField, updateField, openNotificationWithIcon]);

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, []);
  const onCreateFieldClose = useCallback(() => {
    setIsCreateFieldOpen(false);
  }, []);
  const onCreateField = useCallback(() => {
    setIsCreateFieldOpen(true);
  }, []);
  const onFilterOpen = useCallback(() => {
    setIsFilterOpen(true);
  }, []);
  const onFilterClose = useCallback(() => {
    setIsFilterOpen(false);
  }, []);
  const onFilterApply = useCallback(() => {
    filterRef.current?.onApply();
  }, [filterRef]);

  const onFilterReset = useCallback(() => {
    if (!isFilterUninitialized) {
      dispatch(resetCustomFieldsFilterParams());
      reset();
      return;
    }
    setIsFilterOpen(false);
  }, [isFilterUninitialized, reset, dispatch]);


  return {
    onRemoveClick,
    isOpen,
    fieldsList,
    onClose,
    fieldName,
    isCreateFieldOpen,
    onCreateFieldClose,
    onCreateField,
    routeChange,
    onRemoveConfirm,
    contextHolder,
    onSearch,
    searchIsLoading,
    isSearched: !!searchedValue,
    isFilterOpen,
    onFilterClose,
    onFilterReset,
    onFilterOpen,
    onFilterApply,
    filterRef,
    onPageChange,
    isFiltered: !isFilterUninitialized && filterIsSuccess,
    totalItems: currentData?.totalElements
  }
}