import styled, { css } from 'styled-components';

type StyledMessageType = {
  $isValid?: boolean
}

export const StyledMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  width: 100%;
`;

export const StyledValidationIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;

export const StyledMessage = styled.span<StyledMessageType>`
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 12px;
  line-height: 16px;

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css`
        color: #68686B;
      ` : css`
        color: #F5222D;
      `
    }
  }
  }
`;
