export const AddIcon = ({color = 'white'}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 9.0625H4.5C4.48694 9.0625 4.47128 9.0573 4.45699 9.04301C4.4427 9.02872 4.4375 9.01306 4.4375 9C4.4375 8.98694 4.4427 8.97128 4.45699 8.95699C4.47128 8.9427 4.48694 8.9375 4.5 8.9375H13.5C13.5131 8.9375 13.5287 8.9427 13.543 8.95699C13.5573 8.97128 13.5625 8.98694 13.5625 9C13.5625 9.01306 13.5573 9.02872 13.543 9.04301C13.5287 9.0573 13.5131 9.0625 13.5 9.0625Z"
        fill="#222222" stroke={color}/>
      <path
        d="M9 13.5625C8.98694 13.5625 8.97128 13.5573 8.95699 13.543C8.9427 13.5287 8.9375 13.5131 8.9375 13.5V4.5C8.9375 4.48694 8.9427 4.47128 8.95699 4.45699C8.97128 4.4427 8.98694 4.4375 9 4.4375C9.01306 4.4375 9.02872 4.4427 9.04301 4.45699C9.0573 4.47128 9.0625 4.48694 9.0625 4.5V13.5C9.0625 13.5131 9.0573 13.5287 9.04301 13.543C9.02872 13.5573 9.01306 13.5625 9 13.5625Z"
        fill="#222222" stroke={color}/>
    </svg>
  )
}