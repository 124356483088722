import React, { FC } from 'react';
import { Row } from 'componnets';
import { OptionsSelectList, OptionType, SXBackdrop } from 'scribexUI';
import styles from './styles.module.scss';

interface IActionList {
  options: OptionType[]
  onClose:() => void
  onSelect:(event: React.MouseEvent, item: OptionType) => void
}

export const ActionList: FC<IActionList> = React.memo((props) => {
  const {options, onClose, onSelect} = props;
  return <>
    <SXBackdrop onBackdropClick={onClose}/>
    <Row className={styles.action_list}>
      <OptionsSelectList
        bordered
        selectedValue={[]}
        options={options}
        onOptionSelect={onSelect}
      />
    </Row>
  </>
})