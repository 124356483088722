import React from 'react';
import styled, { css } from 'styled-components';
import { DangerIcon, InfoIcon } from 'scribexUI/icons';
import { ValidationInfoType } from 'scribexUI/typings';
import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { StyledMessage, StyledMessageWrapper, StyledValidationIcon } from '../../errorMessage';

const sizes: Record<any, any> = {
  sm: {
    padding: '7px 16px',
    fontSize: '14px',
    lineHeight: '20px'
  },
  lg: {
    padding: '11px 16px',
    fontSize: '16px',
    lineHeight: '24px'
  },
  fill: {
    fontSize: '14px',
    lineHeight: '20px'
  },
};

const width: Record<any, any> = {
  sm: {
    maxWidth: '343px',
  },
  lg: {
    maxWidth: '450px',
  },
  fill: {
    maxWidth: '100%',
  },
};

interface InputTYpe extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string,
  initialValue?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  label?: string,
  disabled?: boolean,
  id?: string,
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
  line?: boolean;
  $line?: boolean;
  inpSize?: 'sm' | 'lg' | 'fill',
  $inpSize?: 'sm' | 'lg' | 'fill',
}

const inputStyles = css<InputTYpe>`
  border: none;
  font-family: DMSans-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 109px;
  color: #140D0B;
  resize: none;

  &:focus {
    border: none;
    color: #331DAD;
    outline: none;

    &::placeholder {
      color: #331DAD;
    }
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: #140D0B;
  }

  ${(props) =>
          props.disabled && css<InputTYpe>`
            background-color: #ffffff;
            cursor: not-allowed;
            color: rgba(20, 13, 11, .5);

            &::placeholder {
              color: rgba(20, 13, 11, .5);
            }
          `
  }
`
const StyledInput = styled.textarea<InputTYpe>`
  ${inputStyles}
`;

const StyledInputContainer = styled.div<InputTYpe>`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  ${(props) => width[props.$inpSize as string]}
`;

const StyledInputWrapper = styled.div<InputTYpe>`
  border-radius: 6px;
  background-color: #FFFFFF;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;

  ${(props) => sizes[props.$inpSize as string]}
  ${(props) =>
          props.$line ? css<InputTYpe>`
            border-bottom: 1px solid #140D0B;
            border-radius: 0;
          ` : css<InputTYpe>`
            border: 1px solid #140D0B;
          `
  }
  &:focus-within {
    ${(props) => {
      if (typeof props.$isValid !== "undefined") {
        return props.$isValid && css<InputTYpe>`border-color: #331DAD`;
      } else {
        return css<InputTYpe>`border-color: #331DAD`;
      }
    }
    }
  }

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css<InputTYpe>`
        border-color: #140D0B;
      ` : css<InputTYpe>`
        border-color: #F5222D;
      `
    }
  }
  }

  ${(props) =>
          props.disabled && css<InputTYpe>`
            border-color:  rgba(20, 13, 11, .5);
            color:  rgba(20, 13, 11, .5);
            cursor: not-allowed;
          `
  }
`;

const StyledInputLabel = styled.label<InputTYpe>`
  padding: 0;
  margin: 0;
  font-family: DMSans-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            color: #68686B;
          `
  }
`;

export const SXTextArea = React.forwardRef<HTMLTextAreaElement, InputTYpe>((props, ref) => {
  const {
    placeholder,
    onChange,
    label,
    disabled,
    validationInfo = {},
    isValid,
    line,
    inpSize = 'lg',
    initialValue = "",
    ...additionalElements
  } = props;


  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  const [inpVal, setInpVal] = useState(initialValue);

  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }
  const uniqueId = Math.random().toString(16).slice(2) + Date.now();
  const isValidationMessageVisible = !disabled && message;

  return (
    <StyledInputContainer $inpSize={inpSize}>
      {label && <StyledInputLabel htmlFor={uniqueId} disabled={disabled}>
        {label}
      </StyledInputLabel>}
      <StyledInputWrapper
        disabled={disabled}
        $isValid={isValid}
        $line={line}
        $inpSize={inpSize}
      >
        <StyledInput
          {...additionalElements}
          ref={ref}
          disabled={disabled}
          id={uniqueId}
          placeholder={placeholder}
          value={inpVal}
          onChange={(event) => {
            setInpVal(event.target.value);
            onChange && onChange(event);
          }}
        />
      </StyledInputWrapper>
      {isValidationMessageVisible && <StyledMessageWrapper>
        <StyledValidationIcon>
          {!disabled && icon}
        </StyledValidationIcon>
        <StyledMessage $isValid={isValid}>
          {!disabled && message}
        </StyledMessage>
      </StyledMessageWrapper>}
    </StyledInputContainer>
  );
});