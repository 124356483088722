import { api } from 'api';
import { notification } from 'antd';
import { ADMIN_ROUTES } from 'constants/routes';
import { SignupErrorMappingValueType } from 'typings';
import { useCallback, useEffect, useState } from 'react';
import { ResetPasswordSuccessMapping } from 'constants/app';
import { useNavigate, useSearchParams } from 'react-router-dom';

const passwordRegexp = new RegExp(/^(?=.*[A-Z])(?=.*\d).{8,}$/);

export const useAdminResetPassword = (formRef: any) => {
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [checkPasswordReset, {isSuccess, isError}] = api.useAdminCheckPasswordResetMutation();

  const [finishPasswordReset, {
    isSuccess: finishPasswordIsSuccess,
    isError: finishPasswordIsError,
  }] = api.useAdminFinishPasswordResetMutation();

  const [notificationApi, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (messageContent: SignupErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  };

  const onInputBlur = useCallback(({value, type}: Record<string, string>) => {
    if (type === 'password') {
      setPasswordIsValid(passwordRegexp.test(value));
    } else if (type === 'confirmPassword') {
      setConfirmPasswordIsValid(value === (formRef.getFieldsValue().password || ''));
    }
  }, [formRef]);

  const validateFields = useCallback((formFields: Record<string, string>) => {
    const passValidation = passwordRegexp.test(formFields.password);
    const confirmPassValidation = formFields.password === formFields.confirmPassword;
    setPasswordIsValid(passValidation);
    setConfirmPasswordIsValid(confirmPassValidation);
    return passValidation && confirmPassValidation;
  }, []);

  const onSubmit = useCallback(() => {
    const isValid = validateFields(formRef.getFieldsValue());
    if (isValid) {
      const data = {
        passwordRepeat: formRef.getFieldsValue().confirmPassword,
        password: formRef.getFieldsValue().password,
        resetToken: searchParams.get('key')
      }
      finishPasswordReset(data);
    }
  }, [formRef, validateFields, finishPasswordReset, searchParams]);

  useEffect(() => {
    if (searchParams.get('key')) {
      checkPasswordReset({resetToken: searchParams.get('key')})
    }
  }, [checkPasswordReset, searchParams]);

  if (isSuccess) {
    // TODO handle error cases
  }
  if (isError) {
    // TODO handle error cases
  }
  if (finishPasswordIsError) {
    // TODO handle error cases
  }
  if (finishPasswordIsSuccess) {
    openNotificationWithIcon(ResetPasswordSuccessMapping['RESET_PASSWORD_SUCCESS']);
    navigate(ADMIN_ROUTES.adminSignIn);
  }
  return {
    onInputBlur,
    passwordIsValid,
    confirmPasswordIsValid,
    onSubmit,
    contextHolder
  }
}