import { FC } from 'react';
import variables from '../styles/variables.scss'

interface IOutlineLeftArrowIcon {
  disabled?: boolean
}

export const OutlineLeftArrowIcon: FC<IOutlineLeftArrowIcon> = ({disabled}) => {
  const color = disabled ? variables.textPlaceholder : variables.textPrimary;
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.89846 2.9843V1.92747C9.89846 1.83587 9.79318 1.78528 9.72209 1.84133L3.55881 6.6552C3.50644 6.69592 3.46407 6.74807 3.43492 6.80766C3.40578 6.86725 3.39062 6.93271 3.39062 6.99905C3.39062 7.06539 3.40578 7.13085 3.43492 7.19044C3.46407 7.25003 3.50644 7.30217 3.55881 7.3429L9.72209 12.1568C9.79455 12.2128 9.89846 12.1622 9.89846 12.0706V11.0138C9.89846 10.9468 9.86701 10.8825 9.81506 10.8415L4.89318 6.99973L9.81506 3.15657C9.86701 3.11555 9.89846 3.0513 9.89846 2.9843Z"
        fill={color}/>
    </svg>
  )
}