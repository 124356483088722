import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseUrl = process.env.REACT_APP_API_URL;

export type AppUserData = {
  email: string,
  name: string,
  type: string,
  password: string,
  passwordRepeat: string
};

export const api = createApi({
    tagTypes: ['User', 'Admin', 'Country', 'Language', 'HomeCountries', 'AdminSettings', 'CustomField', 'Job', 'Tag'],
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
      baseUrl,
      credentials: 'include',
    }),
    endpoints: (builder) => ({
      template: builder.mutation<any, any>({
        query: (data) => ({
          url: 'scribex/home/template',
          method: 'post',
          body: data,
        }),
      }),
      jobList: builder.query<any, void>({
        query: () => ({
          url: '/scribex/api/job/publish/list',
          method: 'get',
        }),
      }),
      formTemplate: builder.mutation<any, any>({
        query: (data) => ({
          url: '/scribex/api/form/create',
          method: 'post',
          body: data,
        }),
      }),
      createCustomField: builder.mutation<any, any>({
        query: (data) => ({
          url: '/scribex/api/custom_field',
          method: 'post',
          body: data,
        }),
        invalidatesTags: () => ["CustomField"]
      }),
      updateCustomField: builder.mutation<any, any>({
        query: (data) => ({
          url: '/scribex/api/custom_field',
          method: 'put',
          body: data,
        }),
        invalidatesTags: () => ["CustomField"]
      }),
      customFieldList: builder.query<any, Record<'size' | 'page', number>>({
        query: (args) => {
          const {size = 10, page = 1} = args;
          const number = page - 1;
          return {
            url: `/scribex/api/custom_field/list?size=${size}&page=${number}`,
            method: 'get',
          }
        },
        providesTags: () => ["CustomField"]
      }),
      customFieldSearch: builder.mutation<any, any>({
        query: (data) => {
          const {size = 10, page = 1, ...rest} = data;
          const number = page - 1;
          return {
            url: `/scribex/api/custom_field/search?size=${size}&page=${number}`,
            method: 'post',
            body: rest
          }
        },
      }),
      customFieldFilter: builder.mutation<any, any>({
        query: (data) => {
          const {size = 10, page = 1, ...rest} = data;
          const number = page - 1;
          return {
            url: `/scribex/api/custom_field/filter?size=${size}&page=${number}`,
            method: 'post',
            body: rest
          }
        },
      }),
      createJob: builder.mutation<any, any>({
        query: (data) => ({
          url: 'scribex/api/job',
          method: 'post',
          body: data
        }),
        invalidatesTags: () => ["Job", 'Tag']
      }),
      updateJob: builder.mutation<any, any>({
        query: (data) => ({
          url: '/scribex/api/job',
          method: 'put',
          body: data,
        }),
        invalidatesTags: () => ["Job", 'Tag']
      }),
      jobAnnouncementList: builder.query<any, Record<'size' | 'page', number>>({
        query: (args) => {
          const {size = 10, page = 1} = args;
          const number = page - 1;
          return {
            url: `scribex/api/job/list?size=${size}&page=${number}`,
            method: 'get',
          }
        },
        providesTags: () => ['Job']
      }),
      jobSearch: builder.mutation<any, any>({
        query: (data) => {
          const {size = 10, page = 1, ...rest} = data;
          const number = page - 1;
          return {
            url: `/scribex/api/job/search?size=${size}&page=${number}`,
            method: 'post',
            body: rest
          }
        },
      }),
      jobFilter: builder.mutation<any, any>({
        query: (data) => {
          const {size = 10, page = 1, ...rest} = data;
          const number = page - 1;
          return {
            url: `/scribex/api/job/filter?size=${size}&page=${number}`,
            method: 'post',
            body: rest
          }
        },
      }),
      tagList: builder.query<any, void>({
        query: () => ({
          url: 'scribex/api/job/tags',
          method: 'get',
        }),
        providesTags: () => ['Tag']
      }),


      adminSignIn: builder.mutation<any, any>({
        query: (data) => ({
          url: 'scribex/auth',
          method: 'post',
          body: data
        }),
        invalidatesTags: () => ['Admin'],
      }),
      adminPasswordReset: builder.mutation({
        query: (data) => ({
          url: '/uaa/admin/api/password-reset',
          method: 'post',
          body: data
        }),
      }),
      adminCheckPasswordReset: builder.mutation({
        query: (data) => ({
          url: '/uaa/admin/api/check-password-reset',
          method: 'post',
          body: data
        }),
      }),
      adminFinishPasswordReset: builder.mutation({
        query: (data) => ({
          url: '/uaa/admin/api/finish-password-reset',
          method: 'post',
          body: data
        }),
      }),
      signOut: builder.mutation<any, void>({
        query: () => ({
          url: '/auth/logout',
          method: 'post',
        }),
        invalidatesTags: () => ['User', 'Admin'],
      }),

    })
  })
;

export const apiAuthMiddleware = () => (next: any) => (action: any) => {
  if (action.type.startsWith('api/') && action.meta) {
    if (action.meta.requestStatus === 'fulfilled') {
      const newToken = action.meta.baseQueryMeta.response.headers.get('X-XSRF-TOKEN');
      if (newToken) {
        // localStorage.setItem('xsrf_token', newToken);
      }
    }
  }
  return next(action);
};
