import { EmailVerificationType, OpenPositionsType, ResetPasswordFormType, ServiceType, SigninFormType } from 'typings';
import checkEmailIcon from 'assets/icons/check-email.svg';

export const CookieData = {
  description: 'We use cookies and similar technologies. By clicking “Allow All” you agree to this. ',
  link: 'Learn more',
  submitBtn: 'Allow All'
}
export const ManageCookieData = {
  title: 'Manage Cookies',
  description: 'Cookies are small text that can be used by websites to make the user experience  more efficient. The law states that we may store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your  permission. This site uses various types of cookies. Some cookies are placed by third party  services that appear on our pages.',
  rejectBtn: 'Reject All',
  necessaryBtn: 'Only Necessary',
  allBtn: 'Allow All',
  cookieOption: [
    {
      title: 'Essential',
      key: 'essential',
      description: 'Necessary for the basic functionality of the website, such as authentication and access to secure areas.',
      necessary: true
    },
    {
      title: 'Performance',
      key: 'performance',
      description: 'Collect data on website usage to enhance its performance. This includes analytics that help improve user experience.',
      necessary: false
    },
    {
      title: 'Functionality',
      key: 'functionality',
      description: 'Enable a website to remember choices you\'ve made (like language preferences) and provide enhanced features.',
      necessary: false
    },
    {
      title: 'Advertising',
      key: 'advertising',
      description: 'Enable a website to remember choices you\'ve made (like language preferences) and Used to deliver targeted advertisements based on your interests and online behavior.provide enhanced features.',
      necessary: false
    },
    {
      title: 'Third-Party Cookies',
      key: 'third-party',
      description: 'Your permission applies to the following domains:',
      additionalList: [
        {
          id: 1,
          label: 'cookieinfo.net'
        },
        {
          id: 2,
          label: 'website.cookieinfo.net'
        }
      ],
      necessary: false
    }
  ]
};

export const servicesData: ServiceType[] = [
  {
    title: 'WEB DEVELOPMENT',
    description: 'Our web development services focus on creating responsive, secure, and SEO-friendly websites that help you connect with your audience and achieve your business objectives. From simple landing pages to complex web applications, our team ensures your online presence is compelling and effective.',
    id: '1',
    optionList: [
      {
        label: 'Custom Website Development',
        info: 'Tailored solutions that align with your brand and business goals.',
        id:'1_1'
      },
      {
        label: 'E-commerce Solutions',
        info: 'Building powerful online stores with seamless shopping experiences.',
        id:'1_2'
      },
      {
        label: 'Content Management Systems (CMS)',
        info: 'Implementing easy-to-use CMS platforms and custom solutions to give you control and flexibility over your content.',
        id:'1_3'
      }
    ]
  },
  {
    title: 'DIGITAL TRANSFORMATION',
    description: 'Transform your business processes and operations with our digital transformation services. We help organizations embrace cutting-edge technologies and innovative solutions to stay competitive in today’s fast-paced digital landscape.',
    id: '2',
    optionList: [
      {
        label: 'Business Process Automation',
        info: 'Streamlining workflows and increasing efficiency with automated solutions.',
        id:'2_1'
      },
      {
        label: 'Cloud Services',
        info: 'Enabling scalability, flexibility, and cost savings through cloud integration and migration.',
        id:'2_2'
      },
      {
        label: 'Data Analytics & Business Intelligence',
        info: 'Leveraging data to drive strategic decision-making and uncover new opportunities.',
        id:'2_3'
      },
      {
        label: 'Enterprise Software Development',
        info: 'Creating custom software solutions to address unique business challenges.',
        id:'2_4'
      }
    ]
  },
  {
    title: 'MOBILE APP DEVELOPMENT',
    description: 'At Scribex, we design and develop custom mobile applications that drive business growth and deliver exceptional user experiences. Our team of experts leverages the latest technologies and industry best practices to create apps that are not only visually stunning but also highly functional and scalable.',
    id: '3',
    optionList: [
      {
        label: 'iOS, Android, Cross-Platform App Development',
        info: 'Crafting high-performance, intuitive, and user-friendly apps for iOS and Android platforms, as well as developing cross-platform solutions that work seamlessly across multiple devices, ensuring maximum reach and user engagement.',
        id:'3_1'
      }
    ]
  },
  {
    title: 'MVP DEVELOPMENT',
    description: 'We understand the importance of bringing your ideas to market quickly and efficiently. Our MVP development services are designed to help you validate your concept, gather user feedback, and minimize risk before committing to a full-scale product launch.',
    id: '4',
    optionList: [
      {
        label: 'Rapid Prototyping',
        info: 'Quickly creating functional prototypes to test core features and gather early feedback.',
        id:'4_1'
      },
      {
        label: 'User-Centric Design',
        info: 'Focusing on user needs and behaviors to ensure the MVP provides a valuable and engaging experience.',
        id:'4_2'
      },
      {
        label: 'Cost-Effective Solutions',
        info: 'Developing a streamlined version of your product with essential features to reduce development time and costs.',
        id:'4_3'
      },
      {
        label: 'Iterative Development',
        info: 'Continuously improving the product based on user feedback and market insights.',
        id:'4_4'

      },
      {
        label: 'Market Validation',
        info: 'Helping you understand market demand and refine your value proposition before investing in full-scale development.',
        id:'4_5'
      }
    ]
  },
  {
    title: 'UX/UI DESIGN',
    description: 'Our design team is dedicated to creating visually appealing and user-centric designs that enhance user experience and foster engagement. We follow a meticulous design process to ensure your digital products are intuitive, accessible, and delightful to use.',
    id: '5',
    optionList: [
      {
        label: 'User Research & Strategy',
        info: 'Understanding user needs and behaviors to inform design decisions.',
        id:'5_1'
      },
      {
        label: 'Wireframing & Prototyping',
        info: 'Creating interactive prototypes to visualize the user journey and interface.',
        id:'5_2'
      },
      {
        label: 'Visual Design',
        info: 'Crafting aesthetically pleasing designs that align with your brand identity.',
        id:'5_3'
      },
      {
        label: 'Design Systems',
        info: 'Developing comprehensive design systems to ensure consistency and efficiency across all your digital products.',
        id:'5_4'
      }
    ]
  },
  {
    title: 'DIGITAL STRATEGY & CONSULTING',
    description: 'Scribex’s digital strategy and consulting services are designed to help you navigate the complexities of the digital world and achieve your business objectives. We work closely with you to develop and implement strategies that drive growth, innovation, and success.',
    id: '6',
  },
  {
    title: 'PRODUCT MANAGEMENT',
    description: 'Our product management services ensure your projects are delivered on time, within budget, and to the highest quality standards. We provide end-to-end project management, from initial concept to final deployment, ensuring a seamless and successful product launch.',
    id: '7',
    optionList: [
      {
        label: 'Project Planning & Roadmapping',
        info: 'Defining project goals, milestones, and deliverables.',
        id:'7_1'
      },
      {
        label: 'Agile Development',
        info: 'Implementing agile methodologies to enhance collaboration and flexibility.',
        id:'7_2'
      },
      {
        label: 'Quality Assurance',
        info: 'Rigorous testing and quality control to ensure flawless performance and user satisfaction.',
        id:'7_3'
      }
    ]
  }
];

export const OpenPositions:OpenPositionsType[] = [
  {
    jobTitle:'Product Designer',
    jobType:'Fulltime',
    jobLocation:'Remote',
    link:'APPLY',
    jobCountry:'Dubai',
    jobPosition:'Senior',
    salaryRange:'100-200k',
    description:'We are looking for a highly skilled and creative Senior Product Designer. As a Senior Product Designer, your role is to solve design problems and create exceptional user experiences for various software products. In collaboration with the cross-functional teams, you should conduct research and use design systems for consistency.',
    jobResponsibilities:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    requiredQualifications:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    ourOfferIncludes:[
      'Flexibility in processes and KPIs (we are always focused on results)',
      'A team of people with an open-minded approach'
    ],
    id:'1'
  },
  {
    jobTitle:'iOS Developer',
    jobType:'Fulltime',
    jobLocation:'Remote',
    link:'APPLY',
    jobCountry:'Dubai',
    jobPosition:'Senior',
    salaryRange:'100-200k',
    description:'We are looking for a highly skilled and creative Senior Product Designer. As a Senior Product Designer, your role is to solve design problems and create exceptional user experiences for various software products. In collaboration with the cross-functional teams, you should conduct research and use design systems for consistency.',
    jobResponsibilities:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    id:'2'
  },
  {
    jobTitle:'QA Engineer',
    jobType:'Fulltime',
    jobLocation:'Remote',
    link:'APPLY',
    jobCountry:'Dubai',
    jobPosition:'Senior',
    salaryRange:'100-200k',
    description:'We are looking for a highly skilled and creative Senior Product Designer. As a Senior Product Designer, your role is to solve design problems and create exceptional user experiences for various software products. In collaboration with the cross-functional teams, you should conduct research and use design systems for consistency.',
    jobResponsibilities:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    id:'3'
  },
  {
    jobTitle:'HR Manager',
    jobType:'Fulltime',
    jobLocation:'Remote',
    link:'APPLY',
    jobCountry:'Dubai',
    jobPosition:'Senior',
    salaryRange:'100-200k',
    description:'We are looking for a highly skilled and creative Senior Product Designer. As a Senior Product Designer, your role is to solve design problems and create exceptional user experiences for various software products. In collaboration with the cross-functional teams, you should conduct research and use design systems for consistency.',
    jobResponsibilities:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    id:'4'
  },
  {
    jobTitle:'HR Manager',
    jobType:'Fulltime',
    jobLocation:'Remote',
    link:'APPLY',
    jobCountry:'Dubai',
    jobPosition:'Senior',
    salaryRange:'100-200k',
    description:'We are looking for a highly skilled and creative Senior Product Designer. As a Senior Product Designer, your role is to solve design problems and create exceptional user experiences for various software products. In collaboration with the cross-functional teams, you should conduct research and use design systems for consistency.',
    jobResponsibilities:[
      '4+ years of experience in UI/UX Design/Product Design',
      'Proficiency in Figma;'
    ],
    id:'5'
  }
]

export const AdminSignInFormData: SigninFormType = {
  title: 'Log In',
  form: [
    {
      label: 'Email *',
      placeholder: 'Email',
      name: 'email',
      id: 2,
      validationInfo: {
        error: 'Please provide valid email address!'
      }
    },
    {
      label: 'Password *',
      placeholder: 'Password',
      name: 'password',
      id: 3,
      isPassword: true,
      validationInfo: {
        error: 'Password can\'t be empty!'
      }
    }
  ],
  forgotPass: 'Forgot password?',
  submitBtn: 'Log In',
};

export const AdminResetPasswordData: ResetPasswordFormType = {
  title: 'Reset Password',
  description: "Enter your email and we'll send you instructions to reset your password",
  form: {
    password: {
      placeholder: 'New Password',
      label: 'New Password *',
      name: 'password',
      isPassword: true,
      id: 1,
      validationInfo: {
        info: 'Password must be at least 8 characters, one number one uppercase letter.',
        error: 'Password must be at least 8 characters, one number one uppercase letter.'
      }
    },
    confirmPassword: {
      placeholder: 'Confirm New Password',
      label: 'Confirm New Password *',
      name: 'confirmPassword',
      isPassword: true,
      id: 2,
      validationInfo: {
        error: 'Passwords doesn\'t match.'
      }
    },
  },
  submitBtn: 'Confirm'
};

export const AdminForgotPasswordData: ResetPasswordFormType = {
  title: 'Forgot Password',
  description: "Enter your email and we'll send you instructions to reset your password",
  form: {
    email: {
      placeholder: 'Email',
      label: 'Email *',
      name: 'email',
      id: 1,
      validationInfo: {
        error: 'Please provide valid email address!'
      }
    }
  },
  submitBtn: 'Send'
};

export const CheckEmailData: EmailVerificationType = {
  title: 'Check your Email',
  descriptionOne: 'Please check your email for password reset instructions.',
  submitBtn: 'Okay',
  icon: checkEmailIcon
}