import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { SettingsHeaderIcon } from 'componnets';
import { TableSettings } from './TableSettings';

export interface TableColumn<T> {
  title: string;
  dataIndex: keyof T;
  render?: (value: T[keyof T]) => React.ReactNode;
  editable?: boolean;
  fixed?: 'left' | 'right'
  width?: number | string
}

interface TableRowData {
  [key: string]: any;
}

interface TableProps<T> {
  columns: any;
  dataSource: T[];
  onRowSelect?: (selectedRows: T[]) => void;
  withCheckbox?: boolean
  showedColumnCount?: number
  tableId: string
}

type FixedType = {
  $fixed: string | undefined
  $width: number | undefined | string
  $showedColumnCount: number
  $index: number
}

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1073px;
`;

const TableContainer = styled.div`
  border: 1px solid #E6E6E6;
  overflow-x: auto;
  position: relative;
  box-sizing: content-box;
`;

const TableSettingsButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  border: 1px solid #E6E6E6;
  margin-left: auto;
  cursor: pointer;
`;

const TableSettingsContainer = styled.div`
  position: absolute;
  background-color: #FFFFFF;
  z-index: 999;
  right: 0;
  top: 36px

`;

const TableHeader = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
`;

const TableHeaderCell = styled.div<FixedType>`
  flex: 0 0 20%;
  font-family: DMSans-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
  border-right: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  background-color: #F8F8F8;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #222222;

  &:last-child {
    border-right: none;
  }

  ${({$fixed}) =>
          $fixed && css`
            position: sticky;
            z-index: 1;`
  };
  ${({$fixed, $index}) =>
          $fixed === 'left' && css`
            left: ${$index * 250 + 'px'};
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          `
  };
  ${({$fixed}) =>
          $fixed === 'right' && css`
            right: 0;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          `
  };
  ${({$width, $showedColumnCount}) =>
          $width && css`
            flex: 0 0 ${$showedColumnCount < 5 ? (1000 / $showedColumnCount + 'px') : $width};
          `
  };
  ${({$index}) => $index === 100 && 'margin-left:auto'};
`;

const TableBody = styled.div``;

const TableRow = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    & > div {
      background-color: rgba(115, 127, 255, 0.1);
    }
  }
`;

const TableCell = styled.div<FixedType>`
  flex: 0 0 20%;
  border-right: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  height: 100%;
  font-family: DMSans-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #222222;
  background-color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  &:last-child {
    border-right: none;
  }

  ${({$fixed}) =>
          $fixed && css`
            position: sticky;
            background-color: #FFFFFF;
            z-index: 3;`
  };
  ${({$fixed, $index}) =>
          $fixed === 'left' && css`
            left: ${$index * 250 + 'px'};
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          `
  };
  ${({$fixed}) =>
          $fixed === 'right' && css`
            right: 0;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          `
  };
  ${({$width, $showedColumnCount}) =>
          $width && css`
            flex: 0 0 ${$showedColumnCount < 5 ? (1000 / $showedColumnCount + 'px') : $width};
          `
  };
  ${({$index}) => $index === 100 && css`
    margin-left: auto;
    background-color: #FFFFFF !important;`
  };
`;

const EditableCell = styled.input`
  width: 100%;
  padding: 0;
  border: none;

  &:focus {
    border: none;
  }

  &:focus-within {
    border: none;
  }

  &:focus-visible {
    border: none;
  }
`;

const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #E6E6E6;
  padding: 0 16px;
  height: 100%;

  &:last-child {
    border-right: none;
  }
`;

const CheckboxInput = styled.input`
`;

export const SXTable = <T extends TableRowData>(props: TableProps<T>) => {
  const {
    columns,
    dataSource,
    onRowSelect,
    withCheckbox,
    showedColumnCount = 4,
    tableId
  } = props;
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);
  const [columnsToShow, setColumnsToShow] = useState<TableColumn<T>[]>(columns);
  const action = columns.find((_column: any) => _column.dataIndex === 'key');
  const columnsToUpdate = JSON.parse(localStorage.getItem(tableId) as string);
  if (columnsToUpdate?.length) {
    const updatedColumn = columnsToUpdate.map((key: string) => columns.find((_column: any) => _column.dataIndex === key));
    if (updatedColumn.length !== columnsToShow.length) {
      // TODO need to check deep equality
      setColumnsToShow(updatedColumn);
    }
  } else {
    const showColumns = columns.filter((_column: any, index: number) => (index < showedColumnCount && _column.dataIndex !== 'key'));
    localStorage.setItem(tableId, JSON.stringify(showColumns.map((_column: any) => _column.dataIndex)));
    setColumnsToShow(showColumns);
  }
  const handleRowSelect = (rowIndex: number) => {
    const isSelected = selectedRows.some(row => row === dataSource[rowIndex]);
    let newSelectedRows: T[];

    if (isSelected) {
      newSelectedRows = selectedRows.filter(selectedRow => selectedRow !== dataSource[rowIndex]);
    } else {
      newSelectedRows = [...selectedRows, dataSource[rowIndex]];
    }

    setSelectedRows(newSelectedRows);
    if (onRowSelect) {
      onRowSelect(newSelectedRows);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedRows([...dataSource]);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, dataIndex: keyof T, rowIndex: number) => {
    const newData = [...dataSource];
    newData[rowIndex][dataIndex] = event.target.value as T[keyof T];
  };

  const onSettingsChange = useCallback((columnsDragged: TableColumn<T>[]) => {
    const columnsToUpdate = JSON.parse(localStorage.getItem(tableId) as string);
    if (columnsToUpdate?.length) {
      const updatedColumn = columnsDragged;
      if (updatedColumn) {
        setColumnsToShow(updatedColumn);
      }
    }
  }, [tableId]);

  const onSettingsClick = useCallback(() => {
    setIsSettingsVisible(prevState => !prevState);
  }, []);

  return (
    <TableWrapper>
      <TableSettingsButton onClick={onSettingsClick}>
        <SettingsHeaderIcon size={13}/>
      </TableSettingsButton>
      {isSettingsVisible && <TableSettingsContainer>
        <TableSettings columns={columns} tableId={tableId} onSettingsChange={onSettingsChange}/>
      </TableSettingsContainer>}
      <TableContainer>
        <TableHeader>
          {withCheckbox && <CheckboxCell>
            <CheckboxInput
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </CheckboxCell>}
          {columnsToShow.map((column: TableColumn<T>, index) => (
            <TableHeaderCell
              $showedColumnCount={columnsToShow?.length || showedColumnCount - 1}
              $width={column.width}
              key={column.dataIndex.toString()}
              $index={index}
              $fixed={column.fixed}>
              {column.title}
            </TableHeaderCell>
          ))}
          {action && <TableHeaderCell
            $showedColumnCount={10}
            $width={action.width}
            key={action.dataIndex.toString()}
            $index={100}
            $fixed={action.fixed}>
            {action.title}
          </TableHeaderCell>}
        </TableHeader>
        <TableBody>
          {dataSource.map((data, index) => (
            <TableRow key={data.key} className={data.key}>
              {withCheckbox && <CheckboxCell>
                <CheckboxInput
                  type="checkbox"
                  checked={selectedRows.includes(data)}
                  onChange={() => handleRowSelect(index)}
                />
              </CheckboxCell>}
              {columnsToShow.map((column: TableColumn<T>, index) => (
                <TableCell
                  $showedColumnCount={columnsToShow?.length || showedColumnCount - 1}
                  key={column.dataIndex.toString()}
                  $fixed={column.fixed}
                  $width={column.width}
                  $index={index}
                >
                  {column.editable ? (
                    <EditableCell
                      value={data[column.dataIndex].toString()}
                      onChange={(e) => handleInputChange(e, column.dataIndex, index)}
                    />
                  ) : (
                    column.render ? column.render(data[column.dataIndex]) : data[column.dataIndex]
                  )}
                </TableCell>
              ))}
              {action &&
                <TableCell
                  $showedColumnCount={10}
                  key={action.dataIndex.toString()}
                  $fixed={action.fixed}
                  $width={action.width}
                  $index={100}
                >
                  {action.render ? action.render(data[action.dataIndex]) : data[action.dataIndex]}
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </TableWrapper>
  );
};
