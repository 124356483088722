import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { blockInvalidChar } from 'utils/helpers';
import { ShowIcon, HideIcon } from 'scribexUI/icons';
import { DangerIcon, InfoIcon } from 'scribexUI/icons';
import { ValidationInfoType } from 'scribexUI/typings';
import { ChangeEvent, InputHTMLAttributes, JSX, useState } from 'react';

const sizes: Record<any, any> = {
  sm: {
    padding: '6px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    height: '34px'
  },
  lg: {
    padding: '10px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    height: '46px'

  },
  fill: {
    padding: '13px 0',
    fontSize: '14px',
    lineHeight: '20px',
    height: '46px'
  },
};
const width: Record<any, any> = {
  sm: {
    maxWidth: '343px',
  },
  lg: {
    maxWidth: '450px',
  },
  fill: {
    maxWidth: '100%',
  }
};

interface InputTYpe extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string,
  initialValue?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  type?: string,
  label?: string,
  disabled?: boolean,
  id?: string,
  $left_icon?: JSX.Element,
  $right_icon?: JSX.Element,
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
  renderIcon?: boolean;
  line?: boolean;
  $line?: boolean;
  inpSize?: 'sm' | 'lg' | 'fill',
  buttonText?: string
}

type StyledInputContainerType = {
  $inpSize: 'sm' | 'lg' | 'fill'
}
type StyledInputWrapperType = {
  $inpSize: 'sm' | 'lg' | 'fill';
  $isValid: boolean | undefined
  $line: boolean
  disabled: boolean
}
const inputStyles = css<InputTYpe>`
  border: none;
  font-family: DMSans-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  color: #140D0B;

  &:focus {
    border: none;
    color: #331DAD;
    outline: none;

    &::placeholder {
      color: #331DAD;
    }
  }

  &:focus-visible {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: #140D0B;
  }

  ${(props) =>
          props.disabled && css<InputTYpe>`
            background-color: #ffffff;
            cursor: not-allowed;
            color: rgba(20, 13, 11, .5);

            &::placeholder {
              color: rgba(20, 13, 11, .5);
            }
          `
  }
`;
const StyledInput = styled.input<InputTYpe>`
  ${inputStyles}
`;

const StyledInputContainer = styled.div<StyledInputContainerType>`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  ${(props) => width[props.$inpSize as string]};
  gap: 4px;
  position: relative;
`;

const StyledInputWrapper = styled.div<StyledInputWrapperType>`
  border-radius: 6px;
  background-color: #FFFFFF;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  height: 46px;
  box-sizing: border-box;
  width: 100%;

  ${(props) => sizes[props.$inpSize as string]};
  ${(props) =>
          props.$line ? css<InputTYpe>`
            border-bottom: 1px solid #140D0B;
            border-radius: 0;
          ` : css<InputTYpe>`
            border: 1px solid #140D0B;
          `
  };

  &:focus-within {
    ${(props) => {
      if (typeof props.$isValid !== "undefined") {
        return props.$isValid && css<InputTYpe>`border-color: #331DAD`;
      } else {
        return css<InputTYpe>`border-color: #331DAD`;
      }
    }
    }
  }
;

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css<InputTYpe>`
        border-color: #140D0B;
      ` : css<InputTYpe>`
        border-color: #F5222D;
      `
    }
  }
  };

  ${(props) =>
          props.disabled && css<InputTYpe>`
            border-color: rgba(20, 13, 11, .5);
            color: rgba(20, 13, 11, .5);
            cursor: not-allowed;
          `
  }
`;

const StyledInputLabel = styled.label<InputTYpe>`
  padding: 0;
  margin: 0;
  font-family: DMSans-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  ${(props) =>
          props.disabled && css<InputTYpe>`
            color: #68686B;
          `
  }
`;

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const StyledMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  width: 100%;
  position: absolute;
  top: calc(100% + 4px);
`;

const StyledMessage = styled.span<InputTYpe>`
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 12px;
  line-height: 16px;

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css<InputTYpe>`
        color: #68686B;
      ` : css<InputTYpe>`
        color: #F5222D;
      `
    }
  }
  }
`;

const StyledButtonWrapper = styled.span`
  color: #222222;
  font-family: DMSans-Medium, sans-serif;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
`

const StyledValidationIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;

export const SXInput = React.forwardRef<HTMLInputElement, InputTYpe>((props, ref) => {
  const {
    placeholder,
    onChange,
    label,
    $right_icon,
    $left_icon,
    disabled = false,
    validationInfo = {},
    isValid,
    type,
    renderIcon,
    line = false,
    inpSize = 'lg',
    buttonText,
    onClick,
    initialValue= '' ,
    ...additionalElements
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [inpVal, setInpVal] = useState(initialValue);
  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }
  const uniqueId = Math.random().toString(16).slice(2) + Date.now();
  const isValidationMessageVisible = !disabled && message;


  const onChangeHandler = useCallback((event:React.ChangeEvent<HTMLInputElement>) => {
    setInpVal(event.target.value);
    onChange && onChange(event);
  },[onChange]);

  return (
    <StyledInputContainer $inpSize={inpSize}>
      {label && <StyledInputLabel htmlFor={uniqueId} disabled={disabled}>
        {label}
      </StyledInputLabel>}
      <StyledInputWrapper
        disabled={disabled}
        $isValid={isValid}
        $line={line}
        $inpSize={inpSize}
      >
        {$left_icon && <StyledIconWrapper>{$left_icon}</StyledIconWrapper>}
        <StyledInput
          {...additionalElements}
          id={uniqueId}
          ref={ref}
          disabled={disabled}
          type={isVisible ? 'text' : type}
          placeholder={placeholder}
          value={inpVal}
          onChange={(event) => onChangeHandler(event)}
          onKeyDown={e => blockInvalidChar(e, type)}
        />
        {buttonText && <StyledButtonWrapper
          onClick={onClick}
        >{buttonText}</StyledButtonWrapper>}
        {($right_icon && type !== 'password' && !buttonText) &&
          <StyledIconWrapper
            style={{marginLeft: 'auto'}}>
            {$right_icon}
          </StyledIconWrapper>}
        {(type === 'password' && renderIcon) &&
          <StyledIconWrapper
            onClick={() => setIsVisible(prevState => !prevState)}
            style={{marginLeft: 'auto', cursor: 'pointer'}}>
            {isVisible ? <ShowIcon/> : <HideIcon/>}
          </StyledIconWrapper>}
      </StyledInputWrapper>
      {isValidationMessageVisible && <StyledMessageWrapper>
        <StyledValidationIcon>
          {!disabled && icon}
        </StyledValidationIcon>
        <StyledMessage $isValid={isValid}>
          {!disabled && message}
        </StyledMessage>
      </StyledMessageWrapper>}
    </StyledInputContainer>
  );
});