// import { api } from 'api';
import { useMemo } from 'react';
// import { useTypedUserData } from 'store/store';

export const useAuth = () => {
  // const {isLoading, isSuccess, isFetching} = api.useAuthenticateQuery('user_data');
  // const userData: any = useTypedUserData();

  const email = localStorage.getItem('email');

  const isAuthenticated = useMemo(() => !!email, [email]);
  return {
    isAuthenticated,
    // isLoading,
    // isSuccess,
    // userData,
    // isFetching
  }
}