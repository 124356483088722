import React from 'react';
import { App } from './App';
import { Theme } from './theme';
import 'assets/styles/index.scss';
import { store } from 'store/store';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const theme = {
  colors: {
    primary: '#5865F2',
    primaryHover: '#4555FF',
    text: '#222222', // Ensure text color is defined,
    disabledBackground: '#D5D5D5',
    primaryDisabled: '#A9A9AA'
  },
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <ConfigProvider theme={Theme}>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <App/>
        </DndProvider>
      </ThemeProvider>
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
