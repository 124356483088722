import { api } from 'api';
import { ADMIN_ROUTES } from 'constants/routes';
import { Form, FormProps, notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { CustomFieldsMapping } from 'constants/mappings';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomFieldValidation } from 'constants/validations';
import { FieldType } from 'componnets/common/modal/createFields/useCreateFields';
import { CUSTOM_FIELD_TYPE_MAPPING, CustomFieldsDefaultValues } from 'constants/app';
import { CustomFieldsType, ErrorMappingValueType, CUSTOM_FIELD_TYPES } from 'typings';

const fieldsValidationInitialState: Record<string, any> = {
  label: {
    isValid: true,
    message: 'Input Label is required',
  },
  placeholder: {
    isValid: true,
    message: 'Input Placeholder is required',
  },
  type: {
    isValid: true,
    message: 'Type is required',
  }
};
export const useCustomField = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fieldsValidationMapping, setFieldsValidationMapping] = useState(fieldsValidationInitialState);
  const [updateField] = api.useUpdateCustomFieldMutation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [inputType, setInputType] = useState<CustomFieldsType>(location.state.fieldData.typeKey);
  const [isOpen, setIsOpen] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  const typeOptions = useMemo(() => Object.entries(CUSTOM_FIELD_TYPES).map(([key, value]) => ({
    label: value,
    value: key
  })), []);
  const selectedOption = useMemo(() => {
    return {
      value: location.state.fieldData.typeKey,
      label: location.state.fieldData.type
    }
  }, [location.state.fieldData]);

  const inputTypeFields = useMemo(() => CUSTOM_FIELD_TYPE_MAPPING[inputType], [inputType]);
  const viewData = useMemo(() => {
    return inputTypeFields.reduce((acc: any, filed) => {
      if (filed.key === "fileType") {
        return [
          ...acc,
          {
            label: CustomFieldsMapping[filed.key],
            key: filed.key,
            options: filed.options,
            value: filed.options?.filter(_option => location.state.fieldData.fileType?.includes(_option.value as string))
          }
        ]
      } else if (location.state.fieldData[filed.key]) {
        return [
          ...acc,
          {
            label: CustomFieldsMapping[filed.key],
            value: location.state.fieldData[filed.key],
            key: filed.key
          }
        ]
      } else if (filed.key === 'range') {
        const updatedOption = filed.options?.map(_opt => {
          return {
            ..._opt,
            value: location.state.fieldData[_opt.key as string]
          }
        });
        return [
          ...acc,
          {
            ...filed,
            options: updatedOption
          },
        ];
      } else {
        return [
          ...acc,
          filed,
        ];
      }
    }, []);
  }, [location.state.fieldData, inputTypeFields]);

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onTypeChange = useCallback((type: CustomFieldsType) => {
    setInputType(type as CustomFieldsType);
  }, []);

  const onRemoveClick = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onFormFieldsChange = useCallback(() => {
    if (!isFieldsChanged) {
      setIsFieldsChanged(true);
    }
  }, [isFieldsChanged]);

  const onRemoveConfirm = useCallback(() => {
    const payload = {
      ...location.state.fieldData,
      type: location.state.fieldData?.typeKey,
      removed: true,
      action: null,
      key: null,
      usedCount: null,
      typeKey: null
    }
    updateField(payload)
      .then(({data, error}: any) => {
        setIsOpen(false);
        if (data?.status && data?.status === "ok") {
          openNotificationWithIcon({type: 'success', message: "The custom field was successfully removed."});
          navigate(ADMIN_ROUTES.customFields);
        } else {
          if (data) {
            Object.keys(data).forEach((_key: string) => {
              if (_key === 'status') {
                openNotificationWithIcon(CustomFieldValidation[data[_key]]);
              } else {
                _key && openNotificationWithIcon({type: 'error', message: data[_key]});
              }
            })
          } else if (error?.data) {
            error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
          }
        }
      })
      .catch(err => {
        console.log(err, 'error')
      })
  }, [location.state.fieldData, updateField, openNotificationWithIcon, navigate])

  const validateFields = useCallback((formFields: FieldType) => {
    const labelValidation = !!formFields.label;
    const placeholderValidation = Object.prototype.hasOwnProperty.call(formFields, "placeholder") ? !!formFields.placeholder : true;
    const typeValidation = !!formFields.type;
    setFieldsValidationMapping(prevState => ({
      ...prevState,
      label: {
        ...prevState.label,
        isValid: labelValidation
      },
      placeholder: {
        ...prevState.placeholder,
        isValid: placeholderValidation
      },
      type: {
        ...prevState.type,
        isValid: typeValidation
      }
    }));
    return labelValidation && typeValidation && placeholderValidation;
  }, []);

  const onFinish: FormProps<FieldType>['onFinish'] = useCallback((values: FieldType) => {
    const isValid = validateFields(values);
    const payload = {
      ...CustomFieldsDefaultValues,
      ...values,
      id: location.state.fieldData.id
    }
    if (!Array.isArray(payload.fileType)) {
      payload.fileType = [payload.fileType];
    }
    if (isValid) {
      updateField(payload)
        .then(({data, error}: any) => {
          if (data?.status && data?.status === "ok") {
            openNotificationWithIcon({type: 'success', message: "The custom field was successfully created."});
          } else {
            if (data) {
              Object.keys(data).forEach((_key: string) => {
                if (_key === 'status') {
                  openNotificationWithIcon(CustomFieldValidation[data[_key]]);
                } else {
                  _key && openNotificationWithIcon({type: 'error', message: data[_key]});
                }
              })
            } else if (error?.data) {
              error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
            }
          }
        })
        .catch(err => {
          console.log(err, 'error')
        })
    }
  }, [updateField, validateFields, openNotificationWithIcon, location.state.fieldData]);

  return {
    contextHolder,
    data: location.state.fieldData,
    typeOptions,
    selectedOption,
    inputTypeFields,
    viewData,
    onFinish,
    form,
    fieldsValidationMapping,
    inputType,
    onTypeChange,
    onRemoveClick,
    isOpen,
    onClose,
    onRemoveConfirm,
    onFormFieldsChange,
    isFieldsChanged
  }
}