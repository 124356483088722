import React from 'react';
import { ADMIN_ROUTES } from 'constants/routes';
import deleteIcon from 'assets/icons/delete.svg';
import { DeleteIcon, HomeIcon } from 'componnets';
import { Col, Divider, Form as AntdForm, Row, Space } from 'antd';
import {
  BreadcrumbItemType,
  SXBreadcrumb,
  SXButton,
  SXIconButton,
  SXInput,
  SXModal,
  SXSelect, SXTooltip,
  SXTypography
} from 'scribexUI';
import { useCustomField } from './useCustomField';
import styles from './styles.module.scss';

const {Title, Text} = SXTypography;

const items: BreadcrumbItemType[] = [
  {
    label: 'Job Management ',
    id: '1',
    path: ADMIN_ROUTES.dashboard,
    icon: <HomeIcon/>,
  },
  {
    label: 'Custom fields',
    path: ADMIN_ROUTES.customFields,
    id: '2',
  },
];

export const CustomField = React.memo(() => {
  const {
    typeOptions,
    selectedOption,
    viewData,
    onFinish,
    form,
    fieldsValidationMapping,
    contextHolder,
    inputType,
    onTypeChange,
    data,
    onRemoveClick,
    isOpen,
    onClose,
    onRemoveConfirm,
    onFormFieldsChange,
    isFieldsChanged
  } = useCustomField();
  return (
    <>
      {contextHolder}
      <Row>
        <Col span={24}>
          <AntdForm
            form={form}
            onFieldsChange={onFormFieldsChange}
            initialValues={{remember: false, type: selectedOption.value}}
            autoComplete={'off'}
            onFinish={onFinish}
          >
            <Row gutter={[0, 32]}>
              <Row gutter={[0, 10]} className={styles.top_banner}>
                <Col span={24} style={{padding: '12px 32px'}}>
                  <SXBreadcrumb items={items}/>
                </Col>
                <Col span={24} style={{padding: '12px 32px 0'}}>
                  <Row justify={'space-between'}>
                    <Col>
                      <Title level={4}>Position</Title>
                    </Col>
                    <Col>
                      <Space size={8}>
                        <SXTooltip
                          disabled={!data.usedCount}
                          title={'This custom field is currently in use.'}
                        >
                          <SXButton
                            type={'submit'} size={'xs'} sxType={'primary'}
                            disabled={!!data.usedCount || !isFieldsChanged}>Save</SXButton>
                        </SXTooltip>
                        <SXTooltip
                          disabled={!data.usedCount}
                          title={"This custom field is currently in use."}>
                          <SXIconButton
                            sxType={'tertiary'}
                            onClick={onRemoveClick}
                            disabled={!!data.usedCount}
                            size={'xs'}>
                            <DeleteIcon/>
                          </SXIconButton>
                        </SXTooltip>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Divider className={styles.divider}/>
              </Row>
              <Col span={24} style={{padding: '0 32px'}}>
                <Row className={styles.view_edit} gutter={[0, 16]}>
                  <Col flex={'450px'}>
                    <AntdForm.Item name={'type'}>
                      <SXSelect
                        disabled={!!data.usedCount}
                        size={'fill'}
                        inputLabel={'Type*'}
                        defaultOption={[selectedOption]}
                        options={typeOptions}
                        onSelect={onTypeChange}
                      />
                    </AntdForm.Item>
                  </Col>
                  {viewData.map((_field: any) => {
                    if (inputType === 'FILE' && _field.options?.length) {
                      const defOption = _field.value.length ? _field.value : [_field.options[0]];
                      return <Col flex={'450px'} key={_field.key}>
                        <AntdForm.Item name={_field.key}>
                          <SXSelect
                            disabled={!!data.usedCount}
                            size={'fill'}
                            inputLabel={_field.label}
                            defaultOption={defOption}
                            options={_field.options}
                          />
                        </AntdForm.Item>
                      </Col>
                    } else if (inputType === 'NUMBER' && _field.options?.length) {
                      return <Col flex={'450px'} key={_field.key}>
                        <Row gutter={8} align={'bottom'}>
                          {_field.options.map((_option: any, index: number) => <Col span={12} key={_option.label}>
                            <AntdForm.Item name={_option.key} initialValue={_option.value}>
                              <SXInput disabled={!!data.usedCount} placeholder={_option.label}
                                       label={!index ? _field.label : ''}/>
                            </AntdForm.Item>
                          </Col>)}
                        </Row>
                      </Col>
                    }
                    return <Col span={24} key={_field.key}>
                      <AntdForm.Item name={_field.key} initialValue={_field.value}>
                        <SXInput
                          disabled={!!data.usedCount}
                          label={_field.label}
                          initialValue={_field.value}
                          isValid={fieldsValidationMapping[_field.key]?.isValid}
                          validationInfo={{
                            error: fieldsValidationMapping[_field.key]?.message
                          }}
                        />
                      </AntdForm.Item>
                    </Col>
                  })}
                </Row>
              </Col>
            </Row>
          </AntdForm>
        </Col>
      </Row>
      <SXModal
        isOpen={isOpen}
        onClose={onClose}
        okText={'Delete'}
        title={'Delete Custom Field'}
        image={deleteIcon}
        onSave={onRemoveConfirm}>
        <Row justify={'center'}>
          <Text className={'center'}>Are you sure you want to delete {data.label} custom field?</Text>
        </Row>
      </SXModal>
    </>
  )
})