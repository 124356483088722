import { api } from 'api';
import { useAppDispatch } from 'store/hooks';
import { useTypedSelector } from 'store/store';
import React, { useCallback, useImperativeHandle } from 'react';
import { Form as AntdForm, FormProps, notification } from 'antd';
import { APPLICATION_METHOD, ErrorMappingValueType, JOB_TYPES } from 'typings';
import { saveJobFilterParams } from './jobFilterSlice';
import { CustomFieldsFilterRef } from './index';

export const useJobFilter = (ref: React.ForwardedRef<CustomFieldsFilterRef>, onClose: (event?: React.MouseEvent) => void) => {
  useImperativeHandle(ref, () => ({
    onApply: () => {
      form.submit();
    }
  }));
  const [form] = AntdForm.useForm();
  const dispatch = useAppDispatch();
  const {filteredParams, usage} = useTypedSelector(state => state.jobFilter);
  const [filter] = api.useJobFilterMutation({fixedCacheKey: 'jobFilterCache'});
  const [notificationApi, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onRemoveTagClick = useCallback(() => {
    form.setFieldValue('remove', [true]);
  }, [form]);

  const onRemoveTagRemove = useCallback(() => {
    form.setFieldValue('remove', [false]);
  }, [form]);

  const onPublishedTagClick = useCallback(() => {
    form.setFieldValue('published', [true]);
  }, [form]);

  const onPublishedTagRemove = useCallback(() => {
    form.setFieldValue('published', [false]);
  }, [form]);

  const onFinish: FormProps<Record<any, any>>['onFinish'] = useCallback((values: any) => {
    const jobType = Object.keys(JOB_TYPES).filter(key => values[key]);
    const applicationMethod = Object.keys(APPLICATION_METHOD).filter(key => values[key]);

    const payload = {
      jobType: jobType.length ? jobType : null,
      applicationMethod: applicationMethod.length ? applicationMethod : null,
      remove: (values.remove[0] === values.published[0]) ? null : values.remove || [false],
      published: (values.remove[0] === values.published[0]) ? null : values.published[0] ? values.published : null
    }
    dispatch(saveJobFilterParams({
      filteredParams: payload,
      usage: {
        archive: values.remove ? values.remove[0] : false,
        published: values.published ? values.published[0] : false
      }
    }));
    filter(payload)
      .then(({data, error}: any) => {
        data && onClose();
        error && openNotificationWithIcon({type: 'error', message: "Something went wrong!"});
      })
  }, [filter, onClose, openNotificationWithIcon, dispatch]);

  return {
    form,
    onFinish,
    onRemoveTagClick,
    onPublishedTagClick,
    onRemoveTagRemove,
    onPublishedTagRemove,
    contextHolder,
    filteredParams,
    usage
  }
}