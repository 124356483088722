export const JobTypeMapping: Record<string, string> = {
  "PART_TIME": 'Part time',
  "FULL_TIME": 'Full time'
}
export const JobSeniorityLevelMapping: Record<string, string> = {
  "NONE": '',
  "JUNIOR": 'Junior',
  "MID_LEVEL": 'Middle',
  "SENIOR": 'Senior'
}
export const CustomFieldsMapping: Record<string, string> = {
  "label": 'Input Label',
  "placeholder": 'Placeholder',
  "characterLimit": 'Character Limit',
  "fileType": 'File Type',
  "range": 'Range'

}