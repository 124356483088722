import React from 'react';
import { Image } from 'antd';
import { createPortal } from 'react-dom';
import { CloseIcon } from 'scribexUI/icons';
import styled, { css } from 'styled-components';
import { SXTextButton, SXButton, SXTypography } from 'scribexUI';

const {Title} = SXTypography;
type ModalSizeType = 'sm' | 'md' | 'lg' | 'fill';

interface ModalProps {
  isOpen: boolean;
  onClose: (event?:React.MouseEvent) => void;
  onSave?: () => void;
  children: React.ReactNode;
  size?: ModalSizeType;
  title?: string;
  image?: string;
  okText?: string;
  cancelText?: string;
  onlyOk?: boolean;
  withActions?: boolean;
  position?: string;
  roundness?:'rounded' | 'sharp',
  type?: 'standard' | undefined
}

interface ICloseButton {
  $type?: 'standard' | undefined
}

const sizes: Record<ModalSizeType, any> = {
  sm: {
    maxWidth: '360px',
    maxHeight: '368px',
    padding: '24px'
  },
  md: {
    maxWidth: '400px',
    maxHeight: '614px',
    padding: '32px'
  },
  lg: {
    maxWidth: '680px',
    maxHeight: '855px',
    padding: '24px 36px'
  },
  fill: {
    maxWidth: '680px',
    maxHeight: 'unset',
    padding: '24px 32px'
  }
};

const roundness: Record<string, any> = {
  rounded: {
    borderRadius: "8px"
  },
  sharp: {
    borderRadius: "none"
  },
};

const ModalOverlay = styled.div<Record<'$position', any>>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.$position ? props.$position : 'center'};
  z-index: 9999;
`;

const ModalContent = styled.div<Record<any, any>>`
  position: relative;
  background-color: #FBFBFB;
  box-shadow: 0 4px 8px 0 #00000014;
  box-sizing: border-box;
  z-index: 99999;
  width: 100%;
  margin: 0 0 50px;
  ${(props) => sizes[props.size as ModalSizeType]};
  ${(props) => roundness[props.$roundness]};
  ${() => css`
    @media (max-width: 577px) {
      padding: 24px 16px;
      margin: 0 16px 16px;
    }
  `};
`;

const CloseButton = styled.div<ICloseButton>`
  position: absolute;
  top: 8px;
  right: 8px;
  ${({$type}) => $type === 'standard' && css`
    top: 32px;
    right: 32px;
  `};
  
`;

const ActionButtons = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 8px;
`;

const CentralizedWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-top: 20px;
`;

const ChildrenWrapper = styled.div<Record<any, any>>`
  overflow: auto;
  ${(props) => props.size === "sm" && 'max-height: 120px'};
  ${(props) => props.size !== "sm" && 'max-height: 400px'};
  ${(props) => props.size === "fill" && 'max-height: unset;overflow: unset;'};
`;
const ImageWrapper = styled.div`
  padding: 12px;
`;

export const SXModal: React.FC<ModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    onSave,
    children,
    size = 'sm',
    title,
    image,
    okText = 'Save',
    cancelText = "Cancel",
    onlyOk = false,
    withActions = true,
    position,
    roundness = 'rounded',
    type,
  } = props;
  if (!isOpen) return null;

  return createPortal(
    <ModalOverlay onClick={onClose} $position={position}>
      <ModalContent $roundness={roundness} onClick={(e: any) => e.stopPropagation()} size={size}>
        <CloseButton $type={type} onClick={onClose}>
          <SXTextButton>
            <CloseIcon color={type === 'standard' ? '#A9A9AA' : '#222222'} type={type}/>
          </SXTextButton>
        </CloseButton>
        {(image || title) && <CentralizedWrapper>
          {image &&
            <ImageWrapper>
              <Image src={image} alt={"Image"} preview={false} width={34} height={34}/>
            </ImageWrapper>}
          {title && <Title level={3}>{title}</Title>}
        </CentralizedWrapper>}
        <ChildrenWrapper className={'scribex-modal-children-wrapper'} size={size}>
          {children}
        </ChildrenWrapper>
        {withActions && <ActionButtons>
          {!onlyOk && <SXButton block sxType={'tertiary'} size={'xs'} onClick={onClose}>{cancelText}</SXButton>}
          <SXButton block sxType={'primary'} size={'xs'} onClick={onSave}>{okText}</SXButton>
        </ActionButtons>}
      </ModalContent>
    </ModalOverlay>,
    document.body
  );
};
