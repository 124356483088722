import { api } from 'api';
import { useCallback, useState } from 'react';

const emailRegexp = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/);

export const useForgotPassword = (formRef: any) => {
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetPassword] = api.useAdminPasswordResetMutation();

  const onInputBlur = useCallback(({value, type}: Record<string, string>) => {
    if (type === 'email') {
      setEmailIsValid(emailRegexp.test(value));
    }
  }, []);

  const validateFields = useCallback((formFields:Record<string, string>) => {
    const emailValidation = emailRegexp.test(formFields.email);
    setEmailIsValid(emailValidation);
    return emailValidation;
  },[]);

  const onSubmit = useCallback(() => {
    const isValid  = validateFields(formRef.getFieldsValue());
    if (isValid) {
      resetPassword(formRef.getFieldsValue())
      setIsModalOpen(true);
    }
  },[ validateFields, formRef, resetPassword]);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  },[]);

  const onModalOk = useCallback(() => {
    setIsModalOpen(false);
  },[]);


  return{
    emailIsValid,
    onInputBlur,
    onSubmit,
    isModalOpen,
    onModalClose,
    onModalOk
  }
}