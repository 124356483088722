import { api } from 'api';
import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { ADMIN_ROUTES } from 'constants/routes';
import { To, useNavigate } from 'react-router-dom';
import { SignInErrorMapping } from 'constants/app';
import { SignupErrorMappingValueType } from 'typings';

const emailRegexp = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/);
export const useAdminSignIn = (formRef: any) => {
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [signIn] = api.useAdminSignInMutation();

  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const openNotificationWithIcon = useCallback((messageContent: SignupErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const validateFields = useCallback((formFields: Record<string, string>) => {
    const emailValidation = emailRegexp.test(formFields.email);
    const passwordValidation = !!formFields.password?.trim();
    setEmailIsValid(emailValidation);
    setPasswordIsValid(passwordValidation);
    return emailValidation && passwordValidation;
  }, []);

  const onInputBlur = useCallback(({value, type}: Record<string, string>) => {
    if (type === 'email') {
      setEmailIsValid(emailRegexp.test(value));
    } else if (type === 'password') {
      setPasswordIsValid(!!value.trim());
    }
  }, []);

  const onSubmit = useCallback(() => {
    const isValid = validateFields(formRef.getFieldsValue());
    if (isValid) {
      signIn(formRef.getFieldsValue())
        .then(({data, error}: any) => {
          if (data?.username) {
            localStorage.setItem('email', data.username)
            navigate(ADMIN_ROUTES.customFields as To);
          } else {
            if (error?.data) {
              if (error.data.status === "INVALID_CREDENTIALS") {
                openNotificationWithIcon(SignInErrorMapping[error.data.status]);
              }
            }
          }
        })
        .catch(err => {
          console.log(err, 'error')
        });
    }
    return;
  }, [formRef, validateFields, signIn, openNotificationWithIcon, navigate]);

  return {
    onInputBlur,
    emailIsValid,
    onSubmit,
    passwordIsValid,
    contextHolder
  }
}