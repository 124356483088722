import React, { useCallback, useEffect, useState } from 'react';
import { SXTypography } from 'scribexUI';
import styled, { css } from 'styled-components';
import { CircleDoneIcon, CloseIcon, ErrorIcon, PlusIcon, WarningIcon } from 'scribexUI/icons';

const {Text} = SXTypography;

type TagStatusType =
  'active'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'disable'
  | 'draft'
  | 'removed'
  | 'published';
type TagStateType = 'default' | 'add' | 'base';
type StyledTagType = {
  $roundness: 'circle' | 'square';
  $selectable: boolean;
  $isSelected: boolean;
  $bordered: boolean;
  $state: TagStateType;
  $status: TagStatusType | undefined
}

interface TagProps {
  roundness?: StyledTagType['$roundness'];
  status?: TagStatusType;
  state?: TagStateType;
  children: string;
  type?: 'remove' | 'add' | 'status';
  bordered?: boolean,
  withDot?: boolean,
  onRemoveClick?: () => void
  onClick?: () => void
  selectable?: boolean
  initialSelectValue?: boolean
  // TODO add custom icon
}

const TagStatus: Record<TagStatusType, any> = {
  active: {
    icon: <CircleDoneIcon color={'#5865F2'}/>,
    backgroundColor: '#EFF2FF',
    color: '#5865F2',
  },
  success: {
    icon: <CircleDoneIcon color={'#389E0D'}/>,
    backgroundColor: '#EFFFE0',
    color: '#389E0D',
  },
  error: {
    icon: <ErrorIcon/>,
    backgroundColor: '#FFF1F0',
    color: '#CF1322',
  },
  warning: {
    icon: <WarningIcon/>,
    backgroundColor: '#FFEDD8',
    color: '#F79009',
  },
  info: {
    icon: <CircleDoneIcon color={'#0B5CD5'}/>,
    backgroundColor: '#E6F0FF',
    color: '#0B5CD5',
  },
  disable: {
    icon: <CircleDoneIcon color={'#68686B'}/>,
    backgroundColor: '#F8F8F8',
    color: '#68686B',
  },
  draft: {
    backgroundColor: '#E6E6E6',
    color: '#909090',
  },
  removed: {
    backgroundColor: '#FFF1F0',
    color: '#F5222D',
  },
  published: {
    backgroundColor: '#EFFFE0',
    color: '#389E0D',
  }
}

const state: Record<TagStateType, any> = {
  base: {
    borderColor: '#5865F2',
    backgroundColor: '#EFF2FF'
  },
  default: {
    backgroundColor: '#F8F8F8',
    borderColor: '#E6E6E6',
  },
  add: {
    border: '1px dashed #A9A9AA',
    backgroundColor: '#ffffff'
  }
}

const TagContainer = styled.div<StyledTagType>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;

  & > * {
    box-sizing: unset;
  }

  border-radius: 360px;
  gap: 4px;
  ${(props) => props.$isSelected ? css`
    padding: unset;
  ` : css`
    padding: 3px 8px;
  `};

  ${(props) => props.$roundness === 'square' && css`
    border-radius: 2px;
  `};
  ${(props) => props.$selectable && css`
    cursor: pointer;
  `};

  ${(props) => !props.$status && state[props.$state]};

  ${(props) => props.$status && css`
    border: none;
    background-color: ${TagStatus[props.$status].backgroundColor};
    color: ${TagStatus[props.$status].color};
  `};

  ${(props) => props.$bordered && css`
    border: 1px solid ${props.$status ? TagStatus[props.$status].color : state[props.$state].borderColor};
  `};
`;

const IconWrapper = styled.span`
  width: 12px;
  height: 12px;
  display: flex;
  padding-right: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const TagContent = styled.div<{ $isSelected: boolean }>`
  display: flex;
  gap: 4px;
  ${(props) => props.$isSelected && 'padding: 3px 0 3px 8px;'}
  justify-content: center;
  align-items: center;
`;
const StyledDot = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const SXTag: React.FC<TagProps> = React.memo((props) => {
  const {
    children,
    type,
    bordered = false,
    state = 'default',
    status,
    roundness = 'circle',
    withDot = false,
    onRemoveClick,
    onClick,
    selectable = false,
    initialSelectValue
  } = props;
  const [isBordered, setIsBordered] = useState<boolean>(bordered);
  const [isSelected, setIsSelected] = useState<boolean>(type === 'remove');
  const textColor = status ? TagStatus[status].color : state === 'default' ? '#68686B' : '#5865F2';
  const onTagClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (selectable) {
      setIsBordered(true);
      setIsSelected(true);
      onClick && onClick();
    }
  }, [selectable, onClick]);

  const onRemove = useCallback(() => {
    if (isSelected) {
      setIsBordered(false);
      setIsSelected(prevState => !prevState);
    }
    if (onRemoveClick) {
      onRemoveClick();
    }
  }, [isSelected, onRemoveClick]);

  useEffect(() => {
    setIsBordered(initialSelectValue as boolean);
    setIsSelected(initialSelectValue as boolean);
  }, [initialSelectValue]);

  return (
    <TagContainer
      $selectable={selectable}
      $isSelected={isSelected}
      $state={state}
      $roundness={roundness}
      $bordered={isBordered}
      $status={status}
    >
      {!status && type === "add" && <IconWrapper>
        <PlusIcon/>
      </IconWrapper>}
      <TagContent onClick={(e) => onTagClick(e)} $isSelected={isSelected}>
        {status && !withDot && TagStatus[status].icon && <IconWrapper>
          {TagStatus[status].icon}
        </IconWrapper>}
        {withDot && <StyledDot color={textColor}/>}
        <Text weight={'medium'} color={textColor} size={'sm'}>{children}</Text>
      </TagContent>
      {((!status && type === "remove") || isSelected) && <IconWrapper onClick={onRemove}>
        <CloseIcon type={'standard'}/>
      </IconWrapper>}
    </TagContainer>
  );
});