import React from 'react';
import moment from 'moment';
import { ADMIN_ROUTES } from 'constants/routes';
import deleteIcon from 'assets/icons/delete.svg';
import { JobCreateFieldsNameType } from 'typings';
import { convertToTitleCase } from 'utils/helpers';
import { Col, Divider, Row, Form as AntdForm, Space } from 'antd';
import { APPLICATION_DETAILS, JOB_DETAILS } from 'constants/data_mapping';
import { AddIcon, ApplicationForm, EditCircle, ErrorIcon, GlobeIcon, HomeIcon } from 'componnets';
import { FormFieldType } from 'componnets/common/modal/applicationForm/useApplicationForm';
import {
  BreadcrumbItemType,
  SXBreadcrumb, SXButton, SXDataPicker,
  SXInput, SXModal,
  SXRadioButtonGroup,
  SXSelect, SXTag, SXTagInput,
  SXTextArea,
  SXTypography, SXUpload
} from 'scribexUI';
import styles from './styles.module.scss';
import { useJobAnnouncementCreate } from './useJobAnnouncementCreate';

const {Title, Text} = SXTypography;
const {Item} = AntdForm;

const items: BreadcrumbItemType[] = [
  {
    label: 'Job Management ',
    id: '1',
    path: ADMIN_ROUTES.dashboard,
    icon: <HomeIcon/>,
  },
  {
    label: 'Job Announcements',
    path: ADMIN_ROUTES.jobAnnouncements,
    id: '2',
  },
  {
    label: 'Create Job Announcements',
    path: ADMIN_ROUTES.createJobAnnouncements,
    id: '3',
  }
];
export const JobAnnouncementCreate = React.memo(() => {

  const {
    form,
    onFinish,
    contextHolder,
    handleKeyDown,
    onDataPickerChange,
    onRangeChange,
    fieldsValidationMapping,
    onDraftSave,
    editJobData,
    tagOptions,
    formApplication,
    onApplicationMethodChange,
    onCreateForm,
    isFormOpen,
    onFormClose,
    onFormSave,
    formFields,
    edit,
    onDelete,
    isRemoveOpen,
    onRemoveClose,
    onRemoveConfirm
  } = useJobAnnouncementCreate();
  return (
    <>
      {contextHolder}
      <Row className={styles.job_announcement_create}>
        <Row gutter={[0, 10]} className={styles.top_banner}>
          <Col span={24} style={{padding: '12px 32px'}}>
            <SXBreadcrumb items={items}/>
          </Col>
          <Col span={24} style={{padding: '10px 32px 0'}}>
            {editJobData ? <Space size={8} align={'center'}>
              <Title level={4}>{editJobData.title ? editJobData.title : 'No name'}</Title>
              <SXTag type={'status'} state={'base'}
                     status={editJobData.remove ? 'removed' : editJobData.published ? 'published' : 'draft'}
              >{editJobData.remove ? 'Archive' : editJobData.published ? 'Published' : 'Draft'}</SXTag>
            </Space> : <Title level={4}>Create Job Announcements</Title>}
          </Col>
          <Divider className={styles.divider}/>
        </Row>
        <Col span={24} style={{padding: '32px', backgroundColor: '#FBFBFB'}}>
          <AntdForm
            onFinish={onFinish}
            form={form}
            onKeyDown={handleKeyDown} // Attach the onKeyPress handler to the Form
            initialValues={{deadLine: editJobData?.deadLine ? moment(editJobData?.deadLine) : null}}
          >
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row>
                  <Col span={5} className={styles.form}>
                    <Text weight={'strong'}>Job Details</Text>
                  </Col>
                  <Col offset={1} span={10}>
                    <Row align={'middle'} justify={'center'} gutter={[0, 24]}>
                      {Object.keys(JOB_DETAILS).map((key) => {
                        const _key = key as JobCreateFieldsNameType;
                        switch (JOB_DETAILS[_key].type) {
                          case 'input':
                            return <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXInput
                                  initialValue={editJobData?.[_key]}
                                  label={convertToTitleCase(_key)}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }}/>
                              </Item>
                            </Col>
                          case 'text_area' :
                            return <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXTextArea
                                  initialValue={editJobData?.[_key]}
                                  label={convertToTitleCase(_key)}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }}
                                />
                              </Item>
                            </Col>
                          case 'select' :
                            return <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXSelect
                                  initialValue={editJobData?.[_key]}
                                  selectable={_key !== 'jobType'}
                                  size={'fill'}
                                  inputLabel={convertToTitleCase(_key)}
                                  options={JOB_DETAILS[_key].options}
                                  placeholder={`Select ${convertToTitleCase(_key)}`}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }}
                                />
                              </Item>
                            </Col>
                          case 'tag_input' :
                            return <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXTagInput
                                  initialValue={editJobData?.[_key]}
                                  options={tagOptions?.[_key]}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }} label={convertToTitleCase(_key)}/>
                              </Item>
                            </Col>
                          case 'range_input' :
                            return <Col span={24} key={_key}>
                              <Item name={_key}>
                                <Row align={'bottom'} gutter={8}>
                                  <Col span={12}>
                                    <SXInput
                                      initialValue={editJobData?.min}
                                      isValid={!fieldsValidationMapping[_key] ? !fieldsValidationMapping['min'] : !fieldsValidationMapping[_key]}
                                      validationInfo={{
                                        error: !fieldsValidationMapping[_key] ? fieldsValidationMapping['min'] : fieldsValidationMapping[_key]
                                      }}
                                      onChange={(event) => onRangeChange(event, 'min')} type={'number'}
                                      label={'Range'} placeholder={'Min'}/>
                                  </Col>
                                  <Col span={12}>
                                    <SXInput
                                      initialValue={editJobData?.max}
                                      isValid={!fieldsValidationMapping[_key] ? !fieldsValidationMapping['max'] : !fieldsValidationMapping[_key]}
                                      validationInfo={{
                                        error: fieldsValidationMapping['max']
                                      }}
                                      onChange={(event) => onRangeChange(event, 'max')} type={'number'}
                                      placeholder={'Max'}/>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                        }
                      })}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Divider className={styles.divider}/>
              <Col span={24}>
                <Row>
                  <Col span={5} className={styles.form}>
                    <Text weight={'strong'}>Application Details</Text>
                  </Col>
                  <Col offset={1} span={10}>
                    <Row align={'middle'} justify={'center'} gutter={[0, 24]}>
                      {Object.keys(APPLICATION_DETAILS).map((key: string) => {
                        const _key = key as JobCreateFieldsNameType;
                        switch (APPLICATION_DETAILS[_key].type) {
                          case 'calendar':
                            return <Col span={24} key={_key}>
                              <Item name={'deadLine'}>
                                <SXDataPicker
                                  format="YYYY/MM/DD"
                                  label={'Application Deadline'}
                                  placeholder={"Select Deadline"}
                                  onChange={(date) => onDataPickerChange(date)}/>
                              </Item>
                            </Col>
                          case 'input':
                            return formApplication && formApplication === 'EMAIL' ? <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXInput
                                  initialValue={editJobData?.[_key]}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }}
                                  label={convertToTitleCase(_key)}
                                  placeholder={APPLICATION_DETAILS[_key]?.placeholder || ''}/>
                              </Item>
                            </Col> : null
                          case 'text_area' :
                            return formApplication && formApplication === 'EMAIL' ? <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXTextArea
                                  initialValue={editJobData?.[_key]}
                                  label={convertToTitleCase(_key)}/>
                              </Item>
                            </Col> : null
                          case 'radio_option' :
                            return <Col span={24} key={_key}>
                              <Item name={_key} initialValue={editJobData?.[_key]}>
                                <SXRadioButtonGroup
                                  initialValue={editJobData?.[_key]}
                                  isValid={!fieldsValidationMapping[_key]}
                                  validationInfo={{
                                    error: fieldsValidationMapping[_key]
                                  }}
                                  label="Application Method"
                                  options={APPLICATION_DETAILS[_key].options}
                                  onChange={onApplicationMethodChange}
                                />
                              </Item>
                            </Col>
                        }
                      })}
                      {formApplication && formApplication === 'FORM' && !formFields?.length && <Row gutter={[0, 4]}>
                        <Col flex={142}>
                          <SXButton
                            onClick={() => onCreateForm(false)}
                            sxType={'primary'}
                            $right_icon={<AddIcon/>}>
                            Create Form
                          </SXButton>
                        </Col>
                        {fieldsValidationMapping['formApp'] && <Row className={styles.error} align={'middle'}>
                          <ErrorIcon/>
                          <Text size={'sm'} className={styles.error_text}>{fieldsValidationMapping['formApp']}</Text>
                        </Row>}
                      </Row>}
                    </Row>
                  </Col>
                </Row>
              </Col>
              {!!formFields?.length && <>
                <Divider className={styles.divider}/>
                <Col span={24}>
                  <Row>
                    <Col span={5} className={styles.form}>
                      <Text weight={'strong'}>Form Application</Text>
                    </Col>
                    <Col offset={1} span={10}>
                      <Row gutter={[0, 32]} className={styles.form_application}>
                        <Row className={'w-100'} justify={'space-between'} align={'middle'}>
                          <Text>Form Application</Text>
                          <Row style={{cursor: 'pointer'}} onClick={() => onCreateForm(true)}>
                            <EditCircle/>
                          </Row>
                        </Row>
                        {formFields.map((_field: FormFieldType) => {
                          if (_field.type === 'FILE') {
                            return <Col flex={24} key={_field.id}>
                              <SXUpload
                                label={_field.label}
                              />
                            </Col>
                          }
                          return <Col span={24} key={_field.id}>
                            <SXInput label={_field.label} placeholder={_field.placeholder}/>
                          </Col>
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </>}
              <Divider className={styles.divider}/>
              <Col span={24}>
                <Row justify={'space-between'} align={'middle'}>
                  <Col>
                    <Space size={6} align={'center'} className={styles.preview}>
                      <GlobeIcon/>
                      <Text color={'#6573FF'}>
                        See Preview
                      </Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space size={8} className={styles.button_container}>
                      <SXButton block onClick={onDelete} size={'sm'}>Delete</SXButton>
                      <SXButton block sxType={'tertiary'} onClick={onDraftSave} size={'sm'}>Save as Draft</SXButton>
                      <SXButton block sxType={'primary'} type={'submit'} size={'sm'}>Publish</SXButton>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </AntdForm>
        </Col>
      </Row>
      <SXModal
        type={'standard'}
        size={'fill'}
        isOpen={isFormOpen}
        onClose={onFormClose}
        okText={'Save'}
        title={`${edit ? 'Edit' : 'Create'} Application Form`}
        withActions={false}>
        <Row justify={'center'}>
          <ApplicationForm formFields={formFields} onSave={onFormSave} onCancel={onFormClose}/>
        </Row>
      </SXModal>
      <SXModal
        isOpen={isRemoveOpen}
        onClose={onRemoveClose}
        okText={'Delete'}
        title={'Delete Application'}
        image={deleteIcon}
        onSave={onRemoveConfirm}>
        <Row justify={'center'}>
          <Text className={'center'}>Are you sure you want to delete <Text
            underline>{editJobData?.title ? editJobData.title : ''}</Text> Job application?</Text>
        </Row>
      </SXModal>
    </>
  )
})