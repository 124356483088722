import React, { FC } from 'react';
import { SXButton } from 'scribexUI';
import { CheckEmailData } from 'mock';
import { Row, Col, Image, Typography } from 'componnets';
import styles from './styles.module.scss';

const {Title, Text} = Typography;

const {icon, title, descriptionOne, submitBtn} = CheckEmailData;

interface ICheckEmail {
  onOk: () => void
}

export const CheckEmail: FC<ICheckEmail> = React.memo((props) => {
  const {onOk} = props;
  return (
    <Row justify={'center'} className={styles.check_email} gutter={[0, 24]}>
      <Image src={icon} alt={'Icon'} preview={false}/>
      <Col span={24}>
        <Row justify={'center'} gutter={[0, 8]}>
          <Title className={styles.title}>{title}</Title>
          <Text className={styles.description}>{descriptionOne}</Text>
        </Row>

      </Col>
      <Col span={24}>
        <SXButton
          sxType={'primary'}
          roundness={'pill'}
          block
          onClick={onOk}
        >
          {submitBtn}
        </SXButton>
      </Col>
    </Row>
  )
})