import React from 'react';

export const viewportSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

const getRandomHeight = (i: number): number => {
  return Math.floor(Math.random() * 151) + 120 + 15 * i;
}

export const getColumnHeights = (numColumns: number): number[] => {
  const columns = JSON.parse(localStorage.getItem('columns') as string);
  if (columns?.length) {
    return columns
  }
  const heights: number[] = [];
  heights.push(160);
  for (let i = 1; i < numColumns; i++) {
    let newHeight = getRandomHeight(i);
    while (newHeight - heights[i - 1] > 35 || Math.abs(newHeight - heights[i - 1]) < 30 ||
    newHeight >= 450 || newHeight < 190 || Math.abs(450 - newHeight) < 30) {
      newHeight = getRandomHeight(i);
    }
    heights.push(newHeight);
  }
  heights[heights.length - 1] = 450;
  localStorage.setItem('columns', JSON.stringify(heights));
  return heights;
}

export const capitalizeWords = (text: string): string => {
  return text.replace(/\b\w/g, c => c.toUpperCase());
};

export const makeNiceName = (string: string) => string && (string[0].toUpperCase() + string.slice(1)).replace(/_/g, ' ');
export const convertToTitleCase = (string:string) => string && string.replace(/([A-Z])/g, ' $1').replace(/^./, (match) => match.toUpperCase());
export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>, type: string | undefined) => {
  if (type === 'number') {
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  }
}

export const getLatestSource = (listTimeStamp:number, filterTimeStamp:number, searchTimeStamp:number) => {
  const latestTimeStamp = Math.max(listTimeStamp || 0, filterTimeStamp || 0, searchTimeStamp || 0);
  let source: string | null = '';

  switch (latestTimeStamp) {
    case listTimeStamp:
      source = 'list';
      break;
    case filterTimeStamp:
      source = 'filter';
      break;
    case searchTimeStamp:
      source = 'search';
      break;
    default:
      source = null;
  }
  return source;
}