import React, { FC } from 'react';
import { SXTypography } from 'scribexUI';
import { InputHTMLAttributes } from "react";
import styled, { css } from 'styled-components';

const {Text} = SXTypography;

export interface InputElementProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  disabled?: boolean;
  initialValue?:string
  checked?:boolean
}

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledRadio = styled.input`
  appearance: none;
  outline: none;
  margin: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #D5D5D5;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:not(:checked):not(:disabled):hover {
    background-color: #F8F8F8;
  }

  &:checked {
    border-color: #5865F2;
  }

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 5px;
  }

  &:hover::after:not([disabled]) {
    background-color: #F8F8F8;
  }

  &:checked::after {
    background-color: #5865F2;
  }

  &:disabled {
    cursor: not-allowed;

    &:checked::after {
      background-color: #D5D5D5;
    }
  }
`;

const StyledLabel = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  ${({disabled}) => disabled && css`
    color: #F8F8F8;
    cursor: not-allowed;
  `}
`;

export const SXRadioButton:FC<InputElementProps> = React.memo((props) => {
  const {
    label,
    id,
    disabled = false,
    initialValue,
    ...rest
  } = props;

  return (
    <Wrapper>
      <StyledRadio id={id} type="radio" disabled={disabled} {...rest}/>
      <StyledLabel htmlFor={id} disabled={disabled}>
        <Text color={'#68686B'}>
          {label}
        </Text>
      </StyledLabel>
    </Wrapper>
  );
});