import React from 'react';
import { SXButton } from 'scribexUI';
import { Result, Row } from 'componnets';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

export const NotAuthorized = React.memo(() => {
  const navigate = useNavigate();
  return (
    <Result
      className={'center'}
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Row justify={'center'}>
          <SXButton sxType="primary" onClick={() => navigate(ROUTES.home)}>
            Back Home
          </SXButton>
        </Row>
      }
    />
  );
})