import React from 'react';
import {
  Row,
  Col,
  Form,
  Image,
  Typography
} from 'componnets';
import { Link } from 'react-router-dom';
import { AdminSignInFormData } from 'mock';
import { SXButton, SXInput } from 'scribexUI';
import { ADMIN_ROUTES } from 'constants/routes';
import image from 'assets/images/admin-sinin.png';
import styles from './styles.module.scss';
import { ScribexLogo } from 'componnets/common/icons';
import { useAdminSignIn } from './useSignin';

const {Text} = Typography;
const {Item: FormItem} = Form;
const {
  title,
  form,
  submitBtn,
  forgotPass
} = AdminSignInFormData;
export const AdminSignIn = React.memo(() => {
  const [formRef] = Form.useForm();
  const {emailIsValid, passwordIsValid, onInputBlur, onSubmit, contextHolder} = useAdminSignIn(formRef);

  return (
    <Col>
      {contextHolder}
      <Row className={styles.logo}>
        <ScribexLogo/>
      </Row>
      <Row wrap={true} className={styles.signup} align={'middle'}>
        <Col lg={12} md={24}>
          <Row justify={'center'} className={styles.left_side} align={'top'}>
            <Col sm={15} xs={24} className={styles.form}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Text className={styles.title}>{title}</Text>
                </Col>
                <Col span={24}>
                  <Form
                    form={formRef}
                    layout={'vertical'}
                    autoComplete={'off'}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <Row gutter={[0, 24]}>
                          {form.map((_formItem:any) => <FormItem
                            name={_formItem.name}
                            className={styles.form_item}
                            key={_formItem.id}
                          >
                            {
                              _formItem.isPassword ?
                                <SXInput
                                  label={_formItem.label}
                                  type={'password'}
                                  name={_formItem.name}
                                  renderIcon={true}
                                  onBlur={(e) => onInputBlur({value: e.target.value, type: _formItem.name})}
                                  isValid={passwordIsValid}
                                  validationInfo={_formItem.validationInfo}
                                /> :
                                <SXInput
                                  label={_formItem.label}
                                  type={'text'}
                                  name={_formItem.name}
                                  onBlur={(e) => onInputBlur({value: e.target.value, type: _formItem.name})}
                                  isValid={emailIsValid}
                                  validationInfo={_formItem.validationInfo}
                                />
                            }
                          </FormItem>)}
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 24]}>
                          <Link to={ADMIN_ROUTES.adminForgotPassword}>
                            <Text className={styles.forgot_pass}>{forgotPass}</Text>
                          </Link>
                          <Col span={24}>
                            <SXButton
                              size={'md'}
                              sxType={'primary'}
                              roundness={'pill'}
                              block
                              onClick={() => onSubmit()}
                            >
                              {submitBtn}
                            </SXButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={24}>
          <Row className={styles.right_side}>
            <Image src={image} alt={"image"} preview={false} width={"100%"}/>
            <Row className={styles.logo_in_img}>
              <ScribexLogo/>
            </Row>
          </Row>
        </Col>
      </Row>
    </Col>
  )
})