import React, { FC } from 'react';
import { Col, Row, Space } from 'antd';
import { FormFieldType, useApplicationForm } from './useApplicationForm';
import { SXButton, SXTypography } from 'scribexUI';
import styled from 'styled-components';
import { AddCircle, BlueInfoIcon, EmptyScreen } from 'componnets';
import styles from './styles.module.scss';
import { ApplicationFormDraggableField } from './ApplicationFormDraggableField';
import EmptyScreenIcon from '../../../../assets/icons/empty-country.png';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../../constants/routes';

const {Text, Title} = SXTypography;

const StyledCustomField = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #E6E6E6;
  max-height: 40px;
  box-sizing: border-box;
`
const FormDraggableFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledIndex = styled.span`
  width: 20px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledAddWrapper = styled.div`
  cursor: pointer;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;
export const ApplicationForm: FC<{
  onSave: (fields: any) => void,
  onCancel: () => void,
  formFields: FormFieldType[]
}> = React.memo((props) => {
  const {onSave, onCancel, formFields} = props;
  const {
    fieldsToDisplay,
    onFieldAdd,
    moveItem,
    onColumnRemoveClick,
    customFields,
    onCustomFieldsClick,
    onToggle
  } = useApplicationForm(formFields);
  const navigate = useNavigate()
  return (
    <Row className={`w-100 ${styles.main}`} gutter={[0, 24]}>
      {!!fieldsToDisplay?.length || !!customFields?.length ? <>
        <Col span={24}>
          <Row>
            <Col span={12} className={styles.side_container}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Row gutter={[0, 4]}>
                    <Col span={24}>
                      <Title level={5}>Fields</Title>
                    </Col>
                    <Text size={'sm'} color={'#68686B'}>Configure your form fields here.</Text>
                  </Row>
                </Col>
                <Col span={24} className={styles.overflow}>
                  {customFields.map((field: any) => <StyledCustomField
                      key={field.id}
                    >
                      <StyledAddWrapper onClick={() => onFieldAdd(field)}>
                        <AddCircle/>
                      </StyledAddWrapper>
                      <Text color={'#68686B'} size={'md'}>{field.label}</Text>
                    </StyledCustomField>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={12} className={styles.side_container}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Row gutter={[0, 4]}>
                    <Col span={24}>
                      <Title level={5}>Ordering and Display</Title>
                    </Col>
                    <Text size={'sm'} color={'#68686B'}>Drag and drop to rearrange fields.</Text>
                  </Row>
                </Col>
                <Col span={24} className={styles.overflow}>
                  {fieldsToDisplay.map((field, index) => {
                    const fieldIndex = index + 1;
                    return <FormDraggableFieldContainer key={field.id}>
                      <StyledIndex>
                        <Text size={'md'}>{fieldIndex}</Text>
                      </StyledIndex>
                      <ApplicationFormDraggableField key={field.id}
                                                     id={field.id}
                                                     text={field.label}
                                                     index={index}
                                                     moveItem={moveItem}
                                                     onRemoveClick={onColumnRemoveClick}
                                                     required={field.required}
                                                     onToggle={(value) => onToggle(value, field.id)}
                      />
                    </FormDraggableFieldContainer>
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Space size={6} align={'center'}>
            <BlueInfoIcon/>
            <Text size={'md'}>To add new fields, go to the <Text className={styles.link} onClick={onCustomFieldsClick}
                                                                 size={'md'} underline>Custom Fields</Text> page.</Text>
          </Space>
        </Col>
        <Col span={24}>
          <ActionButtons>
            <SXButton onClick={onCancel} block sxType={'tertiary'}>Cancel</SXButton>
            <SXButton onClick={() => onSave(fieldsToDisplay)} block sxType={'primary'}>Save</SXButton>
          </ActionButtons>
        </Col>
      </> : <EmptyScreen
        icon={EmptyScreenIcon}
        title={`No Fields Added`}
        description={'To create a form, please add fields.'}>
        <SXButton className={styles.empty_screen_btn} sxType={'primary'} onClick={() => navigate(ADMIN_ROUTES.customFields)}>Add Fields</SXButton>
      </EmptyScreen>
      }
    </Row>
  )
})