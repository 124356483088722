import { api } from 'api';
import { Form, FormProps, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorMappingValueType, JobCreateFieldsType } from 'typings';
import { JobValidation, ValidationErrors } from 'constants/validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormFieldType } from 'componnets/common/modal/applicationForm/useApplicationForm';
import { ADMIN_ROUTES } from 'constants/routes';

type FieldType = {
  applicationMethod: string
  currency: string[]
  deadLine: any
  department: string[]
  description: string
  emailApp: string
  instruction: string
  jobType: string
  location: string[]
  salaryRange: string | null
  seniorityLevel: string[]
  title: string
  published: boolean
  formCustomFields: Record<string, boolean>
  id?: string
  remove?: boolean
};

const fieldsValidationInitialState: JobCreateFieldsType = {
  title: '',
  description: '',
  location: '',
  salaryRange: '',
  applicationMethod: '',
  department: '',
  jobType: '',
  min: '',
  max: '',
  emailApp: ''
};

const emailRegexp = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}$/);

export const useJobAnnouncementCreate = () => {

  const [form] = Form.useForm();
  const [createJob] = api.useCreateJobMutation();
  const [updateJob] = api.useUpdateJobMutation();
  const {data: tagData} = api.useTagListQuery();
  const {data: customFieldsData = {}} = api.useCustomFieldListQuery({page:1, size:10});
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [formApplication, setFormApplication] = useState<string | null>(null);
  const [formFields, setFormFields] = useState<FormFieldType[]>([]);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [edit, setIsEdit] = useState<boolean | undefined>(false);
  const [rangeMin, setRangeMin] = useState<string>('');
  const [rangeMax, setRangeMax] = useState<string>('');
  const [fieldsValidationMapping, setFieldsValidationMapping] = useState<JobCreateFieldsType>(fieldsValidationInitialState);
  const location = useLocation();

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const editJobData = useMemo(() => {
    if (location.state?.jobData) {
      return {
        ...location.state.jobData,
        min: location.state.jobData.salaryRange.split('-')[0],
        max: location.state.jobData.salaryRange.split('-')[1],
      }
    } else {
      return null
    }
  }, [location]);

  useEffect(() => {
    if (location.state?.jobData) {
      setRangeMin(location.state.jobData.salaryRange.split('-')[0]);
      setRangeMax(location.state.jobData.salaryRange.split('-')[1]);
      if (location.state.jobData.applicationMethod === 'FORM') {
        const formFields = customFieldsData?.content?.reduce((acc: any, _customField: FormFieldType) => {
          if (_customField.id in location.state.jobData.formCustomFields) {
            return [
              ...acc,
              {
                ..._customField,
                required: location.state.jobData.formCustomFields[_customField.id]
              }
            ]
          } else {
            return acc
          }
        }, []);
        setFormFields(formFields);
        setFormApplication('FORM')
      } else if (location.state.jobData.applicationMethod === 'EMAIL') {
        setFormApplication('EMAIL')
      }
    }
  }, [location.state?.jobData, customFieldsData?.content]);

  const tagOptions: Record<string, string[]> = useMemo(() => {
    return {
      seniorityLevel: tagData?.seniorityLevel.flat(),
      department: tagData?.department.flat()
    }
  }, [tagData]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const onRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (key === 'min') {
      setRangeMin(e.target.value);
    } else {
      setRangeMax(e.target.value);
    }
  }, []);

  const onDataPickerChange = useCallback((date: any) => {
    form.setFieldValue('deadLine', date);
  }, [form]);
  const onApplicationMethodChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFormApplication(event.target.value);
  }, []);
  const onCreateForm = useCallback((value?: boolean) => {
    setIsFormOpen(true);
    setIsEdit(value);
  }, [])
  const onFormClose = useCallback(() => {
    setIsFormOpen(false);
  }, []);
  const onFormSave = useCallback((formFields: any) => {
    setIsFormOpen(false);
    setFormFields(formFields);
  }, []);
  const onRemoveClose = useCallback(() => {
    setIsRemoveOpen(false)
  }, []);
  const onDelete = useCallback(() => {
    if (editJobData) {
      setIsRemoveOpen(true)
    } else {
      navigate(ADMIN_ROUTES.jobAnnouncements);
    }
  }, [editJobData, navigate]);


  const validateFields = useCallback((formFields: FieldType) => {
    const validationFields: JobCreateFieldsType = fieldsValidationInitialState;
    validationFields.title = !formFields.title ? ValidationErrors.required("Title") : '';
    validationFields.description = !formFields.description ? ValidationErrors.required("Description") : '';
    validationFields.location = !formFields.location ? ValidationErrors.required("Location") : '';
    validationFields.jobType = !formFields.jobType ? ValidationErrors.required("Job type") : '';
    validationFields.salaryRange = formFields.salaryRange === '-' ? ValidationErrors.required("Salary range") : '';
    validationFields.min = formFields.salaryRange !== '-' && !rangeMin ? ValidationErrors.required("Min range") : '';
    validationFields.max = formFields.salaryRange !== '-' && !rangeMax ? ValidationErrors.required("Max range") : '';
    validationFields.applicationMethod = !formFields.applicationMethod ? ValidationErrors.required("Application method") : '';
    validationFields.department = !formFields.department ? ValidationErrors.required("Department") : '';
    validationFields.emailApp = formFields.applicationMethod === 'EMAIL' && !formFields.emailApp ? 'An email is required, when application method is EMAIL.' : '';
    validationFields.emailApp = formFields.applicationMethod === 'EMAIL' && !emailRegexp.test(formFields.emailApp) ? 'Invalid email address.' : '';
    validationFields.formApp = formFields.applicationMethod === 'FORM' && !Object.keys(formFields.formCustomFields).length ? 'An form is required, when application method is FORM.' : ''

    setFieldsValidationMapping(prevState => ({
      ...prevState,
      ...validationFields,
    }));
    return !validationFields.title &&
      !validationFields.description &&
      !validationFields.location &&
      !validationFields.jobType &&
      !validationFields.salaryRange &&
      !validationFields.min &&
      !validationFields.max &&
      !validationFields.applicationMethod &&
      !validationFields.department &&
      !validationFields.emailApp;
  }, [rangeMin, rangeMax]);

  const handleJobCRUD: (values: FieldType, isPublished: boolean, isCreate?: boolean) => void = useCallback((values, isPublished, isCreate) => {
    const payload = values;
    payload.published = isPublished;
    payload.deadLine = values.deadLine?.toISOString();
    payload.salaryRange = [rangeMin, rangeMax].join('-');

    if (payload.applicationMethod === 'FORM') {
      const formCustomFields: Record<string, boolean> = {}
      formFields.forEach(_field => {
        formCustomFields[_field.id] = _field.required || false
      })
      payload.formCustomFields = formCustomFields;
    }
    const isValid = isPublished ? validateFields(payload) : true;
    if (isValid) {
      (isCreate ? createJob(payload) : updateJob(payload))
        .then(({data, error}: any) => {
          if (data?.status && data?.status === "ok") {
            openNotificationWithIcon({type: 'success', message: "The Job was successfully created."});
            navigate(ADMIN_ROUTES.jobAnnouncements)
          } else {
            if (data) {
              Object.keys(data).forEach((_key: string) => {
                if (_key === 'status') {
                  openNotificationWithIcon(JobValidation[data[_key]]);
                } else {
                  _key && openNotificationWithIcon({type: 'error', message: data[_key]});
                }
              })
            } else if (error?.data) {
              openNotificationWithIcon({type: 'error', message: "Something went wrong!"});
            }
          }
        })
        .catch(err => {
          console.log(err, 'error')
        })
    }
  }, [createJob, updateJob, validateFields, openNotificationWithIcon, rangeMin, rangeMax, formFields, navigate]);

  const onFinish: FormProps<FieldType>['onFinish'] = useCallback((values: FieldType) => {
    const fieldValues = values;
    if (editJobData) {
      fieldValues.id = editJobData.id;
    }
    handleJobCRUD(fieldValues, true, !editJobData);
  }, [handleJobCRUD, editJobData]);

  const onDraftSave = useCallback(() => {
    handleJobCRUD(form.getFieldsValue(), false);
  }, [handleJobCRUD, form]);

  const onRemoveConfirm = useCallback(() => {
    const fieldValues = form.getFieldsValue();
    if (editJobData) {
      fieldValues.id = editJobData.id;
    }
    fieldValues.remove = true;
    fieldValues.published = false
    handleJobCRUD(fieldValues, false);
  }, [handleJobCRUD, form, editJobData]);


  return {
    contextHolder,
    form,
    onFinish,
    handleKeyDown,
    onDataPickerChange,
    onRangeChange,
    fieldsValidationMapping,
    onDraftSave,
    editJobData,
    tagOptions,
    formApplication,
    onApplicationMethodChange,
    onCreateForm,
    isFormOpen,
    onFormClose,
    onFormSave,
    formFields,
    edit,
    onDelete,
    isRemoveOpen,
    onRemoveClose,
    onRemoveConfirm
  }
}