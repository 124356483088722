import React from 'react';
import { ADMIN_ROUTES } from 'constants/routes';
import deleteIcon from 'assets/icons/delete.svg';
import EmptyScreenIcon from 'assets/icons/empty-country.png';
import { BreadcrumbItemType, SXBreadcrumb, SXButton, SXTypography } from 'scribexUI';
import { SXInput, SXModal, SXPagination, SXTable, SXTag, SXTooltip } from 'scribexUI';
import {
  Col,
  CustomFieldsFilter,
  Divider,
  EmptyScreen,
  NoResultScreen,
  Row,
  Space,
  Spin,
  AddIcon,
  CreateFields,
  DeleteIcon,
  FilterIcon,
  HomeIcon,
  OutlineArrowDownIcon,
  SearchIcon,
  ShowIcon
} from 'componnets';
import { DataType, useCustomFields } from './useCustomFields';
import styles from './styles.module.scss';

const {Title, Text} = SXTypography;

const items: BreadcrumbItemType[] = [
  {
    label: 'Job Management ',
    id: '1',
    path: ADMIN_ROUTES.jobAnnouncements,
    icon: <HomeIcon/>,
  },
  {
    label: 'Custom fields',
    path: ADMIN_ROUTES.customFields,
    id: '2',
  },
];
interface TableColumn<T> {
  title: string;
  dataIndex: keyof T;
  render?: (value: T[keyof T]) => React.ReactNode;
  editable?: boolean;
  fixed?: 'left' | 'right'
  width?: number | string
}
export const CustomFields = React.memo(() => {
  const {
    onRemoveClick,
    isOpen,
    onClose,
    fieldName,
    isCreateFieldOpen,
    onCreateFieldClose,
    onCreateField,
    routeChange,
    fieldsList,
    onRemoveConfirm,
    contextHolder,
    onSearch,
    searchIsLoading,
    isSearched,
    isFilterOpen,
    onFilterClose,
    onFilterReset,
    onFilterOpen,
    filterRef,
    onFilterApply,
    isFiltered,
    totalItems,
    onPageChange
  } = useCustomFields();
  const columns: TableColumn<DataType>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: '200px'
    },
    {
      title: 'Input label',
      dataIndex: 'label',
      width: '200px'
    },
    {
      title: 'Placeholder',
      dataIndex: 'placeholder',
      width: '200px',
      render: (value: string) => <strong>{value}</strong>,
    },
    {
      title: 'Character limit',
      dataIndex: 'characterLimit',
      width: '200px',
      render: (value: string) => {
        const color = value === 'Active' ? 'green' : value === 'Inactive' ? 'red' : 'orange';
        return <span style={{color}}>{!value ? 'N/A' : value}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'usedCount',
      width: '200px',
      render: (value: string) => {
        return <SXTag type={'status'} state={'base'} status={value ? 'success' : undefined}
                      withDot>{value ? 'In Use' : 'Published'}</SXTag>;
      },
    },
    {
      title: '',
      dataIndex: 'key',
      width: '72px',
      fixed: 'right',
      render: (value: string) => {
        const field: any = fieldsList.find(_field => _field.id === value);
        return (
          <Space size={8} align={'center'}>
            <SXTooltip
              disabled={!field?.usedCount}
              title={'This custom field is currently in use.'}>
              <button
                disabled={!!field?.usedCount}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0',
                  cursor: `${!field?.usedCount ? 'pointer' : 'not-allowed'}`
                }}
                onClick={() => onRemoveClick(value)}><DeleteIcon/></button>
            </SXTooltip>
            <button style={{backgroundColor: 'transparent', border: 'none', padding: '0', cursor: 'pointer'}}
                    onClick={() => routeChange(value)}
            >
              <ShowIcon/>
            </button>
          </Space>
        )
      },
    }
  ];
  return (
    <>
      {contextHolder}
      <Row gutter={[0, 10]} align={'top'} className={styles.custom_fields}>
        <Row gutter={[0, 10]} className={styles.top_banner}>
          <Col span={24} style={{padding: '12px 32px'}}>
            <SXBreadcrumb items={items}/>
          </Col>
          <Col span={24} style={{padding: '10px 32px 0'}}>
            <Row justify={'space-between'} align={'middle'}>
              <Title level={4}>Custom Fields</Title>
              <Col>
                <Space>
                  <Col>
                      <SXButton
                        size={'xs'}
                        sxType={'secondary'}
                        $left_icon={<FilterIcon/>}
                        $right_icon={<OutlineArrowDownIcon/>}
                        onClick={onFilterOpen}
                      >Filter</SXButton>
                  </Col>
                  <Col>
                    <SXInput
                      inpSize={'sm'}
                      placeholder={'Search'}
                      $right_icon={<SearchIcon/>}
                      onChange={(e) => onSearch(e)}
                    />
                  </Col>
                  <Col>
                    <SXButton
                      sxType={'primary'}
                      size={'xs'}
                      $right_icon={<AddIcon/>}
                      onClick={onCreateField}
                    >Create New</SXButton>
                  </Col>
                </Space>
              </Col>
            </Row>
          </Col>
          <Divider className={styles.divider}/>
        </Row>
        <Col span={24} style={{padding: '32px'}}>
          {fieldsList.length ? <Spin spinning={searchIsLoading}>
            <SXTable columns={columns} dataSource={fieldsList} tableId={'uniqueTableIdCustomFields'}/>
          </Spin> : isSearched ? <NoResultScreen/> :
            <EmptyScreen
              icon={EmptyScreenIcon}
              description={`There are no Custom Fields yet!`}/>}
          <SXPagination
            total={totalItems}
            onPageChange={onPageChange}
          />
        </Col>
      </Row>
      <SXModal
        isOpen={isOpen}
        onClose={onClose}
        okText={'Delete'}
        title={'Delete Custom Field'}
        image={deleteIcon}
        onSave={onRemoveConfirm}>
        <Row justify={'center'}>
          <Text className={'center'}>Are you sure you want to delete {fieldName} custom field?</Text>
        </Row>
      </SXModal>
      <SXModal
        type={'standard'}
        size={'md'}
        isOpen={isFilterOpen}
        onClose={onFilterReset}
        cancelText={isFiltered ? 'Cancel' : 'Close'}
        title={'Filter'}
        onSave={onFilterApply}
      >
        <CustomFieldsFilter ref={filterRef} onClose={onFilterClose}/>
      </SXModal>
      <SXModal
        type={'standard'}
        size={'fill'}
        isOpen={isCreateFieldOpen}
        onClose={onCreateFieldClose}
        title={'Create Fields'}
        withActions={false}
      >
        <CreateFields onCancel={onCreateFieldClose}/>
      </SXModal>
    </>
  )
})