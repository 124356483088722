import React from 'react';
import { Row } from 'componnets';
import { SearchIcon } from '../icons';
import { SXTypography } from 'scribexUI';
import { Col } from 'antd';

const {Text} = SXTypography;
export const NoResultScreen = React.memo(() => {
  return (
    <Row justify={'center'} align={'stretch'} gutter={[0, 6]} style={{padding: '10px 0'}}>
      <SearchIcon/>
      <Col span={24}>
        <Row justify={'center'}>
          <Text size={'md'} color={'#222222'} weight={'medium'} center>No Result</Text>
        </Row>
      </Col>
      <Text size={'md'} color={'#68686B'} center>Nothing matched your search terms. Please try again with different
        keywords.</Text>
    </Row>

  )
})