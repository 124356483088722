import React from 'react';
import { Col, Row } from 'componnets';
import { Form as AntdForm } from 'antd';
import { SXButton, SXInput, SXTextArea, SXUpload, SXTypography } from 'scribexUI';
import { usePositionApplyForm } from './usePositionApplyForm';
import styles from './styles.module.scss';

const {Text} = SXTypography;
export const PositionApplyForm = React.memo(() => {
  const {
    form,
    file,
    contextHolder,
    isLoading,
    emailIsValid,
    onInputBlur,
    onFinish,
    dynamicValidationMessage,
    handleFileChange,
    coverLatterCount
  } = usePositionApplyForm();
  return (
    <>
      {contextHolder}
      <AntdForm
        form={form}
        onFinish={onFinish}
        initialValues={{remember: false}}
        autoComplete={'off'}
      >
        <Row gutter={[0, {xs: 24, sm: 32}]} className={styles.form}>
          <Col span={24}>
            <Row gutter={[0, 4]} className={styles.checkbox_container}>
              <Text className={styles.form_title}>INTERESTED?</Text>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row className={styles.form_fields} wrap={true}>
                  <Col flex={1}>
                    <AntdForm.Item name={'NAME'}>
                      <SXInput
                        inpSize={'fill'}
                        line
                        placeholder={'Name'}
                        isValid={!dynamicValidationMessage['NAME']}
                        validationInfo={dynamicValidationMessage['NAME']}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col flex={1}>
                    <AntdForm.Item name={'EMAIL'}>
                      <SXInput
                        inpSize={'fill'}
                        line
                        placeholder={'Email'}
                        onBlur={(e) => onInputBlur({value: e.target.value, type: 'EMAIL'})}
                        isValid={!dynamicValidationMessage['EMAIL'] && emailIsValid}
                        validationInfo={dynamicValidationMessage['EMAIL'] || {
                          error: 'Wrong email format'
                        }}/>
                    </AntdForm.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <AntdForm.Item name={'COVER_LATTER'}>
                      <SXTextArea
                        inpSize={'fill'}
                        line placeholder={'Cover Letter'}
                        onBlur={(e) => onInputBlur({value: e.target.value, type: 'COVER_LATTER'})}
                        isValid={!dynamicValidationMessage['COVER_LATTER']}
                        validationInfo={dynamicValidationMessage['COVER_LATTER']}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={24} className={styles.letter_count}>
                    <Row justify={'end'}>
                      <Text color={'#68686B'} size={'md'}>{coverLatterCount}/2000</Text>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <AntdForm.Item
                      name={'FILE'}
                    >
                      <SXUpload
                        label={'Attach your CV'}
                        file={file}
                        acceptTypes={".jpg, .jpeg, .png, .docx, .pdf"}
                        isValid={!dynamicValidationMessage['FILE']}
                        validationInfo={dynamicValidationMessage['FILE']}
                        onFileChange={handleFileChange}/>
                    </AntdForm.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col flex={'222px'}>
            <SXButton
              type={'submit'}
              sxType={'primary'}
              roundness={'sharp'}
              size={'sm'}
              isLoading={isLoading}
              bgColor={'#331DAD'}>
              Submit
            </SXButton>
          </Col>
        </Row>
      </AntdForm>
    </>
  )
})