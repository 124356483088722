import React from 'react';
import { Row, Image } from 'componnets';
import { SXButton, SXInput } from 'scribexUI';
import { AdminForgotPasswordData } from 'mock';
import { useForgotPassword } from './useForgotPassword';
import styles from '../signIn/styles.module.scss';
import image from 'assets/images/admin-forgot-password.png';
import { CheckEmail, Col, Form, Typography } from 'componnets';
import {SXModal} from 'scribexUI'

const {Text} = Typography;
const {Item: FormItem} = Form;
const {
  title,
  description,
  submitBtn,
  form:{email}
} = AdminForgotPasswordData;

export const AdminForgotPassword = React.memo(() => {

  const [formRef] = Form.useForm();
  const {onInputBlur, emailIsValid, onSubmit, onModalClose, isModalOpen, onModalOk} = useForgotPassword(formRef);
  return (
    <Col>
      <Row wrap={true} className={styles.signup} align={'middle'}>
        <Col lg={12} md={24}>
          <Row justify={'center'} className={styles.left_side}>
            <Col sm={15} xs={24} className={styles.form}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Text className={styles.title}>{title}</Text>
                </Col>
                <Col span={24}>
                  <Text className={styles.description}>{description}</Text>
                </Col>
                <Col span={24}>
                  <Form
                    layout={'vertical'}
                    autoComplete={'off'}
                    form={formRef}
                  >
                    <Row gutter={[0, 24]}>
                      <FormItem
                        name={email.name}
                        className={styles.form_item}
                        key={email.id}
                      >
                        <SXInput
                          type={'text'}
                          label={email.label}
                          name={email.name}
                          onBlur={(e) => onInputBlur({value: e.target.value, type: email.name})}
                          isValid={emailIsValid}
                          validationInfo={email.validationInfo}
                        />
                      </FormItem>
                      <Col span={24}>
                        <SXButton
                          roundness={'pill'}
                          sxType={'primary'}
                          block
                          onClick={() => onSubmit()}
                        >
                          {submitBtn}
                        </SXButton>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={24}>
          <Row className={styles.right_side}>
            <Image src={image} alt={"image"} preview={false}  width={"100%"}/>
          </Row>
        </Col>
      </Row>
      <SXModal
        size={'sm'}
        isOpen={isModalOpen}
        onClose={onModalClose}
        onSave={() => {
          console.log('modal')}}
      >
         <CheckEmail onOk={onModalOk}/>
      </SXModal>
    </Col>
  )
})