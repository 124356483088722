import { FC, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { CloseIcon, DragIcon } from 'scribexUI/icons';
import { SXTypography } from 'scribexUI';

const {Text} = SXTypography;

interface ItemProps {
  id: string;
  text: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemoveClick: (id:string) => void;
}

const StyledDraggableColumn = styled.div<{$isDragging:boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  ${(props) => `opacity: ${props.$isDragging ? 0.5 : 1}`};

  &:hover {
    background-color: #F8F8F8;
  }
`;

const StyledCloseContainer = styled.div`
  margin-left: auto;
`;

export const SettingsDraggableColumn: FC<ItemProps> = ({id, text, index, moveItem, onRemoveClick}) => {
  const [isCloseVisible, setIsCloseVisible] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover: (item: { id: string; index: number }) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: 'COLUMN',
    item: {id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <StyledDraggableColumn
      ref={ref}
      $isDragging={isDragging}
      onMouseEnter={() => setIsCloseVisible(true)}
      onMouseLeave={() => setIsCloseVisible(false)}
    >
      <DragIcon/>
      <Text size={'md'} color={'#68686B'}>
        {text}
      </Text>
      {isCloseVisible && <StyledCloseContainer onClick={() => onRemoveClick(id)}>
        <CloseIcon type={'standard'}/>
      </StyledCloseContainer>}
    </StyledDraggableColumn>
  )
}