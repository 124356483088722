import { Space } from 'antd';
import { FC, useRef } from 'react';
import styled from 'styled-components';
import { RemoveCircle } from 'componnets';
import { DragIcon } from 'scribexUI/icons';
import { useDrag, useDrop } from 'react-dnd';
import { SXToggle, SXTypography } from 'scribexUI';
import styles from './styles.module.scss'
const {Text} = SXTypography;

interface ItemProps {
  id: string;
  text: string;
  index: number;
  required?:boolean
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  onRemoveClick: (id: string) => void;
  onToggle: (value: boolean) => void;
}

const StyledDraggableColumn = styled.div<{ $isDragging: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #E6E6E6;
  ${(props) => `opacity: ${props.$isDragging ? 0.5 : 1}`};

  &:hover {
    background-color: #F8F8F8;
  }
`;

const StyledCloseContainer = styled.div`
  cursor: pointer;
`;
const StyledDragContainer = styled.div`
  cursor: pointer;
`;

export const ApplicationFormDraggableField: FC<ItemProps> = ({id, text, index, required, moveItem, onRemoveClick, onToggle}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'FIELD',
    hover: (item: { id: string; index: number }) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: 'FIELD',
    item: {id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <StyledDraggableColumn
      ref={ref}
      $isDragging={isDragging}
    >
      <StyledCloseContainer onClick={() => onRemoveClick(id)}>
        <RemoveCircle/>
      </StyledCloseContainer>
      <Text className={styles.field_label} size={'md'} color={'#68686B'}>
        {text}
      </Text>
      <Space style={{marginLeft: 'auto'}}>
        <SXToggle defaultChecked={required} onChange={(value) => onToggle(value)}/>
        <Text>Required</Text>
      </Space>
      <StyledDragContainer>
        <DragIcon/>
      </StyledDragContainer>
    </StyledDraggableColumn>
  )
}