import React, { FC } from 'react';
import styled from 'styled-components';
import {SXTypography} from 'scribexUI';
const {Text} = SXTypography;

interface ITagListItem {
  bordered?: boolean
  onOptionSelect?: (event: React.MouseEvent, item: string) => void
  options: string[]
}

const StyledListContainer = styled.ul`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  max-width: 450px;

  li:first-child {
    border-top: none;
  }
  box-shadow: 0 4px 8px 0 #00000014;

`;
const StyledTagListItemContainer = styled.li<{$bordered:boolean}>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  list-style-type: none;
  padding: 8px;
  ${(props) => props.$bordered && "border-top: 0.5px solid #E6E6E6"}
`;

const StyledTagListItem = styled.span`
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  list-style-type: none;
  width: 100%;
`;
export const TagList:FC<ITagListItem> = React.memo((props) => {
  const {options, bordered = false, onOptionSelect} = props;
  return (
    <StyledListContainer>
      {options.map(_option => {
        return <StyledTagListItemContainer
          $bordered={bordered}
          key={_option}
        >
          <StyledTagListItem
            onClick={(event: React.MouseEvent) => {
              onOptionSelect && onOptionSelect(event, _option);
            }}>
            <Text size={'md'} color={'#68686B'}>{_option}</Text>
          </StyledTagListItem>
        </StyledTagListItemContainer>
      })}
    </StyledListContainer>
  )
})