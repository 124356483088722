import React, { FC } from 'react';
import { Col, Form as AntdForm, Row } from 'antd';
import { CustomFieldsType, FileSelectTYpe } from 'typings';
import { SXButton, SXInput, SXSelect, SXTypography } from 'scribexUI';
import { useCreateFields } from './useCreateFields';
import styles from './styles.module.scss';

const {Text} = SXTypography;

interface ICreateFields {
  onCancel: () => void
}

export const CreateFields: FC<ICreateFields> = React.memo((props) => {
  const {
    onCancel
  } = props;
  const {
    typeOptions,
    onTypeChange,
    inputType,
    inputTypeFields,
    form,
    onFinish,
    contextHolder,
    fieldsValidationMapping,
    isLoading,
    defaultType
  } = useCreateFields(onCancel);
  return (
    <>
      {contextHolder}
      <Row gutter={[0, 16]} justify={'center'} className={styles.create_fields}>
        <Col span={14} className={'center'}>
          <Text>Configure your form fields here.<br/>
            Add new fields apply validation rules as needed.</Text>
        </Col>
        <Col span={24}>
          <AntdForm
            form={form}
            onFinish={onFinish}
            initialValues={{remember: false, type: typeOptions[0].value}}
            autoComplete={'off'}
          >
            <Row gutter={[0, 32]}>
              <Col span={24} className={styles.bottom_box}>
                <Row className={styles.form_fields} gutter={[16, 16]}>
                  <Col span={12}>
                    <AntdForm.Item name={'type'}>
                      <SXSelect<CustomFieldsType>
                        size={'fill'}
                        inputLabel={'Type*'}
                        defaultOption={[defaultType]}
                        options={typeOptions}
                        onSelect={onTypeChange}
                      />
                    </AntdForm.Item>
                  </Col>
                  {inputTypeFields.map(_field => {
                    if (inputType === 'FILE' && _field.options?.length) {
                      return <Col span={12} key={_field.label}>
                        <AntdForm.Item name={_field.key}>
                          <SXSelect<FileSelectTYpe>
                            size={'fill'}
                            inputLabel={_field.label}
                            placeholder={'Select File type'}
                            options={_field.options}
                            selectable/>
                        </AntdForm.Item>
                      </Col>
                    } else if (inputType === 'NUMBER' && _field.options?.length) {
                      return <Col span={12} key={_field.label}>
                        <Row gutter={8} align={'bottom'}>
                          {_field.options.map((_option, index) => <Col span={12} key={_option.label}>
                            <AntdForm.Item name={_option.key}>
                            <SXInput placeholder={_option.label} label={!index ? _field.label : ''} type={'number'}/>
                            </AntdForm.Item>
                            </Col>)}
                        </Row>
                      </Col>
                    }
                    return <Col span={12} key={_field.label}>
                      <AntdForm.Item name={_field.key}>
                        <SXInput
                          label={_field.label}
                          placeholder={_field.value}
                          type={_field.key === "characterLimit" ? 'number' : undefined}
                          isValid={fieldsValidationMapping[_field.key]?.isValid}
                          validationInfo={{
                            error: fieldsValidationMapping[_field.key]?.message
                          }}
                        />
                      </AntdForm.Item>
                    </Col>
                  })}
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={8}>
                  <Col span={12}>
                    <SXButton onClick={onCancel} size={'sm'} block sxType={'tertiary'}>Cancel</SXButton>
                  </Col>
                  <Col span={12}>
                    <SXButton
                      isLoading={isLoading}
                      type={'submit'}
                      size={'sm'}
                      block
                      sxType={'primary'}>Save
                    </SXButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </AntdForm>
        </Col>
      </Row>
    </>
  )
})