import { CustomFieldsListType, CustomFieldsType } from 'typings';
import { SignupErrorMappingValueType } from 'typings';

type SignInErrorMappingKeyType = 'INVALID_CREDENTIALS' | string;

type SignInErrorMappingType = {
  [key: SignInErrorMappingKeyType]: SignupErrorMappingValueType
}

type PasswordResetVerificationSuccessMappingType = {
  [key: 'RESET_PASSWORD_SUCCESS' | string]: SignupErrorMappingValueType
}
export const SignInErrorMapping: SignInErrorMappingType = {
  INVALID_CREDENTIALS: {
    type: 'error',
    message: 'Email or password is invalid!'
  },
}
export const ResetPasswordSuccessMapping: PasswordResetVerificationSuccessMappingType = {
  RESET_PASSWORD_SUCCESS: {
    type: 'success',
    message: 'Password successfully changed!'
  },
}

export const CUSTOM_FIELD_TYPE_MAPPING: Record<CustomFieldsType, CustomFieldsListType[]> = {
  TEXT: [
    {
      label: 'Input Label*',
      value: 'Position',
      key: 'label'
    },
    {
      label: 'Characters Limit',
      value: '250',
      key: 'characterLimit'

    },
    {
      label: 'Input Placeholder*',
      value: 'Type Position',
      key: 'placeholder'
    }
  ],
  TEXT_AREA: [
    {
      label: 'Input Label*',
      value: 'Cover Letter',
      key: 'label'

    },
    {
      label: 'Characters Limit',
      value: '1000',
      key: 'characterLimit'
    },
    {
      label: 'Input Placeholder*',
      value: 'Type Position',
      key: 'placeholder'
    },
  ],
  NUMBER: [
    {
      label: 'Input Label*',
      value: 'Number',
      key: 'label'

    },
    {
      label: 'Range',
      key: 'range',
      options: [
        {
          label: 'Min',
          value: '',
          key: 'min'
        },
        {
          label: 'Max',
          value: '',
          key: 'max'

        }
      ]
    },
    {
      label: 'Input Placeholder*',
      value: 'Number',
      key: 'placeholder'
    },
  ],
  FILE: [
    {
      label: 'Input Label*',
      value: 'Attach File',
      key: 'label'
    },
    {
      label: 'File Type',
      key: 'fileType',
      options: [
        {
          label: 'PNG',
          value: 'png'
        },
        {
          label: 'SVG',
          value: 'svg'
        },
        {
          label: 'JPG',
          value: 'jpg'
        },
        {
          label: 'PDF',
          value: 'pdf'
        },
      ]
    },
    {
      label: 'Input Placeholder*',
      value: 'Type Position',
      key: 'placeholder'
    }
  ],
  EMAIL: [
    {
      label: 'Input Label*',
      value: 'Cover Letter',
      key: 'label'
    },
    {
      label: 'Input Placeholder*',
      value: 'Email',
      key: 'placeholder'

    },
  ],
  PHONE_NUMBER: [
    {
      label: 'Input Label*',
      value: 'Phone Number',
      key: 'label'
    }
  ],
};

export const CustomFieldsDefaultValues = {
  characterLimit: 0,
  placeholder: null,
  fileType: null,
  min: null,
  max: null
}
