export const FilterIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 4.9375H12C11.9869 4.9375 11.9713 4.9323 11.957 4.91801C11.9427 4.90372 11.9375 4.88806 11.9375 4.875C11.9375 4.86194 11.9427 4.84628 11.957 4.83199C11.9713 4.8177 11.9869 4.8125 12 4.8125H16.5C16.5131 4.8125 16.5287 4.8177 16.543 4.83199C16.5573 4.84628 16.5625 4.86194 16.5625 4.875C16.5625 4.88806 16.5573 4.90372 16.543 4.91801C16.5287 4.9323 16.5131 4.9375 16.5 4.9375Z"
        fill="#222222" stroke="#222222"/>
      <path
        d="M4.5 4.9375H1.5C1.48694 4.9375 1.47128 4.9323 1.45699 4.91801C1.4427 4.90372 1.4375 4.88806 1.4375 4.875C1.4375 4.86194 1.4427 4.84628 1.45699 4.83199C1.47128 4.8177 1.48694 4.8125 1.5 4.8125H4.5C4.51306 4.8125 4.52872 4.8177 4.54301 4.83199C4.5573 4.84628 4.5625 4.86194 4.5625 4.875C4.5625 4.88806 4.5573 4.90372 4.54301 4.91801C4.52872 4.9323 4.51306 4.9375 4.5 4.9375Z"
        fill="#222222" stroke="#222222"/>
      <path
        d="M7.5 7.5625C6.02114 7.5625 4.8125 6.35386 4.8125 4.875C4.8125 3.39614 6.02114 2.1875 7.5 2.1875C8.97886 2.1875 10.1875 3.39614 10.1875 4.875C10.1875 6.35386 8.97886 7.5625 7.5 7.5625ZM7.5 2.3125C6.08386 2.3125 4.9375 3.45886 4.9375 4.875C4.9375 6.29114 6.08386 7.4375 7.5 7.4375C8.91614 7.4375 10.0625 6.29114 10.0625 4.875C10.0625 3.45886 8.91614 2.3125 7.5 2.3125Z"
        fill="#222222" stroke="#222222"/>
      <path
        d="M16.5 13.6875H13.5C13.1925 13.6875 12.9375 13.4325 12.9375 13.125C12.9375 12.8175 13.1925 12.5625 13.5 12.5625H16.5C16.8075 12.5625 17.0625 12.8175 17.0625 13.125C17.0625 13.4325 16.8075 13.6875 16.5 13.6875Z"
        fill="#222222"/>
      <path
        d="M6 13.6875H1.5C1.1925 13.6875 0.9375 13.4325 0.9375 13.125C0.9375 12.8175 1.1925 12.5625 1.5 12.5625H6C6.3075 12.5625 6.5625 12.8175 6.5625 13.125C6.5625 13.4325 6.3075 13.6875 6 13.6875Z"
        fill="#222222"/>
      <path
        d="M10.5 16.3125C8.745 16.3125 7.3125 14.88 7.3125 13.125C7.3125 11.37 8.745 9.9375 10.5 9.9375C12.255 9.9375 13.6875 11.37 13.6875 13.125C13.6875 14.88 12.255 16.3125 10.5 16.3125ZM10.5 11.0625C9.36 11.0625 8.4375 11.985 8.4375 13.125C8.4375 14.265 9.36 15.1875 10.5 15.1875C11.64 15.1875 12.5625 14.265 12.5625 13.125C12.5625 11.985 11.64 11.0625 10.5 11.0625Z"
        fill="#222222"/>
    </svg>
  )
}