import React, { FC } from 'react';
import styled, { css } from 'styled-components';

export type TextType = {
  $size: 'sm' | 'md' | 'base' | 'lg',
  $underline?: boolean,
  $italic?: boolean,
  $delete?: boolean,
  $weight: 'normal' | 'medium' | 'strong' | 'light',
  $center?: boolean,
  $font?: string,
}

const sizes: Record<TextType["$size"], any> = {
  sm: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  md: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  lg: {
    fontSize: '20px',
    lineHeight: '26px'
  },
};

const weights: Record<TextType["$weight"], any> = {
  strong: {
    fontFamily: 'DMSans-SemiBold, sans-serif'
  },
  medium: {
    fontFamily: 'DMSans-Medium, sans-serif'
  },
  normal: {
    fontFamily: 'DMSans-Regular, sans-serif'
  },
  light: {
    fontFamily: 'DMSans-Light, sans-serif'
  }

}

export interface TextProps {
  size?: TextType["$size"]
  children: React.ReactNode;
  underline?: boolean,
  italic?: boolean,
  delete?: boolean,
  weight?: 'medium' | 'strong' | 'light',
  color?: string,
  className?: string,
  center?: boolean,
  font?: string,
  onClick?:() => void
}

const StyledText = styled.span<TextType>`
  color: #222222;
  text-overflow: ellipsis;
  ${(props) => sizes[props.$size]}
  ${(props) => props.$font ? css`
    font-family: ${props.$font};` : weights[props.$weight]}
  ${(props) => props.$underline && css`
    text-decoration: underline;
  `}
  ${(props) => props.$delete && css`
    text-decoration: line-through;
  `}
  ${(props) => props.$italic && css`
    font-style: italic;
  `}
  ${(props) => props.color && css`
    color: ${props.color};
  `}
  ${(props) => props.$center && css`
    text-align: center;
  `}
`;

export const Text: FC<TextProps> = (props) => {
  const {
    size = 'base',
    children,
    underline,
    italic,
    weight = 'normal',
    delete: deleteProp,
    color,
    center,
    className,
    font,
    ...rest
  } = props;
  return (
    <StyledText
      className={className}
      $center={center}
      $underline={underline}
      $delete={deleteProp}
      $italic={italic}
      $weight={weight}
      color={color}
      $size={size}
      $font={font}
      {...rest}
    >
      {children}
    </StyledText>
  );
};
