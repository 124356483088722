export const EditCircle = () => {
  return (

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#EEEFFF"/>
      <path
        d="M17.2502 11.4166L20.5835 14.75M9.3335 22.6666H12.6668L21.4168 13.9166C21.8589 13.4746 22.1072 12.8751 22.1072 12.25C22.1072 11.6248 21.8589 11.0253 21.4168 10.5833C20.9748 10.1413 20.3753 9.89294 19.7502 9.89294C19.125 9.89294 18.5255 10.1413 18.0835 10.5833L9.3335 19.3333V22.6666Z"
        stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}