export const DatePickerIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.2149 6.0814C7.84304 6.0814 7.53467 5.77302 7.53467 5.40116V2.68023C7.53467 2.30837 7.84304 2 8.2149 2C8.58676 2 8.89513 2.30837 8.89513 2.68023V5.40116C8.89513 5.77302 8.58676 6.0814 8.2149 6.0814Z" fill="#222222"/>
      <path d="M15.4708 6.0814C15.0989 6.0814 14.7905 5.77302 14.7905 5.40116V2.68023C14.7905 2.30837 15.0989 2 15.4708 2C15.8426 2 16.151 2.30837 16.151 2.68023V5.40116C16.151 5.77302 15.8426 6.0814 15.4708 6.0814Z" fill="#222222"/>
      <path d="M8.6687 14.0177C8.55079 14.0177 8.43288 13.9905 8.32404 13.9451C8.20614 13.8998 8.11544 13.8363 8.02474 13.7546C7.86149 13.5823 7.76172 13.3556 7.76172 13.1107C7.76172 12.9928 7.78893 12.8749 7.83428 12.766C7.87963 12.6572 7.94311 12.5574 8.02474 12.4667C8.11544 12.3851 8.20614 12.3216 8.32404 12.2763C8.65055 12.1402 9.05869 12.2128 9.31265 12.4667C9.4759 12.6391 9.57567 12.8749 9.57567 13.1107C9.57567 13.1651 9.5666 13.2286 9.55753 13.2921C9.54846 13.3465 9.53032 13.4009 9.50311 13.4553C9.48497 13.5098 9.45777 13.5642 9.42149 13.6186C9.39428 13.6639 9.34893 13.7093 9.31265 13.7546C9.14032 13.9179 8.90451 14.0177 8.6687 14.0177Z" fill="#222222"/>
      <path d="M11.843 14.0173C11.7251 14.0173 11.6072 13.9901 11.4984 13.9448C11.3805 13.8994 11.2898 13.8359 11.1991 13.7543C11.0358 13.5819 10.936 13.3552 10.936 13.1103C10.936 12.9924 10.9632 12.8745 11.0086 12.7657C11.0539 12.6568 11.1174 12.5571 11.1991 12.4664C11.2898 12.3847 11.3805 12.3212 11.4984 12.2759C11.8249 12.1308 12.233 12.2124 12.487 12.4664C12.6502 12.6387 12.75 12.8745 12.75 13.1103C12.75 13.1647 12.7409 13.2282 12.7318 13.2917C12.7228 13.3461 12.7046 13.4006 12.6774 13.455C12.6593 13.5094 12.6321 13.5638 12.5958 13.6182C12.5686 13.6636 12.5232 13.7089 12.487 13.7543C12.3146 13.9175 12.0788 14.0173 11.843 14.0173Z" fill="#222222"/>
      <path d="M15.0173 14.0173C14.8994 14.0173 14.7815 13.9901 14.6727 13.9448C14.5548 13.8994 14.4641 13.8359 14.3734 13.7543C14.3371 13.7089 14.3008 13.6636 14.2645 13.6182C14.2283 13.5638 14.2011 13.5094 14.1829 13.455C14.1557 13.4006 14.1376 13.3461 14.1285 13.2917C14.1194 13.2282 14.1104 13.1647 14.1104 13.1103C14.1104 12.8745 14.2101 12.6387 14.3734 12.4664C14.4641 12.3847 14.5548 12.3212 14.6727 12.2759C15.0083 12.1308 15.4073 12.2124 15.6613 12.4664C15.8245 12.6387 15.9243 12.8745 15.9243 13.1103C15.9243 13.1647 15.9152 13.2282 15.9062 13.2917C15.8971 13.3461 15.879 13.4006 15.8517 13.455C15.8336 13.5094 15.8064 13.5638 15.7701 13.6182C15.7429 13.6636 15.6976 13.7089 15.6613 13.7543C15.489 13.9175 15.2531 14.0173 15.0173 14.0173Z" fill="#222222"/>
      <path d="M8.6687 17.192C8.55079 17.192 8.43288 17.1648 8.32404 17.1195C8.21521 17.0741 8.11544 17.0106 8.02474 16.929C7.86149 16.7567 7.76172 16.5209 7.76172 16.285C7.76172 16.1671 7.78893 16.0492 7.83428 15.9404C7.87963 15.8225 7.94311 15.7227 8.02474 15.6411C8.36032 15.3055 8.97707 15.3055 9.31265 15.6411C9.4759 15.8134 9.57567 16.0492 9.57567 16.285C9.57567 16.5209 9.4759 16.7567 9.31265 16.929C9.14032 17.0922 8.90451 17.192 8.6687 17.192Z" fill="#222222"/>
      <path d="M11.843 17.192C11.6072 17.192 11.3714 17.0922 11.1991 16.929C11.0358 16.7567 10.936 16.5209 10.936 16.285C10.936 16.1671 10.9632 16.0492 11.0086 15.9404C11.0539 15.8225 11.1174 15.7227 11.1991 15.6411C11.5346 15.3055 12.1514 15.3055 12.487 15.6411C12.5686 15.7227 12.6321 15.8225 12.6774 15.9404C12.7228 16.0492 12.75 16.1671 12.75 16.285C12.75 16.5209 12.6502 16.7567 12.487 16.929C12.3146 17.0922 12.0788 17.192 11.843 17.192Z" fill="#222222"/>
      <path d="M15.0173 17.1917C14.7815 17.1917 14.5457 17.0919 14.3734 16.9287C14.2917 16.8471 14.2283 16.7473 14.1829 16.6294C14.1376 16.5206 14.1104 16.4026 14.1104 16.2847C14.1104 16.1668 14.1376 16.0489 14.1829 15.9401C14.2283 15.8222 14.2917 15.7224 14.3734 15.6408C14.582 15.4322 14.8994 15.3324 15.1897 15.3959C15.2531 15.405 15.3076 15.4231 15.362 15.4503C15.4164 15.4684 15.4708 15.4957 15.5252 15.532C15.5706 15.5592 15.6159 15.6045 15.6613 15.6408C15.8245 15.8131 15.9243 16.0489 15.9243 16.2847C15.9243 16.5206 15.8245 16.7564 15.6613 16.9287C15.489 17.0919 15.2531 17.1917 15.0173 17.1917Z" fill="#222222"/>
      <path d="M19.5525 9.79088H4.13385C3.76199 9.79088 3.45361 9.48251 3.45361 9.11065C3.45361 8.73879 3.76199 8.43042 4.13385 8.43042H19.5525C19.9243 8.43042 20.2327 8.73879 20.2327 9.11065C20.2327 9.48251 19.9243 9.79088 19.5525 9.79088Z" fill="#222222"/>
      <path d="M15.4709 21.4999H8.21512C4.90465 21.4999 3 19.5952 3 16.2848V8.57547C3 5.265 4.90465 3.36035 8.21512 3.36035H15.4709C18.7814 3.36035 20.686 5.265 20.686 8.57547V16.2848C20.686 19.5952 18.7814 21.4999 15.4709 21.4999ZM8.21512 4.72082C5.62116 4.72082 4.36047 5.98151 4.36047 8.57547V16.2848C4.36047 18.8787 5.62116 20.1394 8.21512 20.1394H15.4709C18.0649 20.1394 19.3256 18.8787 19.3256 16.2848V8.57547C19.3256 5.98151 18.0649 4.72082 15.4709 4.72082H8.21512Z" fill="#222222"/>
    </svg>

  )
}