import React, { FC } from 'react';
import { Col, DatePicker, Row } from 'antd';
import { DatePickerIcon } from 'scribexUI/icons';
import { DatePickerProps } from 'antd/lib/date-picker';
import './dataPickerReset.scss';
import { SXTypography } from 'scribexUI';
const {Text} = SXTypography;

interface ISXDataPicker extends DatePickerProps {
  label?:string
}
export const SXDataPicker: FC<ISXDataPicker> = React.memo((props) => {
  const {label, ...rest} = props;
  return (
    <Row gutter={[0, 4]}>
      <Col span={24}>
        <Text weight={'strong'}>{label}</Text>
      </Col>
      <Col span={24}>
        <DatePicker {...rest} suffixIcon={<DatePickerIcon/>} rootClassName={"data_picker"}/>
      </Col>
    </Row>
  )
})