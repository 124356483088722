import React from 'react';
import { Col, Row } from 'antd';
import { JobProfile } from 'componnets';
import { SXButton, SXTypography } from 'scribexUI';
import styles from './styles.module.scss';
import { useCareers } from './useCareers';

const {Text} = SXTypography;
export const Careers = React.memo(() => {
  const {width, onReachOut, data} = useCareers();
  return (
    <Row className={styles.careers} justify={'start'} gutter={[0, {xs:40, sm:60}]}>
      <Col>
        <Row align={'middle'} className={styles.title_container} wrap={width < 900}>
          <Text color={'#ffffff'} className={styles.title}>
            TALENT WANTED. ALWAYS
          </Text>
          <Row wrap={true} gutter={[0, 8]}>
            <Col span={24}>
              <Text size={'lg'} color={'#ffffff'} className={styles.reach_out_text}>CRAFT AWARD-WINNING PRODUCTS WITH
                US</Text>
            </Col>
            <SXButton onClick={onReachOut} color={'#331DAD'}>Reach Out</SXButton>
          </Row>
        </Row>
      </Col>
      <Col className={styles.open_positions} span={24}>
        {/*
        <Row gutter={[0, 16]} justify={'center'} align={'middle'} className={styles.no_job_container}>*/}
        {/*  <Col className={'center'}>*/}
        {/*    <Text color={'#FFFFFF'} weight={'strong'} className={styles.open_positions_title}>*/}
        {/*      New Job Openings Coming Soon*/}
        {/*    </Text>*/}
        {/*  </Col>*/}
        {/*  <Col className={'center'}>*/}
        {/*    <Text className={styles.open_positions_description} size={'lg'} color={'#FFFFFF'}>Currently, there are no open positions, but we’re always eager to meet exceptional talent.</Text>*/}
        {/*  </Col>*/}
        {/*
        </Row>*/}
        <Row gutter={[0, 12]} justify={'start'} className={styles.open_positions_container}>
          <Text color={'#ffffff'} className={styles.open_positions_title}>OPEN POSITIONS</Text>
          <Col span={24} >{data.map((_job:any) => <JobProfile key={_job.id} {..._job}/>)}</Col>
        </Row>
       </Col>
    </Row>
  )
})