import { JobDetailsType } from 'typings';

export const JOB_DETAILS: JobDetailsType = {
  title: {
    type: 'input',
  },
  description: {
    type: 'text_area'
  },
  location: {
    type: 'select',
    options: [
      {
        label: 'USA',
        value: 'usa'
      },
      {
        label: 'UAE',
        value: 'uae'
      },
      {
        label: 'ARM',
        value: 'arm'
      }
    ]
  },
  jobType: {
    type: 'select',
    options: [
      {
        label: 'Full-time',
        value: 'FULL_TIME'
      },
      {
        label: 'Part-time',
        value: 'PART_TIME'
      }
    ]
  },
  department: {
    type: 'tag_input'
  },
  seniorityLevel: {
    type: 'tag_input'
  },
  salaryRange: {
    type: 'range_input'
  },
  currency: {
    type: 'select',
    options: [
      {
        label: 'USA',
        value: 'usa'
      },
      {
        label: 'EURO',
        value: 'euro'
      },
      {
        label: 'ADM',
        value: 'amd'
      }
    ]
  },
};

export const APPLICATION_DETAILS: Record<string, any> = {
  deadLine: {
    type: 'calendar'
  },
  applicationMethod: {
    type: 'radio_option',
    options: [
      {
        label: 'Email Application',
        value: 'EMAIL',
        name: 'application'
      },
      {
        label: 'Form Application',
        value: 'FORM',
        name: 'application',
      },
    ]
  },
  emailApp: {
    type: 'input',
    placeholder:'Type Email address',
    tooltip: {
      type: 'info',
      value: 'Write email where application should be sent.'
    }
  },
  instruction: {
    type: 'text_area'
  }
}