export const ShowIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99982 10.6457C6.53927 10.6457 5.35371 9.46018 5.35371 7.99963C5.35371 6.53907 6.53927 5.35352 7.99982 5.35352C9.46038 5.35352 10.6459 6.53907 10.6459 7.99963C10.6459 9.46018 9.46038 10.6457 7.99982 10.6457ZM7.99982 6.27018C7.04649 6.27018 6.27038 7.04629 6.27038 7.99963C6.27038 8.95296 7.04649 9.72907 7.99982 9.72907C8.95316 9.72907 9.72927 8.95296 9.72927 7.99963C9.72927 7.04629 8.95316 6.27018 7.99982 6.27018Z"
        fill="#222222"/>
      <path
        d="M7.99992 13.5122C5.70214 13.5122 3.5327 12.1678 2.04159 9.83335C1.39381 8.82502 1.39381 7.18113 2.04159 6.16668C3.53881 3.83224 5.70826 2.48779 7.99992 2.48779C10.2916 2.48779 12.461 3.83224 13.9521 6.16668C14.5999 7.17501 14.5999 8.8189 13.9521 9.83335C12.461 12.1678 10.2916 13.5122 7.99992 13.5122ZM7.99992 3.40446C6.02603 3.40446 4.1377 4.59002 2.8177 6.66168C2.35937 7.37668 2.35937 8.62335 2.8177 9.33835C4.1377 11.41 6.02603 12.5956 7.99992 12.5956C9.97381 12.5956 11.8621 11.41 13.1821 9.33835C13.6405 8.62335 13.6405 7.37668 13.1821 6.66168C11.8621 4.59002 9.97381 3.40446 7.99992 3.40446Z"
        fill="#222222"/>
    </svg>

  )
}