import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultOptionType, SXCheckbox, SXTypography } from 'scribexUI';

const {Text} = SXTypography;

export type OptionType = {
  value: string | number
  label: string
  key?: 'min' | 'max'
}

interface IStyledLiContainer {
  $bordered: boolean
}

interface IListItem {
  bordered?: boolean
  onOptionSelect?: (event: React.MouseEvent, item: OptionType) => void
  options: OptionType[]
  placeholder?: string
  selectedValue: DefaultOptionType[]
  size?: "sm" | 'md' | 'fill' | 'xs'
  id?: string
  selectable?: boolean
  onCheckboxClick?: (event: React.ChangeEvent<HTMLInputElement>, option?: any) => void;
}

type StyledListContainerType = {
  $size: "sm" | 'md' | 'fill' | 'xs'
}

const StyledListContainer = styled.ul<StyledListContainerType>`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: ${({$size}) => $size === 'xs' ? '0px' : '8px'};
  cursor: pointer;

  li:first-child {
    border-top: none;
  }
`;

const StyledListItemContainer = styled.li<IStyledLiContainer>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  list-style-type: none;
  padding: 8px;
  ${(props) => props.$bordered && "border-top: 0.5px solid #E6E6E6"}
`;
const StyledListItem = styled.span`
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  list-style-type: none;
  width: 100%;
`;
export const OptionsSelectList: FC<IListItem> = React.memo((props) => {
  const {
    bordered = true,
    onOptionSelect,
    options,
    placeholder,
    selectedValue,
    selectable,
    onCheckboxClick,
    id,
    size = 'md'
  } = props;
  return (
    <StyledListContainer $size={size} id={id}>
      {!!selectedValue.length && placeholder && <StyledListItemContainer
        $bordered={bordered}
      >
        <StyledListItem
          onClick={(event: React.MouseEvent) => {
            onOptionSelect && onOptionSelect(event, {value: '', label: placeholder || ''});
          }}>
          <Text size={'md'} color={'#68686B'}>{placeholder}</Text>
        </StyledListItem>
      </StyledListItemContainer>}
      {options.map(_option => {
        const isChecked = selectedValue?.some(_selectedOption => _selectedOption.label === _option.label);
        return <StyledListItemContainer
          $bordered={bordered}
          key={_option.value}
        >
          <StyledListItem
            onClick={(event: React.MouseEvent) => {
              onOptionSelect && onOptionSelect(event, {..._option});
            }}>
            <Text size={'md'} color={'#68686B'}>{_option.label}</Text>
          </StyledListItem>
          {selectable && <SXCheckbox
            defaultChecked={isChecked}
            onChange={(event) => {
              onCheckboxClick && onCheckboxClick(event, {..._option})
            }}
            value={isChecked}
          />}
        </StyledListItemContainer>
      })}
    </StyledListContainer>
  )
})