export const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2528_9966)">
        <path
          d="M4 10.2222C4 11.0393 4.16094 11.8484 4.47364 12.6034C4.78633 13.3583 5.24466 14.0442 5.82245 14.622C6.40023 15.1998 7.08617 15.6581 7.84108 15.9708C8.596 16.2835 9.40511 16.4444 10.2222 16.4444C11.0393 16.4444 11.8484 16.2835 12.6034 15.9708C13.3583 15.6581 14.0442 15.1998 14.622 14.622C15.1998 14.0442 15.6581 13.3583 15.9708 12.6034C16.2835 11.8484 16.4444 11.0393 16.4444 10.2222C16.4444 9.40511 16.2835 8.596 15.9708 7.84108C15.6581 7.08617 15.1998 6.40023 14.622 5.82245C14.0442 5.24466 13.3583 4.78633 12.6034 4.47364C11.8484 4.16094 11.0393 4 10.2222 4C9.40511 4 8.596 4.16094 7.84108 4.47364C7.08617 4.78633 6.40023 5.24466 5.82245 5.82245C5.24466 6.40023 4.78633 7.08617 4.47364 7.84108C4.16094 8.596 4 9.40511 4 10.2222Z"
          stroke="#A9A9AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.9999 20.0003L14.6666 14.667" stroke="#A9A9AA" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2528_9966">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}