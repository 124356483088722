import React, { ChangeEvent, useRef } from 'react';
import styled, { css } from 'styled-components';
import { CloseIcon, DangerIcon, InfoIcon, UploadIcon } from 'scribexUI/icons';
import { ValidationInfoType } from '../../typings';

interface AttachmentProps {
  file?: File | null;
  onFileChange?: (file: File | null) => void;
  acceptTypes?: string
  label?: string
  disabled? :boolean,
  isValid? :boolean,
  validationInfo?: ValidationInfoType;
}

interface AttachmentMessage {
  $isValid? :boolean,
}
type IconWrapperType = {
  $isUploaded: boolean
}

const AttachmentWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const FileInput = styled.input`
  display: none !important;
`;

const UploadButton = styled.span<IconWrapperType>`
  color: #68686B;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  font-family: DMSans-Regular, sans-serif;
  max-width: 330px;

  &:hover {
    color: #140D0B;
  }

  ${(props) => props.$isUploaded && css`
    color: #331DAD;
  `}
`;

const UploadLabelWrapper = styled.label<IconWrapperType>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    .svg-icon {
      fill: #140D0B;
    }

    span {
      color: #140D0B;

    }
  }

  ${(props) => props.$isUploaded && css`
    .svg-icon {
      fill: rgba(51, 29, 173, 1);
    }
  `}
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
const StyledMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  width: 100%;
  position: absolute;
  top: calc(100% + 4px);
`;
const StyledValidationIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;
const StyledMessage = styled.span<AttachmentMessage>`
  color: #68686B;
  font-family: DMSans-Regular, sans-serif;
  font-size: 12px;
  line-height: 16px;

  ${(props) => {
    if (typeof props.$isValid !== "undefined") {
      return props.$isValid ? css`
        color: #68686B;
      ` : css`
        color: #F5222D;
      `
    }
  }
  }
`;

export const SXUpload: React.FC<AttachmentProps> = (props) => {
  const {
    file,
    acceptTypes = "",
    label = 'Attachment',
    onFileChange,
    disabled = false,
    isValid,
    validationInfo = {}
  } = props;
  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const files = event.target.files;
    if (files && files.length > 0) {
      const splitName = files[0].name.split('.');
      const extension = splitName[splitName.length - 1];
      if (acceptTypes.includes(extension)) {
        onFileChange && onFileChange(files[0]);
        return;
      }
    }
    onFileChange && onFileChange(null);
  };
  const handleRemove = () => {
    onFileChange && onFileChange(null);
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };
  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }
  const isValidationMessageVisible = !disabled && message;

  return (
    <AttachmentWrapper>
      <UploadLabelWrapper $isUploaded={!!file} htmlFor="fileInput">
        <UploadIcon/>
        <UploadButton $isUploaded={!!file}>{file ? file.name : label}</UploadButton>
      </UploadLabelWrapper>
      {file && <CloseButtonWrapper onClick={handleRemove}>
        <CloseIcon color={'#222222'}/>
      </CloseButtonWrapper>}
      <FileInput
        ref={inputFile}
        id="fileInput"
        type="file"
        accept={acceptTypes}
        onChange={handleFileChange}
      />
      {isValidationMessageVisible && <StyledMessageWrapper>
        <StyledValidationIcon>
          {!disabled && icon}
        </StyledValidationIcon>
        <StyledMessage $isValid={isValid}>
          {!disabled && message}
        </StyledMessage>
      </StyledMessageWrapper>}
    </AttachmentWrapper>
  );
};