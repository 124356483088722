export const AddCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00016 15.1667C4.04683 15.1667 0.833496 11.9534 0.833496 8.00004C0.833496 4.04671 4.04683 0.833374 8.00016 0.833374C11.9535 0.833374 15.1668 4.04671 15.1668 8.00004C15.1668 11.9534 11.9535 15.1667 8.00016 15.1667ZM8.00016 1.83337C4.60016 1.83337 1.8335 4.60004 1.8335 8.00004C1.8335 11.4 4.60016 14.1667 8.00016 14.1667C11.4002 14.1667 14.1668 11.4 14.1668 8.00004C14.1668 4.60004 11.4002 1.83337 8.00016 1.83337Z"
        fill="#52C41A"/>
      <path
        d="M10.6668 8.5H5.3335C5.06016 8.5 4.8335 8.27333 4.8335 8C4.8335 7.72667 5.06016 7.5 5.3335 7.5H10.6668C10.9402 7.5 11.1668 7.72667 11.1668 8C11.1668 8.27333 10.9402 8.5 10.6668 8.5Z"
        fill="#52C41A"/>
      <path
        d="M8 11.1667C7.72667 11.1667 7.5 10.94 7.5 10.6667V5.33337C7.5 5.06004 7.72667 4.83337 8 4.83337C8.27333 4.83337 8.5 5.06004 8.5 5.33337V10.6667C8.5 10.94 8.27333 11.1667 8 11.1667Z"
        fill="#52C41A"/>
    </svg>

  )
}