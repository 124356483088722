import React from 'react';
import { Col, Form, Row,  Typography, Image } from 'componnets';
import { AdminResetPasswordData } from 'mock';
import { SXButton, SXInput } from 'scribexUI';
import styles from '../signIn/styles.module.scss';
import { useAdminResetPassword } from './useResetPassword';
import image from 'assets/images/admin-forgot-password.png';


const {Text} = Typography;
const {Item: FormItem} = Form;
const {
  title,
  form: {password, confirmPassword},
  submitBtn,
  description
} = AdminResetPasswordData;
export const AdminResetPassword = React.memo(() => {
  const [formRef] = Form.useForm();
  const {
    confirmPasswordIsValid,
    passwordIsValid,
    onInputBlur,
    onSubmit,
    contextHolder
  } = useAdminResetPassword(formRef);
  return (
    <>
      {contextHolder}
      <Row wrap={true} className={styles.signup} align={'middle'}>
        <Col lg={12} md={24}>
          <Row justify={'center'} className={styles.left_side}>
            <Col sm={15} xs={24} className={styles.form}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Text className={styles.title}>{title}</Text>
                </Col>
                <Col span={24}>
                  <Text className={styles.description}>{description}</Text>
                </Col>
                <Col span={24}>
                  <Form
                    layout={'vertical'}
                    autoComplete={'off'}
                    form={formRef}
                  >
                    <Row gutter={[0, 24]}>
                      <FormItem
                        name={password.name}
                        className={styles.form_item}
                        key={password.id}
                      >
                        <SXInput
                          label={password.label}
                          type={'password'}
                          name={password.name}
                          renderIcon={true}
                          onBlur={(e) => onInputBlur({value: e.target.value, type: password.name})}
                          isValid={passwordIsValid}
                          validationInfo={password.validationInfo}
                        />
                      </FormItem>
                      <FormItem
                        name={confirmPassword.name}
                        className={styles.form_item}
                        key={confirmPassword.id}
                      >
                        <SXInput
                          label={confirmPassword.label}
                          type={'password'}
                          name={confirmPassword.name}
                          renderIcon={true}
                          onBlur={(e) => onInputBlur({value: e.target.value, type: confirmPassword.name})}
                          isValid={confirmPasswordIsValid}
                          validationInfo={confirmPassword.validationInfo}
                        />
                      </FormItem>
                      <Col span={24}>
                        <SXButton
                          sxType={'primary'}
                          block
                          roundness={'pill'}
                          onClick={() => onSubmit()}
                        >
                          {submitBtn}
                        </SXButton>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={24}>
          <Row className={styles.right_side}>
            <Image src={image} alt={"image"} preview={false} width={'100%'}/>
          </Row>
        </Col>
      </Row>
    </>
  )
})