import { api } from 'api';
import { CUSTOM_FIELD_TYPES } from 'typings';
import { Form, FormProps, notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { CustomFieldValidation } from 'constants/validations';
import {  CUSTOM_FIELD_TYPE_MAPPING } from 'constants/app';
import { CustomFieldsType, ErrorMappingValueType } from 'typings';
import { DefaultOptionType } from 'scribexUI';

export type FieldType = {
  type: string
  label: string
  placeholder: string
  fileType: string[]
  character: number
  min: number
  max: number
};

const fieldsValidationInitialState: Record<string, any> = {
  label: {
    isValid: true,
    message: 'Input Label is required',
  },
  placeholder: {
    isValid: true,
    message: 'Input Placeholder is required',
  },
  type: {
    isValid: true,
    message: 'Type is required',
  }
};
export const useCreateFields = (onCancel: () => void) => {
  const [inputType, setInputType] = useState<CustomFieldsType>('TEXT');
  const [fieldsValidationMapping, setFieldsValidationMapping] = useState(fieldsValidationInitialState);
  const [form] = Form.useForm();
  const [creteField, {isLoading}] = api.useCreateCustomFieldMutation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const typeOptions = useMemo(() => Object.entries(CUSTOM_FIELD_TYPES).map(([key, value]) => ({
    label: value,
    value: key
  })), []);
  const inputTypeFields = useMemo(() => CUSTOM_FIELD_TYPE_MAPPING[inputType], [inputType]);
  const typeField = form.getFieldValue('type');
  const defaultType: DefaultOptionType = useMemo(() => {
    return typeField ?
      typeOptions.find(_option => _option.value === typeField) as DefaultOptionType
      : typeOptions[0];
  }, [typeField, typeOptions]);

  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onTypeChange = useCallback((type: CustomFieldsType) => {
    form.resetFields();
    setInputType(type as CustomFieldsType);
    setFieldsValidationMapping(fieldsValidationInitialState);
  }, [form]);

  const validateFields = useCallback((formFields: FieldType) => {
    const labelValidation = !!formFields.label;
    const placeholderValidation = Object.prototype.hasOwnProperty.call(formFields, "placeholder") ? !!formFields.placeholder : true;
    const typeValidation = !!formFields.type;
    setFieldsValidationMapping(prevState => ({
      ...prevState,
      label: {
        ...prevState.label,
        isValid: labelValidation
      },
      placeholder: {
        ...prevState.placeholder,
        isValid: placeholderValidation
      },
      type: {
        ...prevState.type,
        isValid: typeValidation
      }
    }));
    return labelValidation && typeValidation && placeholderValidation;
  }, []);

  const onFinish: FormProps<FieldType>['onFinish'] = useCallback((values: FieldType) => {
    const payload = values;
    if (!Array.isArray(payload.fileType)) {
      payload.fileType = [payload.fileType]
    }
    const isValid = validateFields(payload);
    if (isValid) {
      creteField(payload)
        .then(({data, error}: any) => {
          if (data?.status && data?.status === "ok") {
            openNotificationWithIcon({type: 'success', message: "The custom field was successfully created."});
            onCancel();
          } else {
            if (data) {
              Object.keys(data).forEach((_key: string) => {
                if (_key === 'status') {
                  openNotificationWithIcon(CustomFieldValidation[data[_key]]);
                } else {
                  _key && openNotificationWithIcon({type: 'error', message: data[_key]});
                }
              })
            } else if (error?.data) {
              error?.data?.message && openNotificationWithIcon({type: 'error', message: error.data.message});
            }
          }
        })
        .catch(err => {
          console.log(err, 'error')
        })
    }
  }, [creteField, validateFields, openNotificationWithIcon, onCancel]);

  return {
    typeOptions,
    onTypeChange,
    inputType,
    inputTypeFields,
    form,
    contextHolder,
    onFinish,
    fieldsValidationMapping,
    isLoading,
    defaultType
  }
}