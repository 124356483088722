import { ThemeConfig } from 'antd';

export const Theme: ThemeConfig = {
  token: {
    colorPrimary: '#6573FF',

    // todo default focused hover color themes
  },
  components: {
    Tabs: {
      horizontalItemPadding: '0 0 6px 0'
      /* here is your component tokens */
    },
    Notification: {
      zIndexPopup: 9999999
    }
  },
};
