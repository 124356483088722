import { createSlice } from '@reduxjs/toolkit';

const initialState: IInitialState = {
  filteredParams: {},
  usage: {
    inUse: false,
    published: false
  }
}

type IInitialState = {
  filteredParams: Record<string, boolean | undefined | null>
  usage: Record<string, boolean>
}

export const customFieldsFilterSlice = createSlice({
  name: 'customFieldsFilter',
  initialState,
  reducers: {
    saveCustomFieldsFilterParams: (state, action) => {
      state.filteredParams = action.payload.filteredParams;
      state.usage = action.payload.usage;
    },
    resetCustomFieldsFilterParams: (state) => {
      state.filteredParams = initialState.filteredParams;
      state.usage = initialState.usage;
    }
  }
});

export const {
  saveCustomFieldsFilterParams,
  resetCustomFieldsFilterParams
} = customFieldsFilterSlice.actions
