import { SXTypography } from 'scribexUI';
import { useCallback, useState } from 'react';
import React, { JSX, useEffect, useRef } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { DangerIcon, InfoIcon, OutlineArrowDownIcon, OutlineArrowUpIcon } from 'scribexUI/icons';
import { OptionsSelectList, OptionType } from './SelectList';
import { ValidationInfoType } from 'scribexUI/typings';
import { StyledMessage, StyledMessageWrapper, StyledValidationIcon } from '../errorMessage';

const {Text} = SXTypography;

type SelectTypeSizeType = "sm" | 'md' | 'fill' | 'xs';

export type DefaultOptionType = {
  value: string | number,
  label: string
}

interface ISXSelect<T> {
  disabled?: boolean
  size?: SelectTypeSizeType
  placeholder?: string
  onSelect?: (value: T) => void
  options: OptionType[]
  defaultOption?: DefaultOptionType[]
  inputLabel?: string
  selectable?: boolean
  initialValue?: T; // Add value prop
  onChange?: (value: T) => void; // Add onChange prop
  validationInfo?: ValidationInfoType;
  isValid?: boolean;
  $isValid?: boolean;
}

interface IStyledSelectWrapper {
  disabled: boolean
  $size: SelectTypeSizeType
  $isOpen: boolean
}

interface IStyledIconWrapper {
  disabled: boolean;
  $size: SelectTypeSizeType
}

const sizes: Record<SelectTypeSizeType, CSSProp> = {
  sm: {
    maxWidth: '200px',
    maxHeight: '196px',
  },
  md: {
    maxWidth: '300px',
    maxHeight: '304px',
  },
  xs: {
    maxWidth: '90px',
    maxHeight: '304px',
  },
  fill: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}

const StyledSelectBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: transparent;
  width: 100%;
  z-index: 999;
`;

const StyledSelectWrapper = styled.div<IStyledSelectWrapper>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #D5D5D5;
  padding: 10px 16px;
  box-sizing: border-box;
  ${({$size}) => $size === 'xs' && css`
    padding: 5px 7px;
    gap: 2px;
  `};
  ${({$isOpen}) => $isOpen ? css`
    z-index: 1000;
  ` : css`
    z-index: 999;
  `};
  ${({$size}) => sizes[$size]}
  ${(props) =>
          props.disabled && css<IStyledSelectWrapper>`
            border-color: #68686B;
            color: #68686B;
            cursor: not-allowed;
          `
  }
`;
const StyledIconWrapper = styled.span<IStyledIconWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${({$size}) => $size === 'xs' && css`
    width: 16px;
    height: 16px;
  `};
  ${({disabled}) => disabled && css<IStyledIconWrapper>`
    cursor: not-allowed;
  `}
`;

type StyledOptionsType = {
  $isBottom: boolean;
  $size: SelectTypeSizeType
}

const StyledOptions = styled.div<StyledOptionsType>`
  position: absolute;
  ${({$isBottom}) => $isBottom ? 'top: calc(100% + 2px)' : 'bottom: calc(100% + 2px)'};
  left: 0;
  border-radius: 6px;
  background-color: #FBFBFB;
  box-shadow: 0 4px 8px 0 #00000014;
  ${({$size}) => sizes[$size]}; //max-height: 400px;
  max-height: 300px;
  overflow-x: auto;
  z-index: 9999;
  width: 100%;
`;

const StyledInputLabel = styled.label<{ disabled: boolean }>`
  padding: 0;
  margin: 0;
  font-family: DMSans-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  ${({disabled}) => disabled && css`
    color: #68686B;
  `}
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  position: relative;
`;

export const SXSelect = React.memo(<T extends string | number | string[]>(props: ISXSelect<T>) => {
  const {
    defaultOption,
    disabled = false,
    size = 'md',
    placeholder,
    onSelect,
    options,
    inputLabel,
    selectable,
    initialValue,
    onChange,
    validationInfo = {},
    isValid
  } = props;

  const {error, info, infoIcon = <InfoIcon/>, errorIcon = <DangerIcon/>} = validationInfo;
  const [label, setLabel] = useState<DefaultOptionType[]>(defaultOption ? defaultOption : []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);
  let message = info;
  let icon = message ? infoIcon : null;
  if (typeof props.isValid !== "undefined") {
    message = !isValid ? error : info;
    icon = !isValid ? errorIcon : infoIcon;
  }
  const isValidationMessageVisible = !disabled && message;


  useEffect(() => {
    if (initialValue && !Array.isArray(initialValue)) {
      const selectedOption = options.find(option => option.value === initialValue);
      setLabel(selectedOption ? [selectedOption] : []);
    } else if (initialValue && Array.isArray(initialValue)) {
      const selectedOption = options.filter(option => initialValue.includes(option.value as string));
      setLabel(selectedOption);
    }
  }, [initialValue, options]);

  const onOptionSelect = useCallback((event: React.MouseEvent, {value, label}: OptionType) => {
    event.stopPropagation();
    if (label === placeholder) {
      setLabel([]);
    } else {
      setLabel([{
        value,
        label
      }]);
    }
    setIsOpen(false);
    onSelect && onSelect((selectable ? !value ? null :  [value] : value) as T);
    onChange && onChange((selectable ? !value ? null :  [value] : value) as T);
  }, [placeholder, onSelect, onChange, selectable]);

  const onSelectClick = useCallback(() => {
    if (!disabled) {
      if (selectRef.current) {
        const dropdownRect = selectRef.current.getBoundingClientRect();
        setIsBottom(window.innerHeight - dropdownRect.top > options?.length * 36 || dropdownRect.bottom < options?.length * 36);
      }
      if (isOpen && selectable) {
        const selectedValues = label.map(_option => _option.value) as string[];
        onSelect && onSelect(selectedValues as T);
        onChange && onChange(selectedValues as T);
      }
      setIsOpen(prevState => !prevState);
    }
  }, [disabled, label, onSelect, isOpen, selectable, onChange, options]);

  const onCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>, option: DefaultOptionType) => {
    if (event.target.checked) {
      setLabel(prevState => [...prevState, option]);
    } else {
      setLabel(prevState => prevState.filter(_label => _label.label !== option.label));
    }
    onChange && onChange(label.map(_option => _option.value) as T);
  }, [onChange, label]);

  return (
    <>
      {isOpen && <StyledSelectBackdrop onClick={onSelectClick}/>}
      <StyledInputContainer>
        {inputLabel && <StyledInputLabel disabled={disabled} htmlFor={inputLabel} onClick={onSelectClick}>
          {inputLabel}
        </StyledInputLabel>}
        <StyledSelectWrapper $isOpen={isOpen} disabled={disabled} $size={size} onClick={onSelectClick} ref={selectRef}>
          <Text size={'md'} color={'#68686B'}>
            {label.length ? label.map(_option => `${_option.label} `) : placeholder}
          </Text>
          <StyledIconWrapper $size={size} disabled={disabled}>
            {isOpen ? <OutlineArrowUpIcon/> : <OutlineArrowDownIcon/>}
          </StyledIconWrapper>
          {isOpen && <StyledOptions $size={size} $isBottom={isBottom}>
            <OptionsSelectList
              size={size}
              options={options}
              onOptionSelect={onOptionSelect}
              placeholder={placeholder}
              selectedValue={label}
              id={inputLabel}
              onCheckboxClick={onCheckboxClick}
              selectable={selectable}
            />
          </StyledOptions>}
        </StyledSelectWrapper>
        {isValidationMessageVisible && <StyledMessageWrapper>
          <StyledValidationIcon>
            {!disabled && icon}
          </StyledValidationIcon>
          <StyledMessage $isValid={isValid}>
            {!disabled && message}
          </StyledMessage>
        </StyledMessageWrapper>}
      </StyledInputContainer>
    </>
  )
}) as unknown as <T>(
  props: ISXSelect<T>
) => JSX.Element;