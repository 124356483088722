export const InsightsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13.8499C11.87 13.8499 11.74 13.8199 11.63 13.7499C11.27 13.5399 11.15 13.0799 11.35 12.7299L12.2 11.2499H11.36C10.86 11.2499 10.45 11.0299 10.23 10.6599C10.01 10.2799 10.03 9.8199 10.28 9.3799L11.35 7.5199C11.56 7.1599 12.02 7.0399 12.37 7.2399C12.73 7.4499 12.85 7.9099 12.65 8.2599L11.8 9.7499H12.64C13.14 9.7499 13.55 9.9699 13.77 10.3399C13.99 10.7199 13.97 11.1799 13.72 11.6199L12.65 13.4799C12.51 13.7199 12.26 13.8499 12 13.8499Z"
        fill="#68686B"/>
      <path
        d="M14.77 19.7503H9.26C8.82 19.7503 8.25999 19.7003 7.89 19.3203C7.52999 18.9503 7.54 18.4903 7.55 18.1803V17.2903C5.03 15.6103 3.37 12.6903 3.37 9.91029C3.37 7.28029 4.55 4.82029 6.6 3.17029C8.64999 1.52029 11.34 0.900291 13.97 1.48029C16.47 2.03029 18.63 3.70029 19.75 5.95029C21.92 10.3203 19.83 15.0503 16.49 17.3003V18.0503C16.5 18.3403 16.51 18.8703 16.1 19.2903C15.79 19.5903 15.35 19.7503 14.77 19.7503ZM9.04 18.2403C9.09 18.2403 9.16 18.2503 9.25 18.2503H14.77C14.87 18.2503 14.94 18.2403 14.98 18.2303C14.98 18.2003 14.98 18.1603 14.98 18.1303V16.8703C14.98 16.6103 15.12 16.3603 15.34 16.2303C18.3 14.4403 20.26 10.3503 18.39 6.60029C17.47 4.75029 15.69 3.38029 13.63 2.93029C11.45 2.45029 9.22 2.96029 7.52 4.33029C5.82 5.70029 4.85 7.73029 4.85 9.91029C4.85 12.6903 6.77 15.0903 8.67 16.2403C8.9 16.3803 9.02999 16.6203 9.02999 16.8803V18.2303C9.04 18.2303 9.04 18.2303 9.04 18.2403Z"
        fill="#68686B"/>
      <path
        d="M15.5 22.7504C15.43 22.7504 15.36 22.7404 15.29 22.7204C13.13 22.1004 10.86 22.1004 8.70001 22.7204C8.30001 22.8304 7.89001 22.6004 7.77001 22.2004C7.65001 21.8004 7.89001 21.3904 8.29001 21.2704C10.71 20.5804 13.28 20.5804 15.7 21.2704C16.1 21.3804 16.33 21.8004 16.22 22.2004C16.13 22.5404 15.83 22.7504 15.5 22.7504Z"
        fill="#68686B"/>
    </svg>
  )
}
