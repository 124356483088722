import { api } from 'api';
import { useAppDispatch } from 'store/hooks';
import { useTypedSelector } from 'store/store';
import { Form as AntdForm, FormProps, notification } from 'antd';
import { CUSTOM_FIELD_TYPES, ErrorMappingValueType } from 'typings';
import React, { useCallback, useImperativeHandle, useState } from 'react';
import { saveCustomFieldsFilterParams } from './customFieldsFilterSlice';
import { CustomFieldsFilterRef } from './index';

export const useCustomFieldsFilter = (ref: React.ForwardedRef<CustomFieldsFilterRef>, onClose: (event?: React.MouseEvent) => void) => {
  const dispatch = useAppDispatch();
  useImperativeHandle(ref, () => ({
    onApply: () => {
      form.submit();
    }
  }));
  const [form] = AntdForm.useForm();
  const {filteredParams, usage} = useTypedSelector(state => state.customFieldsFilter);
  const [inUse, setInUse] = useState(usage.inUse);
  const [published, setPublished] = useState(usage.published);
  const [filter] = api.useCustomFieldFilterMutation({fixedCacheKey: 'customFieldFilterCache'});
  const [notificationApi, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback((messageContent: ErrorMappingValueType) => {
    const {type, message} = messageContent;
    notificationApi[type]({
      message: message,
    });
  }, [notificationApi]);

  const onInUseTagClick = useCallback(() => {
    if (form.getFieldValue('usage') === false) {
      form.setFieldValue('usage', null);
    } else {
      form.setFieldValue('usage', true);
    }
    setInUse(true);
  }, [form]);

  const onInUseTagRemove = useCallback(() => {
    if (form.getFieldValue('usage') === null) {
      form.setFieldValue('usage', false);
    } else {
      form.setFieldValue('usage', null);
    }
    setInUse(false);
  }, [form]);

  const onPublishedTagClick = useCallback(() => {
    if (form.getFieldValue('usage')) {
      form.setFieldValue('usage', null);
    } else {
      form.setFieldValue('usage', false);
    }
    setPublished(true);
  }, [form]);

  const onPublishedTagRemove = useCallback(() => {
    if (form.getFieldValue('usage') === null) {
      form.setFieldValue('usage', true);
    } else {
      form.setFieldValue('usage', null);
    }
    setPublished(false);
  }, [form]);

  const onFinish: FormProps<Record<any, any>>['onFinish'] = useCallback((values: any) => {
    const typeList = Object.keys(CUSTOM_FIELD_TYPES).filter(key => values[key]);
    dispatch(saveCustomFieldsFilterParams({filteredParams: values, usage: {inUse, published}}));
    filter({type: typeList.length ? typeList : null, usage: values.usage})
      .then(({data, error}: any) => {
        data && onClose();
        error && openNotificationWithIcon({type: 'error', message: "Something went wrong!"});
      })
  }, [filter, onClose, openNotificationWithIcon, dispatch, inUse, published]);

  return {
    form,
    onFinish,
    onInUseTagClick,
    onPublishedTagClick,
    onInUseTagRemove,
    onPublishedTagRemove,
    contextHolder,
    filteredParams,
    usage
  }
}