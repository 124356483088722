export const CalendarIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.21502 4.0814C4.84316 4.0814 4.53479 3.77302 4.53479 3.40116V0.680233C4.53479 0.308372 4.84316 0 5.21502 0C5.58688 0 5.89526 0.308372 5.89526 0.680233V3.40116C5.89526 3.77302 5.58688 4.0814 5.21502 4.0814Z"
        fill="#222222"/>
      <path
        d="M12.4708 4.0814C12.099 4.0814 11.7906 3.77302 11.7906 3.40116V0.680233C11.7906 0.308372 12.099 0 12.4708 0C12.8427 0 13.1511 0.308372 13.1511 0.680233V3.40116C13.1511 3.77302 12.8427 4.0814 12.4708 4.0814Z"
        fill="#222222"/>
      <path
        d="M5.66863 12.0179C5.55073 12.0179 5.43282 11.9907 5.32398 11.9454C5.20608 11.9 5.11538 11.8365 5.02468 11.7549C4.86143 11.5825 4.76166 11.3558 4.76166 11.1109C4.76166 10.993 4.78887 10.8751 4.83422 10.7663C4.87957 10.6574 4.94305 10.5577 5.02468 10.467C5.11538 10.3854 5.20608 10.3218 5.32398 10.2765C5.65049 10.1405 6.05863 10.213 6.31259 10.467C6.47584 10.6393 6.57561 10.8751 6.57561 11.1109C6.57561 11.1653 6.56654 11.2288 6.55747 11.2923C6.5484 11.3468 6.53026 11.4012 6.50305 11.4556C6.48491 11.51 6.45771 11.5644 6.42143 11.6188C6.39422 11.6642 6.34887 11.7095 6.31259 11.7549C6.14026 11.9181 5.90445 12.0179 5.66863 12.0179Z"
        fill="#222222"/>
      <path
        d="M8.84301 12.0171C8.7251 12.0171 8.6072 11.9899 8.49836 11.9445C8.38045 11.8992 8.28976 11.8357 8.19906 11.754C8.0358 11.5817 7.93604 11.355 7.93604 11.1101C7.93604 10.9922 7.96325 10.8743 8.00859 10.7654C8.05394 10.6566 8.11743 10.5568 8.19906 10.4661C8.28976 10.3845 8.38045 10.321 8.49836 10.2756C8.82487 10.1305 9.23301 10.2122 9.48696 10.4661C9.65022 10.6385 9.74999 10.8743 9.74999 11.1101C9.74999 11.1645 9.74092 11.228 9.73185 11.2915C9.72278 11.3459 9.70464 11.4003 9.67743 11.4547C9.65929 11.5092 9.63208 11.5636 9.5958 11.618C9.56859 11.6633 9.52324 11.7087 9.48696 11.754C9.31464 11.9173 9.07883 12.0171 8.84301 12.0171Z"
        fill="#222222"/>
      <path
        d="M12.0174 12.0171C11.8995 12.0171 11.7816 11.9899 11.6727 11.9445C11.5548 11.8992 11.4641 11.8357 11.3734 11.754C11.3372 11.7087 11.3009 11.6633 11.2646 11.618C11.2283 11.5636 11.2011 11.5092 11.183 11.4547C11.1558 11.4003 11.1376 11.3459 11.1286 11.2915C11.1195 11.228 11.1104 11.1645 11.1104 11.1101C11.1104 10.8743 11.2102 10.6385 11.3734 10.4661C11.4641 10.3845 11.5548 10.321 11.6727 10.2756C12.0083 10.1305 12.4074 10.2122 12.6613 10.4661C12.8246 10.6385 12.9244 10.8743 12.9244 11.1101C12.9244 11.1645 12.9153 11.228 12.9062 11.2915C12.8972 11.3459 12.879 11.4003 12.8518 11.4547C12.8337 11.5092 12.8065 11.5636 12.7702 11.618C12.743 11.6633 12.6976 11.7087 12.6613 11.754C12.489 11.9173 12.2532 12.0171 12.0174 12.0171Z"
        fill="#222222"/>
      <path
        d="M5.66863 15.1923C5.55073 15.1923 5.43282 15.1651 5.32398 15.1197C5.21515 15.0744 5.11538 15.0109 5.02468 14.9292C4.86143 14.7569 4.76166 14.5211 4.76166 14.2853C4.76166 14.1674 4.78887 14.0495 4.83422 13.9406C4.87957 13.8227 4.94305 13.723 5.02468 13.6413C5.36026 13.3058 5.97701 13.3058 6.31259 13.6413C6.47584 13.8137 6.57561 14.0495 6.57561 14.2853C6.57561 14.5211 6.47584 14.7569 6.31259 14.9292C6.14026 15.0925 5.90445 15.1923 5.66863 15.1923Z"
        fill="#222222"/>
      <path
        d="M8.84301 15.1923C8.6072 15.1923 8.37138 15.0925 8.19906 14.9292C8.0358 14.7569 7.93604 14.5211 7.93604 14.2853C7.93604 14.1674 7.96325 14.0495 8.00859 13.9406C8.05394 13.8227 8.11743 13.723 8.19906 13.6413C8.53464 13.3058 9.15138 13.3058 9.48696 13.6413C9.56859 13.723 9.63208 13.8227 9.67743 13.9406C9.72278 14.0495 9.74999 14.1674 9.74999 14.2853C9.74999 14.5211 9.65022 14.7569 9.48696 14.9292C9.31464 15.0925 9.07883 15.1923 8.84301 15.1923Z"
        fill="#222222"/>
      <path
        d="M12.0174 15.1917C11.7816 15.1917 11.5458 15.0919 11.3734 14.9287C11.2918 14.8471 11.2283 14.7473 11.183 14.6294C11.1376 14.5206 11.1104 14.4026 11.1104 14.2847C11.1104 14.1668 11.1376 14.0489 11.183 13.9401C11.2283 13.8222 11.2918 13.7224 11.3734 13.6408C11.582 13.4322 11.8995 13.3324 12.1897 13.3959C12.2532 13.405 12.3076 13.4231 12.362 13.4503C12.4165 13.4684 12.4709 13.4957 12.5253 13.532C12.5706 13.5592 12.616 13.6045 12.6613 13.6408C12.8246 13.8131 12.9244 14.0489 12.9244 14.2847C12.9244 14.5206 12.8246 14.7564 12.6613 14.9287C12.489 15.0919 12.2532 15.1917 12.0174 15.1917Z"
        fill="#222222"/>
      <path
        d="M16.5524 7.79113H1.13378C0.761924 7.79113 0.453552 7.48276 0.453552 7.1109C0.453552 6.73904 0.761924 6.43066 1.13378 6.43066H16.5524C16.9242 6.43066 17.2326 6.73904 17.2326 7.1109C17.2326 7.48276 16.9242 7.79113 16.5524 7.79113Z"
        fill="#222222"/>
      <path
        d="M12.4709 19.4999H5.21512C1.90465 19.4999 0 17.5952 0 14.2848V6.57547C0 3.265 1.90465 1.36035 5.21512 1.36035H12.4709C15.7814 1.36035 17.686 3.265 17.686 6.57547V14.2848C17.686 17.5952 15.7814 19.4999 12.4709 19.4999ZM5.21512 2.72082C2.62116 2.72082 1.36047 3.98151 1.36047 6.57547V14.2848C1.36047 16.8787 2.62116 18.1394 5.21512 18.1394H12.4709C15.0649 18.1394 16.3256 16.8787 16.3256 14.2848V6.57547C16.3256 3.98151 15.0649 2.72082 12.4709 2.72082H5.21512Z"
        fill="#222222"/>
    </svg>
  )
}
