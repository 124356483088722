import { api } from 'api';
import { OptionType } from 'scribexUI';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'constants/routes';

export const useAdmin = () => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const [signOut] = api.useSignOutMutation();
  const navigate = useNavigate();
  const onActionMenuClick = useCallback(() => {
    setIsActionMenuOpen(prevState => !prevState)
  }, []);
  const onActionOptionSelect = useCallback((item: OptionType) => {
    switch (item.value) {
      case 'logout':
        setIsActionMenuOpen(false);
        localStorage.removeItem('email');
        signOut();
        navigate(ADMIN_ROUTES.adminSignIn);
        break;
      default:
        console.log('It is default action')
    }
  }, [navigate, signOut]);
  return {
    onActionMenuClick,
    onActionOptionSelect,
    isActionMenuOpen
  }
}