import styled, { css } from 'styled-components';
import variables from 'scribexUI/styles/variables.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { HorizontalDotsIcon, OutlineLeftArrowIcon, OutlineRightArrowIcon } from 'scribexUI/icons';
import { SXSelect } from '../select';

interface PaginationProps {
  total: number;
  pageSize?: number;
  currentPage?: number;
  size?: 'sm' | 'md'
  onPageChange?: (page: number, perPageCount: number) => void;
  withText?: boolean,
  withTotal?: boolean
}

type PageButtonType = {
  $isActive?: boolean,
  $size: 'sm' | 'md',
  disabled?: boolean
}

const sizes = {
  sm: {
    width: '24px',
    height: '24px',
  },
  md: {
    width: '32px',
    height: '32px',
  }
}

const StyledButton = styled.button<PageButtonType>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: ${variables.surface5};
`;

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin: 24px auto;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const TextButton = styled(StyledButton)`
  color: #6573FF;
  font-size: 14px;
  line-height: 20px;
  font-family: ${variables.mainFontRegular};
  ${({disabled}) => disabled && css`
    color: ${variables.textPlaceholder};
    cursor: not-allowed;
  `};
`;

const StyledTotalItems = styled.span`
  color: ${variables.textPrimary};
  font-size: 14px;
  line-height: 20px;
  font-family: ${variables.mainFontRegular};
`;
const StyledSelectWrapper = styled.div`
  max-width: 90px;
`;

const PageButton = styled(StyledButton)`
  color: ${variables.textPrimary};
  padding: 6px;
  transition: all 0.3s ease;
  border-radius: 4px;
  ${({$isActive, disabled}) => $isActive && css`
    border: 1px solid #6573FF;
    color: #6573FF;
    ${disabled && css`
      background-color: ${variables.surface2};
      border: none;
      color: ${variables.textPlaceholder};
    `}
  `};
  ${({$size}) => sizes[$size]}
  ${({disabled}) => disabled && css`
    cursor: not-allowed;
  `};

  &:hover:not([disabled]) {
    background-color: #F6F7FF;
    border-color: ${variables.surface5};
  }
`;

const Ellipsis = styled.div<PageButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({$size}) => sizes[$size]}
`;

const paginationOptions = [
  {
    label: '10/page',
    value: '10'
  },
  {
    label: '20/page',
    value: '20'
  },
  {
    label: '50/page',
    value: '50'
  },
  {
    label: '100/page',
    value: '100'
  },
]

export const SXPagination: React.FC<PaginationProps> = (props) => {
  const {
    total,
    pageSize = 10,
    currentPage = 1,
    size = 'md',
    onPageChange,
    withText = true,
    withTotal = true
  } = props;
  const [page, setPage] = useState(currentPage);
  const [perPageSize, setPerPageSize] = useState(pageSize);
  const totalPages = Math.ceil(total / perPageSize);

  useEffect(() => {
    if (totalPages === 1) {
      setPage(1);
    }
  }, [totalPages]);

  // useEffect(() => {
  //   if (currentPage !== page && totalPages !== 1) {
  //     setPage(currentPage)
  //   }
  // }, [page, currentPage, totalPages]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPage, perPageSize);
    }
  }, [onPageChange, perPageSize]);
  const handlePageCountChange = useCallback((pageCount: number) => {
    setPerPageSize(pageCount);
    setPage(1);
    if (onPageChange) {
      onPageChange(1, pageCount);
    }
  }, [onPageChange]);

  const renderPageButtons = useCallback(() => {
    const buttons = [];
    const maxButtons = 5;
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        Math.abs(i - page) <= 1 ||
        (page <= 3 && i <= maxButtons) ||
        (page >= totalPages - 2 && i > totalPages - maxButtons)
      ) {
        buttons.push(
          <PageButton
            key={i}
            onClick={() => handlePageChange(i)}
            $isActive={i === page}
            $size={size}
          >
            {i}
          </PageButton>
        );
      } else if ((page > 3 && i === 2) || (page < totalPages - 2 && i === totalPages - 1)) {
        buttons.push(<Ellipsis $size={size} key={i}>
          <HorizontalDotsIcon/>
        </Ellipsis>);
      }
    }
    return buttons;
  }, [page, handlePageChange, size, totalPages]);
  return (
    <StyledPaginationWrapper>
      {withTotal && <StyledTotalItems>
        Total {total} items
      </StyledTotalItems>}
      <PaginationContainer>
        {withText ? <TextButton
            $size={size}
            disabled={page === 1 || totalPages === 0}
            onClick={() => handlePageChange(page - 1)}
          >
            Previous
          </TextButton> :
          <PageButton
            disabled={page === 1 || totalPages === 0}
            onClick={() => handlePageChange(page - 1)}
            $size={size}>
            <OutlineLeftArrowIcon disabled={page === 1}/>
          </PageButton>}
        {renderPageButtons()}
        {withText ? <TextButton
            $size={size}
            disabled={page === totalPages || totalPages === 0}
            onClick={() => handlePageChange(page + 1)}
          >
            Next
          </TextButton> :
          <PageButton
            disabled={page === totalPages || totalPages === 0}
            onClick={() => handlePageChange(page + 1)}
            $size={size}>
            <OutlineRightArrowIcon disabled={page === totalPages}/>
          </PageButton>}
      </PaginationContainer>
      <StyledSelectWrapper>
        <SXSelect
          disabled={totalPages === 0}
          size={'xs'}
          defaultOption={[paginationOptions[0]]}
          options={paginationOptions}
          onSelect={(value) => handlePageCountChange(value as number)}/>
      </StyledSelectWrapper>
    </StyledPaginationWrapper>
  );
};