export const PlusIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.90972 6.65272H2.46528C2.21093 6.65272 2 6.4418 2 6.18745C2 5.93309 2.21093 5.72217 2.46528 5.72217H9.90972C10.1641 5.72217 10.375 5.93309 10.375 6.18745C10.375 6.4418 10.1641 6.65272 9.90972 6.65272Z"
        fill="#5865F2"/>
      <path
        d="M6.18793 10.375C5.93358 10.375 5.72266 10.1641 5.72266 9.90972V2.46528C5.72266 2.21093 5.93358 2 6.18793 2C6.44229 2 6.65321 2.21093 6.65321 2.46528V9.90972C6.65321 10.1641 6.44229 10.375 6.18793 10.375Z"
        fill="#5865F2"/>
    </svg>

  );
};