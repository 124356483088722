import React, { ReactNode, useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { SXTypography } from 'scribexUI';
import { createPortal } from 'react-dom';

const {Text} = SXTypography;

interface TooltipProps {
  title: ReactNode;
  children: ReactNode;
  placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
  trigger?: 'hover' | 'focus' | 'click';
  open?: boolean;
  zIndex?: number;
  disabled?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const TooltipContainer = styled.div`
  position: relative;
  display: block;
`;

const TooltipText = styled.div<{ $placement: string; zIndex?: number; $top: number; $left: number }>`
  visibility: ${(props) => (props.$top !== 0 || props.$left !== 0 ? 'visible' : 'hidden')};
  width: 150px;
  background-color: #FBFBFB;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: ${(props) => props.zIndex || 9999};
  opacity: ${(props) => (props.$top !== 0 || props.$left !== 0 ? 1 : 0)};
  transition: opacity 0.3s;
  box-shadow: 0 2px 8px 0 #00000026;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  
  &::after {
    content: '';
    position: absolute;
    ${(props) => {
      switch (props.$placement) {
        case 'top':
          return 'top: 100%; left: 50%; margin-left: -5px; border-width: 5px; border-style: solid; border-color: #FBFBFB transparent transparent transparent;';
        case 'bottom':
          return 'bottom: 100%; left: 50%; margin-left: -5px; border-width: 5px; border-style: solid; border-color: transparent transparent #FBFBFB transparent;';
        case 'left':
          return 'left: 100%; top: 50%; margin-top: -5px; border-width: 5px; border-style: solid; border-color: transparent transparent transparent #FBFBFB;';
        case 'right':
          return 'right: 100%; top: 50%; margin-top: -5px; border-width: 5px; border-style: solid; border-color: transparent #FBFBFB transparent transparent;';
        case 'topLeft':
          return 'top: 100%; left: 5px; border-width: 5px; border-style: solid; border-color: #FBFBFB transparent transparent transparent;';
        case 'topRight':
          return 'top: 100%; right: 5px; border-width: 5px; border-style: solid; border-color: #FBFBFB transparent transparent transparent;';
        case 'bottomLeft':
          return 'bottom: 100%; left: 5px; border-width: 5px; border-style: solid; border-color: transparent transparent #FBFBFB transparent;';
        case 'bottomRight':
          return 'bottom: 100%; right: 5px; border-width: 5px; border-style: solid; border-color: transparent transparent #FBFBFB transparent;';
        case 'leftTop':
          return 'left: 100%; top: 5px; border-width: 5px; border-style: solid; border-color: transparent transparent transparent #FBFBFB;';
        case 'leftBottom':
          return 'left: 100%; bottom: 5px; border-width: 5px; border-style: solid; border-color: transparent transparent transparent #FBFBFB;';
        case 'rightTop':
          return 'right: 100%; top: 5px; border-width: 5px; border-style: solid; border-color: transparent #FBFBFB transparent transparent;';
        case 'rightBottom':
          return 'right: 100%; bottom: 5px; border-width: 5px; border-style: solid; border-color: transparent #FBFBFB transparent transparent;';
        default:
          return 'top: 100%; left: 50%; margin-left: -5px; border-width: 5px; border-style: solid; border-color: #FBFBFB transparent transparent transparent;';
      }
    }}
  }

  ${TooltipContainer}:hover &,
  ${TooltipContainer}:focus &,
  ${TooltipContainer}:active & {
    visibility: visible;
    opacity: 1;
  }
`;

export const SXTooltip: React.FC<TooltipProps> = (props) => {
  const {
    title,
    children,
    placement = 'top',
    trigger = 'hover',
    open,
    zIndex,
    disabled,
    onOpenChange,
  } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({top: 0, left: 0});
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof open === 'boolean') {
      setVisible(open);
    }
  }, [open]);

  const handleVisibleChange = useCallback((newVisible: boolean) => {
    setVisible(newVisible);
    if (onOpenChange) {
      onOpenChange(newVisible);
    }
  }, [onOpenChange]);

  const handleMouseEnter = useCallback(() => trigger === 'hover' && !disabled && handleVisibleChange(true), [handleVisibleChange, trigger, disabled]);
  const handleMouseLeave = useCallback(() => trigger === 'hover' && !disabled && handleVisibleChange(false), [handleVisibleChange, trigger, disabled]);
  const handleFocus = useCallback(() => trigger === 'focus' && !disabled && handleVisibleChange(true), [handleVisibleChange, trigger, disabled]);
  const handleBlur = useCallback(() => trigger === 'focus' && !disabled && handleVisibleChange(false), [handleVisibleChange, trigger, disabled]);
  const handleClick = useCallback(() => trigger === 'click' && !disabled && handleVisibleChange(!visible), [handleVisibleChange, visible, trigger, disabled]);


  const getTooltipPosition = useCallback(() => {
    if (!tooltipRef.current || !containerRef.current) return {top: 0, left: 0};
    const containerRect = containerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    let top = 0;
    let left = 0;

    switch (placement) {
      case 'top':
        top = containerRect.top - tooltipRect.height - 8;
        left = containerRect.left + (containerRect.width / 2) - (tooltipRect.width / 2);
        break;
      case 'bottom':
        top = containerRect.bottom + 8;
        left = containerRect.left + (containerRect.width / 2) - (tooltipRect.width / 2);
        break;
      case 'left':
        top = containerRect.top + (containerRect.height / 2) - (tooltipRect.height / 2);
        left = containerRect.left - tooltipRect.width - 8;
        break;
      case 'right':
        top = containerRect.top + (containerRect.height / 2) - (tooltipRect.height / 2);
        left = containerRect.right + 8;
        break;
      // Add other placements here...
      default:
        top = containerRect.top - tooltipRect.height - 8;
        left = containerRect.left + (containerRect.width / 2) - (tooltipRect.width / 2);
    }
    setPosition({top, left});
  }, [placement]);

  useEffect(() => {
    if (visible) {
      getTooltipPosition();
    }
  }, [visible, getTooltipPosition]);
  return (
    <TooltipContainer
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      {children}
      {visible && !disabled && createPortal(
        <TooltipText ref={tooltipRef} $placement={placement} zIndex={zIndex} $top={position.top} $left={position.left}>
          <Text size={'md'} weight={'medium'} color={'#68686B'}>
            {title}
          </Text>
        </TooltipText>,
        document.body
      )}
    </TooltipContainer>
  );
};
