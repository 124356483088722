import React, { FC } from 'react';
import { SXButton, SXTextButton, SXToggle, SXTypography } from 'scribexUI';
import { ManageCookieData } from 'mock';
import { Col, Row, Form } from 'componnets';
import { useManageCookie } from './useManageCookie';
import styles from './style.module.scss';

const {Item: FormItem} = Form;
const {Text, Title} = SXTypography;
const {title, necessaryBtn, allBtn, rejectBtn, cookieOption, description} = ManageCookieData;

interface IManageCookie {
  onModalClose: () => void
}

export const ManageCookie: FC<IManageCookie> = React.memo((props) => {
  const {onModalClose} = props;
  const {
    onRejectAll,
    onAllowAll,
    onOnlyNecessary,
    width,
    formRef
  } = useManageCookie(onModalClose);
  return (
    <Row align={'middle'} justify={'center'} gutter={[0, 14]} className={styles.cookie_manage}>
      <Col span={24}>
        <Title level={3} className={styles.title}>{title}</Title>
      </Col>
      <Col span={24}>
        <Text className={styles.description}>{description}</Text>
      </Col>
      <Col span={24} className={styles.toggle_container}>
        <Form
          form={formRef}
          autoComplete={'off'}
          layout={'vertical'}
        >
          {cookieOption.map(_cookie => <Row
            key={_cookie.title}
            className={styles.cookie_option}
            justify={'space-between'}
            align={'middle'}
            wrap={false}>
            <Col>
              <Row align={'middle'} gutter={[0, 7]}>
                <Col span={24}>
                  <Text className={styles.cookie_label}>
                    {_cookie.title}
                  </Text>
                </Col>
                <Col>
                  <Text className={styles.cookie_description}>
                    {_cookie.description}
                  </Text>
                  {_cookie.additionalList?.length && <ul>
                    {_cookie.additionalList.map(_list => <li
                      className={styles.cookie_description}
                      key={_list.id}>
                      {_list.label}
                    </li>)}
                  </ul>}
                </Col>
              </Row>
            </Col>
            <Col>
              <FormItem name={_cookie.key} valuePropName="checked">
                <SXToggle
                  size={'md'}
                  alwaysChecked={_cookie.necessary ? true : undefined}
                />
              </FormItem>
            </Col>
          </Row>)}
        </Form>
      </Col>
      <Col span={24}>
        <Row justify={'end'} wrap={width < 577} className={styles.action_buttons_container}>
          <SXTextButton
            underline
            className={styles.reject}
            color={'#140D0B'}
            onClick={onRejectAll}>{rejectBtn}</SXTextButton>
          <SXButton
            block
            className={styles.necessary_btn}
            sxType={'tertiary'}
            roundness={'sharp'}
            size={'md'}
            borderColor={'#140D0B'}
            onClick={onOnlyNecessary}>{necessaryBtn}</SXButton>
          <SXButton
            block
            sxType={'primary'}
            onClick={onAllowAll}
            roundness={'sharp'}
            size={'md'}
            bgColor={'#140D0B'}
          >{allBtn}</SXButton>
        </Row>
      </Col>
    </Row>
  )
})