import { createSlice } from '@reduxjs/toolkit';

const initialState: IInitialState = {
  filteredParams: {},
  usage: {
    archive: false,
    published: false
  }
}

type IInitialState = {
  filteredParams: Record<string, boolean | undefined | null>
  usage: Record<string, boolean>
}

export const jobFilterSlice = createSlice({
  name: 'jobFilter',
  initialState,
  reducers: {
    saveJobFilterParams: (state, action) => {
      state.filteredParams = action.payload.filteredParams;
      state.usage = action.payload.usage;
    },
    resetJobFilterParams: (state) => {
      state.filteredParams = initialState.filteredParams;
      state.usage = initialState.usage;
    }
  }
});

export const {
  saveJobFilterParams,
  resetJobFilterParams
} = jobFilterSlice.actions
